import { TDllOptions } from '../extension_modules/DllOptions'
import { TReplaceStrProc } from '../extension_modules/DllOptions'
import {
    MODE_CLOSE,
    MODE_HIGH,
    MODE_LOW,
    MODE_OPEN,
    MODE_VOLUME,
    TOptValue,
    TOptValue_number,
    TOptValue_str
} from './indicators/api/IndicatorInterfaceUnit'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import { TChunkStatus, TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { ChunkBuilder } from '@fto/lib/ft_types/data/BarBuilding/chunk_building/ChunkBuilder'
import IFMBarsArray from '../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { TBarRecord } from '../ft_types/data/DataClasses/TBarRecord'
import { TDateTime } from '../delphi_compatibility/DateUtils'

export class TVeryBasicDllClass {
    // protected fLibHandle!: THandle;

    // protected fDataPath!: AnsiString;
    protected fOptions!: TDllOptions
    protected fReplaceStrProc!: TReplaceStrProc
    protected fLibName!: string
    protected fFileName: string

    constructor(fileName: string) {
        this.fFileName = fileName
    }

    public Print(s: string): void {
        // eslint-disable-next-line no-console
        console.log(s)
    }

    public get options(): TDllOptions {
        return this.fOptions
    }

    public get LibName(): string {
        return this.fLibName
    }

    public get FileName(): string {
        return this.fFileName
    }

    //# api Implementation {

    protected RegOption(OptionName: string, OptionType: number, OptPtr: string | number | TOptValue | null, userInNameWithParams: boolean = true): void {
        let optionPointer: TOptValue

        if (typeof OptPtr === 'string') {
            optionPointer = new TOptValue_str(OptPtr)
        } else if (typeof OptPtr === 'number') {
            optionPointer = new TOptValue_number(OptPtr)
        } else if (OptPtr instanceof TOptValue) {
            optionPointer = OptPtr
        } else {
            optionPointer = new TOptValue()
        }

        const invisible = false
        this.fOptions.RegOption(OptionName, OptionType, optionPointer, invisible, this.fReplaceStrProc, userInNameWithParams)
    }

    protected AddOptionValue(OptionName: string, value: string): void {
        this.fOptions.AddOptionValue(OptionName, value)
    }

    //TODO:IN GetIndexByDateBin
    protected iBarShift(symbol: string, timeframe: number, time: TDateTime, exact: boolean): number {
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeframe)
        if (!bars) return -1
        const barChunk = bars.GetChunkByDate(time)

        if (barChunk && barChunk.Status !== TChunkStatus.cs_Loaded) {
            ChunkBuilder.Instance.BuildChunk(barChunk)
            return -1
        }

        try {
            let result = bars.GetGlobalIndexByDate(time, TNoExactMatchBehavior.nemb_ReturnNearestLower, false)
            if (result !== -1) {
                if (bars.LastItemInTestingIndex < result) {
                    result = 0
                } else {
                    result = bars.LastItemInTestingIndex - bars.FitIndex(result)
                }
            }
            return result
        } catch {
            return -1
        }
    }

    protected iBars(symbol: string, timeframe: number): number {
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeframe)
        return bars ? bars.totalBarsCount : 0
    }

    // TODO:EX (impl not checked) function TVeryBasicDllClass.iHighest(Symbol: PAnsiChar; TimeFrame, _type, count,
    protected iHighest(symbol: string, timeFrame: number, type: number, count: number, index: number): number {
        //TODO: can we optimize this by storing bars array in a private field?
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeFrame)
        if (!this.isIndexValid(bars, index)) {
            return -1
        }

        index = bars.FitIndex(index)
        index = bars.totalBarsCount - 1 - index
        let i = index - 1
        let bar = bars.GetItemByGlobalIndex(index)
        if (!bar) return -1
        let max = this.getValueByType(bar, type)
        let result = index
        count--
        while (i >= 0 && count > 0) {
            bar = bars.GetItemByGlobalIndex(i)
            if (!bar) return -1
            const value = this.getValueByType(bar, type)
            if (value >= max) {
                max = value
                result = i
            }
            i--
            count--
        }

        if (result !== -1) result = bars.totalBarsCount - 1 - result
        return result
    }

    // TODO:EX (impl not checked) function TVeryBasicDllClass.iLowest(Symbol: PAnsiChar; TimeFrame, _type, count,
    protected iLowest(symbol: string, timeFrame: number, type: number, count: number, index: number): number {
        //TODO: can we optimize this by storing bars array in a private field?
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeFrame)
        if (!this.isIndexValid(bars, index)) {
            return -1
        }

        index = bars.FitIndex(index)
        index = bars.totalBarsCount - 1 - index
        let i = index - 1
        let bar = bars.GetItemByGlobalIndex(index)
        if (!bar) return -1
        let min = this.getValueByType(bar, type)

        let result = index
        count--
        while (i >= 0 && count > 0) {
            bar = bars.GetItemByGlobalIndex(i)
            if (!bar) return -1
            const value = this.getValueByType(bar, type)
            if (value <= min) {
                min = value
                result = i
            }
            i--
            count--
        }

        if (result === -1) result = bars.totalBarsCount - 1 - result
        return result
    }

    private isIndexValid(bars: IFMBarsArray, index: number) {
        return bars.totalBarsCount > 0 && index > 0 && index < bars.totalBarsCount
    }

    protected SetOptionRange(OptionName: string, LowValue: number, HighValue: number): void {
        this.fOptions.SetOptionRange(OptionName, LowValue, HighValue)
    }

    //# api Implementation }

    private getValueByType(barRecord: TBarRecord, type: number): number {
        switch (type) {
            case MODE_OPEN: {
                return barRecord.open
            }
            case MODE_LOW: {
                return barRecord.low
            }
            case MODE_HIGH: {
                return barRecord.high
            }
            case MODE_CLOSE: {
                return barRecord.close
            }
            case MODE_VOLUME: {
                return barRecord.volume
            }
            default: {
                return barRecord.DateTime
            }
        }
    }
}
