import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import {
    DelphiLikeArray,
    TBrushStyle,
    TColor,
    TFontStyle,
    TPenStyle,
    TPoint,
    TRect
} from '../../delphi_compatibility/DelphiBasicTypes'
import { TMouseButton } from '../../delphi_compatibility/DelphiFormsBuiltIns'
import { DelphiMathCompatibility } from '../../delphi_compatibility/DelphiMathCompatibility'
import { ColorHelperFunctions } from '../../drawing_interface/ColorHelperFunctions'
import { TGdiPlusCanvas } from '../../drawing_interface/GdiPlusCanvas'
import { TExtendsRay, TLineStyle, TMkFontStyle } from '../../drawing_interface/vclCanvas'
import { TChartType } from '../../ft_types/common/BasicClasses/BasicEnums'
import { Common } from '../../ft_types/common/Common'
import { TCursor, TCustomCursor } from '../../ft_types/common/CursorPointers'
import { TOffsStringList, TVarList } from '../../ft_types/common/OffsStringList'
import { StrsConv } from '../../ft_types/common/StrsConv'
import { TNoExactMatchBehavior } from '../../ft_types/data/chunks/ChunkEnums'
import GlobalOptions from '../../globals/GlobalOptions'
import { NotImplementedError } from '../../utils/common_utils'
import IBasicPaintTool from './IBasicPaintTool'
import { MarkerImagesManager } from './MarkerImagesManager'
import {
    ObjProp,
    TCoordsRect,
    TPaintToolStatus,
    TPaintToolType,
    TPointInfo,
    TPointsArr
} from './PaintToolsAuxiliaryClasses'
import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import TrendLineStore from '@fto/lib/charting/tool_storages/trendline'
import { IGPFont, IGPPen, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import {
    FillColorParamsType,
    FontStylesType
} from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

/**
 * This class should be used as abstract, now we do not have enough time,
 * because refactoring will take a while
 *
 * TODOS: make class abstract,
 * `saveToManager` and `applySettingsFromManager` should be abstract methods(manager is LastPaintToolStyleManager)
 * in each place where TBasicPaintTool used as interface, replace it with IBasicPaintTool
 * there are a lot of methods implemented in TBasicPaintTool,
 * but there are no corresponding declarations in IBasicPaintTool, this SHOULD be fixed
 *
 */
export class TBasicPaintTool implements IBasicPaintTool {
    private fToolName!: string
    private fTempRes!: string
    private isVisible = true

    protected fChart: TChart
    protected fToolType: TPaintToolType
    protected fStatus: TPaintToolStatus
    protected fSelected: boolean
    protected fHighlighted: boolean
    protected fHighlightedPoint: number
    protected fClosedPolygon: boolean
    protected fShouldFillInside: boolean
    protected hasText!: boolean
    protected hasLines: boolean
    //TODO: make it a normal array
    protected fTimeframes!: DelphiLikeArray<number>
    protected fMaxPoints: number
    protected fPoints: TPointsArr
    protected fScreenCoords: boolean
    protected fHighlightLinesColor: TColor

    protected fLineStyle: TLineStyle
    protected fBrushStyle: TBrushStyle
    protected fBrushColor: TColor
    protected fFontStyle: TMkFontStyle
    protected isNeedDrawBorder = true
    protected symbolName = ''

    protected fText: string
    protected fTextVAlign: number
    protected fTextHAlign: number

    public fExtendsRay: TExtendsRay
    public pen: IGPPen
    public brush: IGPSolidBrush
    public font: IGPFont
    public name = ''
    public fLocked: boolean

    ShortName!: string
    CursorStyle!: TCustomCursor | TCursor
    description!: string
    icon!: number
    LinkNumber!: number
    isNeededToCopyToOtherCharts!: boolean
    isNeedToTransferBetweenCharts = true
    ExportToDialogBool = true

    public isSyncedWithOtherCharts = true

    protected get chartPaintRect(): TRect {
        if (this.fChart.IsPaintContextCacheInitialized) {
            return this.fChart.PaintContextCache.PaintRect
        }
        return this.fChart.GetPaintRect()
    }

    protected get SymbolData(): TSymbolData {
        const symbolName = this.chart.Bars.DataDescriptor.symbolName
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbolName)
    }

    constructor(aChart: TChart, aShortName: string) {
        this.ShortName = aShortName
        this.fChart = aChart
        this.fPoints = new TPointsArr()
        this.fToolType = TPaintToolType.tt_Line
        this.fStatus = TPaintToolStatus.ts_FirstPoint
        this.fSelected = false
        this.fHighlighted = false
        this.fHighlightedPoint = -1
        this.fMaxPoints = 1
        this.fClosedPolygon = false
        this.hasLines = true
        this.fShouldFillInside = true
        this.fScreenCoords = false
        this.ToolName = 'Basic tool'
        this.CursorStyle = TCursor.crDefault // CursorStyleType.Default corresponds to crDefault
        // SetAllTimeframes needs to be implemented according to its logic in Delphi
        this.icon = 0
        this.LinkNumber = 0
        this.isNeededToCopyToOtherCharts = true
        this.fHighlightLinesColor = '#f1f1f1' + '95'

        // fLineStyle.create needs to be adapted to TypeScript equivalent
        this.fLineStyle = new TLineStyle('#2F80ED', TPenStyle.psSolid, 1) // Example conversion for MakeColor and LineStyle
        this.fBrushStyle = TBrushStyle.bsSolid // BrushStyle.Solid corresponds to bsSolid
        // Assuming MakeColor handles opacity as well, here 70 is the opacity
        this.fBrushColor = ColorHelperFunctions.MakeColor('#2F80ED', 50)
        // fFontStyle.create needs to be adapted to TypeScript equivalent
        this.fFontStyle = new TMkFontStyle(
            'Roboto Flex',
            TFontStyle.fsNone,
            14,
            this.fChart.ChartOptions.ColorScheme.FrameAndTextColor
        )

        this.fText = ''
        this.fTextVAlign = 0
        this.fTextHAlign = 1

        // AddPoint needs to be adapted to TypeScript equivalent
        this.AddPoint(0, 0) // Assuming addPoint is a method in this class
        this.pen = new IGPPen(this.fLineStyle.color, this.fLineStyle.width)
        this.brush = new IGPSolidBrush(this.fBrushColor, 0.5)
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.fLocked = false
        this.fExtendsRay = new TExtendsRay()
        this.isSyncedWithOtherCharts = GlobalOptions.Options.isSyncActionsWithGraphTools
    }

    clone(): TBasicPaintTool {
        const cloned = new TBasicPaintTool(this.fChart, this.ShortName)

        cloned.fPoints = this.fPoints.clone()
        cloned.fToolType = this.fToolType
        cloned.fStatus = this.fStatus
        cloned.fSelected = this.fSelected
        cloned.fHighlighted = this.fHighlighted
        cloned.fHighlightedPoint = this.fHighlightedPoint
        cloned.fMaxPoints = this.fMaxPoints
        cloned.fClosedPolygon = this.fClosedPolygon
        cloned.fShouldFillInside = this.fShouldFillInside
        cloned.fScreenCoords = this.fScreenCoords
        cloned.ToolName = this.ToolName
        cloned.CursorStyle = this.CursorStyle

        cloned.icon = this.icon
        cloned.LinkNumber = this.LinkNumber
        cloned.isNeededToCopyToOtherCharts = this.isNeededToCopyToOtherCharts

        cloned.fLineStyle = this.fLineStyle
        cloned.fBrushStyle = this.fBrushStyle

        cloned.fBrushColor = this.fBrushColor

        cloned.fFontStyle = this.fFontStyle

        cloned.fText = this.fText
        cloned.fTextVAlign = this.fTextVAlign
        cloned.fTextHAlign = this.fTextHAlign
        cloned.fTimeframes = this.fTimeframes
        cloned.name = this.name

        cloned.pen = this.pen.clone()
        cloned.brush = this.brush.clone()
        cloned.font = this.font
        cloned.fLocked = this.fLocked

        cloned.isNeedDrawBorder = this.isNeedDrawBorder
        cloned.fExtendsRay = this.fExtendsRay
        cloned.isSyncedWithOtherCharts = this.isSyncedWithOtherCharts
        cloned.symbolName = this.symbolName

        return cloned
    }

    public toJson(): BasicPaintToolJSON {
        const basic = {
            name: this.name,
            ToolName: this.ToolName,
            ShortName: this.ShortName,
            ToolType: this.fToolType,
            IsVisible: this.isVisible,
            Status: this.fStatus,
            Selected: this.fSelected,
            Highlighted: this.fHighlighted,
            HighlightedPoint: this.fHighlightedPoint,
            ClosedPolygon: this.fClosedPolygon,
            ShouldFillInside: this.fShouldFillInside,
            Timeframes: this.fTimeframes,
            MaxPoints: this.fMaxPoints,
            LineStyle: {
                color: this.fLineStyle.color,
                style: this.fLineStyle.style,
                width: this.fLineStyle.width
            },
            BrushStyle: this.fBrushStyle,
            BrushColor: this.fBrushColor,
            FontStyle: {
                name: this.fFontStyle.name,
                style: this.fFontStyle.style,
                size: this.fFontStyle.size,
                color: this.fFontStyle.color
            },
            Text: this.fText,
            TextVAlign: this.fTextVAlign,
            TextHAlign: this.fTextHAlign,
            CursorStyle: this.CursorStyle,
            Description: this.description,
            Icon: this.icon,
            LinkNumber: this.LinkNumber,
            IsNeededToCopyToOtherCharts: this.isNeededToCopyToOtherCharts,
            TempRes: this.fTempRes,
            pen: {
                color: this.pen.color,
                width: this.pen.width,
                opacity: this.pen.opacity,
                dashStyle: this.pen.dashStyle
            },
            brush: {
                color: this.brush.getColor(),
                opacity: this.brush.getOpacity(),
                style: this.brush.getStyle()
            },
            font: this.font.toString(),
            locked: this.fLocked,
            fExtendsRay: {
                left: this.fExtendsRay.left,
                right: this.fExtendsRay.right,
                fillLeft: this.fExtendsRay.fillLeft,
                fillRight: this.fExtendsRay.fillRight,
                leftfPoints: this.fExtendsRay.leftfPoints,
                rightfPoints: this.fExtendsRay.rightfPoints,
                fillLeftfPoints: this.fExtendsRay.fillLeftfPoints,
                fillRightfPoints: this.fExtendsRay.fillRightfPoints
            },
            symbolName: this.symbolName
        }

        if (this.fScreenCoords) {
            return {
                ...basic,
                ScreenCoords: this.fScreenCoords,
                Points: this.fPoints.map((point) => ({ x: point.x, y: point.y }))
            }
        }
        return {
            ...basic,
            ScreenCoords: this.fScreenCoords,
            Points: this.fPoints.map((point) => ({ price: point.price, time: point.time }))
        }
    }

    fromJSON(json: BasicPaintToolJSON): void {
        this.name = json.name
        this.ToolName = json.ToolName
        this.ShortName = json.ShortName
        this.fToolType = json.ToolType
        this.isVisible = json.IsVisible
        this.fStatus = json.Status
        this.fSelected = json.Selected
        this.fHighlighted = json.Highlighted
        this.fHighlightedPoint = json.HighlightedPoint
        this.fClosedPolygon = json.ClosedPolygon
        this.fShouldFillInside = json.ShouldFillInside
        this.fTimeframes = json.Timeframes
        this.fMaxPoints = json.MaxPoints
        this.fPoints = new TPointsArr()

        if (json.ScreenCoords) {
            throw new NotImplementedError('Logic for json.ScreenCoords: true is not implemented ')
        }
        for (const point of json.Points) {
            const p = new TPointInfo(point.time, point.price)
            p.time = point.time
            p.price = point.price
            this.fPoints.add(p)
        }
        this.fScreenCoords = json.ScreenCoords
        this.fLineStyle = new TLineStyle(json.LineStyle.color, json.LineStyle.style, json.LineStyle.width)
        this.fBrushStyle = json.BrushStyle
        this.fBrushColor = json.BrushColor
        this.fFontStyle = new TMkFontStyle(
            json.FontStyle.name,
            json.FontStyle.style,
            json.FontStyle.size,
            json.FontStyle.color
        )
        this.fText = json.Text
        this.fTextVAlign = json.TextVAlign
        this.fTextHAlign = json.TextHAlign
        this.CursorStyle = json.CursorStyle
        this.description = json.Description
        this.icon = json.Icon
        this.LinkNumber = json.LinkNumber
        this.isNeededToCopyToOtherCharts = json.IsNeededToCopyToOtherCharts
        this.fTempRes = json.TempRes
        this.pen = new IGPPen(json.pen.color, json.pen.width)
        this.pen.opacity = json.pen.opacity
        this.pen.dashStyle = json.pen.dashStyle
        this.brush = new IGPSolidBrush(json.brush.color, json.brush.opacity)
        this.brush.setStyle(json.brush.style)
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.fLocked = json.locked
        this.fExtendsRay = json.fExtendsRay || new TExtendsRay() //in cases of old projects they may not have this field
        this.symbolName = json.symbolName
    }

    public SetProperty(index: number, value: any): void {
        let s: string

        // Helper function to set time
        const SetTime = (i: number): void => {
            if (this.fPoints.Count > i) {
                this.fPoints[i].time = value as TDateTime
            }
        }

        // Helper function to set price
        const SetPrice = (i: number): void => {
            if (this.fPoints.Count > i) {
                this.fPoints[i].price = Number(value)
            }
        }

        switch (index) {
            case ObjProp.OBJPROP_TIME1: {
                SetTime(0)
                break
            }
            case ObjProp.OBJPROP_PRICE1: {
                SetPrice(0)
                break
            }
            case ObjProp.OBJPROP_TIME2: {
                SetTime(1)
                break
            }
            case ObjProp.OBJPROP_PRICE2: {
                SetPrice(1)
                break
            }
            case ObjProp.OBJPROP_TIME3: {
                SetTime(2)
                break
            }
            case ObjProp.OBJPROP_PRICE3: {
                SetPrice(2)
                break
            }
            case ObjProp.OBJPROP_COLOR: {
                this.fLineStyle.color = ColorHelperFunctions.FixColor(value as TColor)
                this.fBrushColor = ColorHelperFunctions.FixColor(value as TColor)
                this.pen = new IGPPen(this.fLineStyle.color, this.fLineStyle.width)
                this.brush = new IGPSolidBrush(this.fBrushColor, 0.5)
                this.font = new IGPFont(
                    new TGPFontFamily(this.fFontStyle.name),
                    this.fFontStyle.size,
                    StylingHelper.ConvertFontStyle(this.fFontStyle.style)
                )
                break
            }
            case ObjProp.OBJPROP_TEXT_PARAMS: {
                const fontStyle = value as TMkFontStyle
                if (fontStyle) {
                    this.fFontStyle = fontStyle
                    this.font = new IGPFont(
                        new TGPFontFamily(this.fFontStyle.name),
                        this.fFontStyle.size,
                        StylingHelper.ConvertFontStyle(this.fFontStyle.style)
                    )
                }
                break
            }
            case ObjProp.OBJPROP_STYLE: {
                this.fLineStyle.style = value as TPenStyle
                break
            }
            case ObjProp.OBJPROP_WIDTH: {
                this.fLineStyle.width = Number(value)
                break
            }
            case ObjProp.OBJPROP_FONTNAME: {
                this.fFontStyle.name = StrsConv.Utf8ToString(value)
                break
            }
            case ObjProp.OBJPROP_FONTSIZE: {
                this.fFontStyle.size = Number(value)
                this.font.setFontSize(this.fFontStyle.size)
                break
            }
            case ObjProp.OBJPROP_NAME: {
                this.ToolName = StrsConv.Utf8ToString(value)
                break
            }
            case ObjProp.OBJPROP_FILLINSIDE: {
                this.fShouldFillInside = Boolean(value)
                break
            }
            case ObjProp.OBJPROP_FILLCOLOR: {
                this.fBrushColor = ColorHelperFunctions.FixColor(value as TColor)
                const opacity = ColorHelperFunctions.GetOpacity(value as TColor)
                this.brush = new IGPSolidBrush(this.fBrushColor, opacity)
                break
            }
            case ObjProp.OBJPROP_SCREENCOORDS: {
                this.ScreenCoords = Boolean(value)
                break
            }
            case ObjProp.OBJPROP_TEXT: {
                const encoder = new TextEncoder()
                const uint8Array = encoder.encode(value)
                s = StrsConv.Utf8ToString(uint8Array)
                this.fText = s
                break
            }
            //TODO: Define constants for property indexes and handle any missing cases
            case ObjProp.BORDER: {
                this.isNeedDrawBorder = Boolean(value)
                break
            }
            default: {
                throw new NotImplementedError(`Property index not handled: ${index}`)
            }
        }
    }

    public SetPropertyDouble(index: number, value: number): void {
        let int: number
        let b: boolean
        let ps: TPenStyle

        switch (index) {
            case ObjProp.OBJPROP_TIME1:
            case ObjProp.OBJPROP_PRICE1:
            case ObjProp.OBJPROP_TIME2:
            case ObjProp.OBJPROP_PRICE2:
            case ObjProp.OBJPROP_TIME3:
            case ObjProp.OBJPROP_PRICE3:
            case ObjProp.OBJPROP_LEVELVALUE: {
                this.SetProperty(index, value)
                break
            }

            case ObjProp.OBJPROP_COLOR:
            case ObjProp.OBJPROP_FILLCOLOR:
            case ObjProp.OBJPROP_WIDTH:
            case ObjProp.OBJPROP_FONTSIZE:
            case ObjProp.OBJPROP_FIBOENDWIDTH:
            case ObjProp.OBJPROP_FIBOLEVELS:
            case ObjProp.OBJPROP_FIBOLEVELN:
            case ObjProp.OBJPROP_LEVELCOLOR:
            case ObjProp.OBJPROP_LEVELWIDTH:
            case ObjProp.OBJPROP_XDISTANCE:
            case ObjProp.OBJPROP_YDISTANCE:
            case ObjProp.OBJPROP_VALIGNMENT:
            case ObjProp.OBJPROP_HALIGNMENT:
            case ObjProp.OBJPROP_SCRVALIGNMENT:
            case ObjProp.OBJPROP_SCRHALIGNMENT: {
                int = Math.round(value)
                this.SetProperty(index, int)
                break
            }

            case ObjProp.OBJPROP_STYLE:
            case ObjProp.OBJPROP_LEVELSTYLE: {
                ps = Math.round(value) as TPenStyle
                this.SetProperty(index, ps)
                break
            }

            case ObjProp.OBJPROP_ELLIPSE:
            case ObjProp.OBJPROP_FIBOCLOSEDENDS:
            case ObjProp.OBJPROP_FIBOSHOWPRICE:
            case ObjProp.OBJPROP_SCREENCOORDS:
            case ObjProp.OBJPROP_FILLINSIDE: {
                b = value > 0
                this.SetProperty(index, b)
                break
            }

            default: {
                throw new NotImplementedError(`SetPropertyDouble is not implemented for index: ${index}`)
            }
        }
    }

    public get points(): TPointsArr {
        return this.fPoints
    }

    public get SymbolName(): string {
        return this.symbolName
    }

    public set SymbolName(value: string) {
        this.symbolName = value
    }

    public get status(): TPaintToolStatus {
        return this.fStatus
    }

    public set status(value: TPaintToolStatus) {
        this.fStatus = value
    }

    public get Selected(): boolean {
        return this.fSelected
    }

    public set Selected(value: boolean) {
        this.fSelected = value
    }

    public get chart(): TChart {
        return this.fChart
    }

    public set chart(value: TChart) {
        this.fChart = value
    }

    public get Points(): TPointsArr {
        return this.fPoints
    }

    public get ToolType(): TPaintToolType {
        return this.fToolType
    }

    public get ToolName(): string {
        return this.fToolName
    }

    public set ToolName(value: string) {
        this.fToolName = value
    }

    public get ScreenCoords(): boolean {
        return this.fScreenCoords
    }

    public set ScreenCoords(value: boolean) {
        this.fScreenCoords = value
    }

    public get Highlighted(): boolean {
        return this.fHighlighted
    }

    public set Highlighted(value: boolean) {
        this.fHighlighted = value
    }

    public get LineStyle() {
        return this.fLineStyle
    }

    public set LineStyle(value: TLineStyle) {
        this.fLineStyle = value
    }

    public get HighlightedPoint(): number {
        return this.fHighlightedPoint
    }

    public set HighlightedPoint(value: number) {
        this.fHighlightedPoint = value
    }

    public get Timeframes(): DelphiLikeArray<number> {
        return this.fTimeframes
    }

    public get ClosedPolygon(): boolean {
        return this.fClosedPolygon
    }

    public get ShouldFillInside(): boolean {
        return this.fShouldFillInside
    }

    public get MaxPoints(): number {
        return this.fMaxPoints
    }

    public get BrushStyle(): TBrushStyle {
        return this.fBrushStyle
    }

    public get BrushColor(): TColor {
        return this.fBrushColor
    }

    public get FontStyle(): TMkFontStyle {
        return this.fFontStyle
    }

    public get Text(): string {
        return this.fText
    }

    public get TextVAlign(): number {
        return this.fTextVAlign
    }

    public get TextHAlign(): number {
        return this.fTextHAlign
    }

    CompleteTool(): void {
        let i: number
        this.fPoints.clear()
        for (i = 0; i < this.fMaxPoints; i++) {
            this.AddPoint(0, 0)
        }
        this.SetAllTimeframes()
        this.fStatus = TPaintToolStatus.ts_Completed
    }

    public GetPointInfo(index: number): [number, number, number] {
        if (index < 0 || index >= this.fPoints.length) {
            throw new StrangeError(`GetPointInfo - Index out of bounds: ${index}`)
        }
        const point = this.fPoints[index]
        return [point.x, point.y, point.price]
    }

    public IsVisibleOnCurrTimeframe(): boolean {
        return this.IsVisibleOnTimeframe(this.fChart.ChartOptions.Timeframe)
    }

    public IsVisibleOnTimeframe(tf: number): boolean {
        return this.fTimeframes.includes(tf)
    }

    public IsVisibleOnSymbol(): boolean {
        return this.symbolName === this.SymbolData.symbolInfo.SymbolName
    }

    public PaintBackground(): void {
        this.FillInside()
    }

    protected GetCoordsRect(): TCoordsRect {
        const result: TCoordsRect = new TCoordsRect()
        result.x1 = Number.MAX_SAFE_INTEGER
        result.x2 = 0
        result.y1 = Number.MIN_SAFE_INTEGER // Corrected initial value for y1 to find the maximum price
        result.y2 = Number.MAX_SAFE_INTEGER

        for (const { time, price } of this.fPoints) {
            result.x1 = Math.min(result.x1, time)
            result.y1 = Math.max(result.y1, price)
            result.x2 = Math.max(result.x2, time)
            result.y2 = Math.min(result.y2, price)
        }

        return result
    }

    public Show(): void {
        this.isVisible = true
    }

    public Hide(): void {
        this.isVisible = false
    }

    public IsVisible(): boolean {
        return this.isVisible
    }

    paintFrame(): void {
        const ctx = this.chart.HTML_Canvas.getContext('2d')
        if (ctx) {
            const r = this.GetFrameRect() // Assuming getFrameRect() exists and returns a rectangle
            ctx.strokeStyle = this.chart.ChartOptions.ColorScheme.FrameAndTextColor
            ctx.lineWidth = 1
            ctx.setLineDash([1, 1]) // Simulates dotted line
            ctx.beginPath()
            ctx.rect(r.Left, r.Top, r.Width, r.Height)
            ctx.stroke()
            ctx.setLineDash([])
        }
    }

    public get visible(): boolean {
        if (this.fExtendsRay && this.fExtendsRay.left && this.CheckExtendsRayVisible(this.fExtendsRay.leftfPoints)) {
            return true
        }

        if (this.fExtendsRay && this.fExtendsRay.right && this.CheckExtendsRayVisible(this.fExtendsRay.rightfPoints)) {
            return true
        }

        let R1
        if (this.chart.IsPaintContextCacheInitialized) {
            R1 = this.chart.PaintContextCache.PaintRect
        } else {
            R1 = this.chart.GetPaintRect()
        }
        const R2: TRect = this.GetFrameRect()

        return !(R2.Left > R1.Right || R2.Right < R1.Left || R2.Bottom < R1.Top || R2.Top > R1.Bottom)
    }

    private CheckExtendsRayVisible(rayPoints: any[]): boolean {
        for (const pointDict of rayPoints) {
            for (const key in pointDict) {
                if (pointDict.hasOwnProperty(key)) {
                    const [point1, point2] = pointDict[key]
                    if (
                        this.chart.IsRayVisible(point2.x, point2.y, point1.x, point1.y) ||
                        this.chart.IsRayVisible(point1.x, point1.y, point2.x, point2.y)
                    ) {
                        return true
                    }
                }
            }
        }
        return false
    }

    public GetFrameRect(): TRect {
        const result: TRect = new TRect(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, 0, 0)

        for (let i = 0; i < this.fPoints.length; i++) {
            const point = this.fPoints[i]
            result.Left = Math.min(result.Left, point.x)
            result.Top = Math.min(result.Top, point.y)
            result.Right = Math.max(result.Right, point.x)
            result.Bottom = Math.max(result.Bottom, point.y)
        }

        return result
    }

    protected FillInside(): void {
        if (this.fPoints.length < 3 || !this.fShouldFillInside || !this.fClosedPolygon) {
            return
        }
        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        gdiPlusCanvas.ClearPath()
        gdiPlusCanvas.MoveTo(this.fPoints[0].x, this.fPoints[0].y)
        for (let i = 1; i < this.fPoints.length; i++) {
            gdiPlusCanvas.LineToPath(this.fPoints[i].x, this.fPoints[i].y)
        }
        gdiPlusCanvas.LineToPath(this.fPoints[0].x, this.fPoints[0].y)
        gdiPlusCanvas.FillPath(this.brush)
    }

    public PointCanMove(index: number, time: TDateTime, price: number): boolean {
        // Always returns true in the base class, but can be overridden by descendants
        return true
    }

    public OnMoveTool(dx: number, dy: number): void {
        for (let i = 0; i < this.fPoints.length; i++) {
            const point = this.fPoints[i]
            const dt: TDateTime = this.fChart.GetDateByIndex(point.s_index + dx)
            const p: number = point.s_price + dy

            if (this.fScreenCoords) {
                point.time = this.fChart.GetXFromDate(dt)
                point.price = this.fChart.GetY(p)
            } else {
                point.time = dt
                point.price = p
            }
        }

        this.invalidate()
    }

    public OnMovePoint(index: number, time: TDateTime, price: number): void {
        // Check if the point can be moved; if not, exit the method early.
        if (!this.PointCanMove(index, time, price)) {
            return
        }

        // Ensure the index is within the bounds of the fPoints array.
        if (index < 0 || index >= this.fPoints.length) {
            throw new StrangeError(`OnMovePoint - Index out of bounds: ${index}`)
        }

        // If fScreenCoords is true, convert time and price to screen coordinates.
        // Otherwise, assign them directly.
        if (this.fScreenCoords) {
            this.fPoints[index].time = this.fChart.GetXFromDate(time)
            this.fPoints[index].price = this.fChart.GetY(price)
        } else {
            this.fPoints[index].time = time
            this.fPoints[index].price = price
        }

        // Invalidate the chart to trigger a redraw with the updated points.
        this.invalidate()
    }

    // ... other methods and properties ...
    AddPoint(time: TDateTime, price: number): void {
        const point = new TPointInfo(time, price)
        point.x = time
        point.y = price
        point.time = time
        point.price = price
        this.fPoints.add(point)
    }

    SetAllTimeframes(): void {
        const activeTimeframes = this.SymbolData.GetActiveBarArrays()
        this.fTimeframes = new DelphiLikeArray()
        for (const activeTF of activeTimeframes) {
            this.fTimeframes.add(activeTF.DataDescriptor.timeframe)
        }
    }

    public assign(tool: TBasicPaintTool, isCopy = false): void {
        // copy points
        this.fPoints.clear()

        if (isCopy) {
            this.fPoints = this.adjustPointsToPaste(tool)
        } else {
            this.fPoints = tool.Points.clone()
        }

        // copy timeframes
        this.fTimeframes = new DelphiLikeArray<number>()
        for (let i = 0; i < tool.Timeframes.length; i++) {
            this.fTimeframes.add(tool.Timeframes[i])
        }

        // copy other vars
        this.ShortName = tool.ShortName
        this.CursorStyle = tool.CursorStyle
        this.fClosedPolygon = tool.ClosedPolygon
        this.fShouldFillInside = tool.ShouldFillInside
        this.fMaxPoints = tool.MaxPoints
        this.fScreenCoords = tool.ScreenCoords //TODO: check if this works. This is for the user to be able to leave some text on a chart and the text will stay in the same place when the chart is scrolled

        this.fLineStyle = tool.LineStyle
        this.fBrushStyle = tool.BrushStyle
        this.fBrushColor = tool.BrushColor
        this.fFontStyle = tool.fFontStyle
        this.brush = new IGPSolidBrush(tool.brush.getColor(), tool.brush.getOpacity(), tool.brush.getStyle())

        this.fText = tool.Text
        this.fTextVAlign = tool.TextVAlign
        this.fTextHAlign = tool.TextHAlign
        this.name = tool.name
        this.description = tool.description
        this.fLocked = tool.fLocked
        this.fExtendsRay = tool.fExtendsRay

        this.isSyncedWithOtherCharts = tool.isSyncedWithOtherCharts
        this.symbolName = tool.symbolName
    }

    public adjustPointsToPaste(tool: TBasicPaintTool): TPointsArr {
        const newArray: TPointsArr = new TPointsArr()
        const canvasWidth = this.fChart.HTML_Canvas.width
        const canvasHeight = this.fChart.HTML_Canvas.height

        let minX = Infinity,
            minY = Infinity,
            maxX = -Infinity,
            maxY = -Infinity
        for (const point of tool.fPoints) {
            if (point.x < minX) minX = point.x
            if (point.y < minY) minY = point.y
            if (point.x > maxX) maxX = point.x
            if (point.y > maxY) maxY = point.y
        }

        const toolFitsWithinCanvas = minX >= 0 && minY >= 0 && maxX <= canvasWidth && maxY <= canvasHeight

        if (toolFitsWithinCanvas) {
            for (const point of tool.fPoints) {
                const newTime = this.fChart.GetBarDateFromX(point.x)
                const newPrice = this.fChart.GetPriceFromY(point.y)

                const newPoint = new TPointInfo(point.x, point.y)
                newPoint.time = newTime
                newPoint.price = newPrice

                newArray.add(newPoint)
            }
            return newArray
        }

        let translateX = 0,
            translateY = 0

        if (minX < 0) {
            translateX = -minX
        } else if (maxX > canvasWidth) {
            translateX = canvasWidth - maxX
        }

        if (minY < 0) {
            translateY = -minY
        } else if (maxY > canvasHeight) {
            translateY = canvasHeight - maxY
        }

        for (const point of tool.fPoints) {
            const x = point.x + translateX
            const y = point.y + translateY

            const newTime = this.fChart.GetBarDateFromX(x)
            const newPrice = this.fChart.GetPriceFromY(y)

            const newPoint = new TPointInfo(x, y)
            newPoint.time = newTime
            newPoint.price = newPrice

            newArray.add(newPoint)
        }

        return newArray
    }

    public ShiftPoints(dateTimeDiff: number, priceDiff: number): void {
        for (let i = 0; i < this.fPoints.length; i++) {
            const point = this.fPoints[i]
            point.time = point.time + dateTimeDiff
            point.price += priceDiff
        }
    }

    public RecountScreenCoords(): void {
        for (let i = 0; i < this.fPoints.length; i++) {
            const screenPoint = this.PointToScreen(i)
            this.fPoints[i].x = screenPoint.x
            this.fPoints[i].y = screenPoint.y
        }
    }

    public PointToScreen(pointIndex: number): TPoint {
        const result = new TPoint(-1, -1)

        if (this.fScreenCoords) {
            result.x = Math.round(this.fPoints[pointIndex].time)
            result.y = Math.round(this.fPoints[pointIndex].price)
        } else {
            if (!DateUtils.IsEmpty(this.fPoints[pointIndex].time)) {
                ;(result.x = this.fChart.GetXFromDate(this.fPoints[pointIndex].time)),
                    (result.y = this.fChart.GetY(this.fPoints[pointIndex].price))
            }
        }

        return result
    }

    PointUnderMouse(x: number, y: number): number {
        for (let i = 0; i < this.fPoints.length; i++) {
            const p = this.PointToScreen(i)
            if (this.Distance2D(x, y, p.x, p.y) <= GlobalOptions.Options.MouseSensitivity) {
                return i
            }
        }
        return -1
    }

    Distance2D(x1: number, y1: number, x2: number, y2: number): number {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
    }

    public PrepareToMove(): void {
        for (let i = 0; i < this.fPoints.length; i++) {
            const point = this.fPoints[i]

            if (this.fScreenCoords) {
                point.s_index = this.fChart.GetIndexFromX(Math.round(point.time))
                point.s_price = this.fChart.GetPriceFromY(Math.round(point.price))
            } else {
                point.s_index = this.fChart.GetGlobalIndexByDate(
                    point.time,
                    TNoExactMatchBehavior.nemb_ReturnNearestLower,
                    true
                )
                point.s_price = point.price
            }
        }
    }

    // Determines if the tool can be placed on a given chart
    CanBePlaced(chart: TChart, ChartType: TChartType): boolean {
        if (chart === null) {
            return false
        }

        return true
    }

    OnMouseMove(time: TDateTime, price: number, chart: TChart): void {
        if (this.fStatus !== TPaintToolStatus.ts_Completed) {
            // update last point
            this.fPoints.LastItem.time = time
            this.fPoints.LastItem.price = price

            this.invalidate()
        }
    }

    // Handles mouse down events
    OnMouseDown(time: TDateTime, price: number, button: TMouseButton): void {
        if (this.fStatus !== TPaintToolStatus.ts_Completed && button === TMouseButton.mbLeft) {
            // update last point
            this.fPoints.LastItem.time = time
            this.fPoints.LastItem.price = price
            this.OnPointPlaced()

            if (this.fStatus === TPaintToolStatus.ts_FirstPoint) {
                this.fStatus = TPaintToolStatus.ts_InProgress
            }

            // check if there is no more points
            if (this.fPoints.Count < this.fMaxPoints) {
                this.AddPoint(time, price)
            } else {
                this.ReportEndOfWork()
            }

            this.invalidate()
        }

        if (this.fStatus !== TPaintToolStatus.ts_Completed && button === TMouseButton.mbRight) {
            if (this.ToolType === TPaintToolType.tt_Polyline && this.fPoints.Count > 2) {
                this.fPoints.Delete(this.fPoints.Count - 1)
                this.ReportEndOfWork()
            } else {
                if (this.ToolType === TPaintToolType.tt_Text) {
                    this.ExportToDialogBool = false
                }

                for (const chart of GlobalChartsController.Instance.getAllCharts()) {
                    chart.FreeActiveTool()
                    this.isNeededToCopyToOtherCharts = false
                    chart.MainChart.PaintTools.DeleteTool(this)
                    for (const oscWin of chart.OscWins) {
                        oscWin.chart.PaintTools.DeleteTool(this)
                    }
                }
                this.ReportCancelOfWork()
            }
        }
    }

    public ReadyToMove(x: number, y: number): boolean {
        return this.EdgeUnderMouse(x, y) !== -1
    }

    public ReadyToSize(x: number, y: number): boolean {
        return this.PointUnderMouse(x, y) !== -1
    }

    public EdgeUnderMouse(x: number, y: number): number {
        if (this.fPoints.length <= 1) {
            return -1
        }

        if (this.fExtendsRay.left && this.CheckExtendsRayAboveTheLine(x, y, this.fExtendsRay.leftfPoints)) {
            return 1
        }

        if (this.fExtendsRay.right && this.CheckExtendsRayAboveTheLine(x, y, this.fExtendsRay.rightfPoints)) {
            return 1
        }

        let p1 = this.PointToScreen(0)

        for (let i = 1; i < this.fPoints.length; i++) {
            const p2 = this.PointToScreen(i)
            if (this.PointAboveTheLine(x, y, p1.x, p1.y, p2.x, p2.y)) {
                return i - 1
            }
            p1 = p2
        }

        if (this.fClosedPolygon && this.fPoints.length > 2) {
            const p2 = this.PointToScreen(0)
            if (this.PointAboveTheLine(x, y, p1.x, p1.y, p2.x, p2.y)) {
                return this.fPoints.length - 1
            }
        }

        return -1
    }

    private CheckExtendsHorizontalAboveTheLine(
        x: number,
        y: number,
        pointsIndexes: number[],
        isLeft: boolean
    ): boolean {
        const topPoint = this.fPoints[pointsIndexes[0]]
        const bottomPoint = this.fPoints[pointsIndexes[1]]
        const canvasWidth = this.fChart.GdiCanvas.graphics.Context.canvas.width

        if (isLeft) {
            if (this.PointAboveTheLine(x, y, 0, topPoint.y, topPoint.x, topPoint.y)) {
                return true
            }
            if (this.PointAboveTheLine(x, y, 0, bottomPoint.y, bottomPoint.x, bottomPoint.y)) {
                return true
            }
        } else {
            if (this.PointAboveTheLine(x, y, topPoint.x, topPoint.y, canvasWidth, topPoint.y)) {
                return true
            }
            if (this.PointAboveTheLine(x, y, bottomPoint.x, bottomPoint.y, canvasWidth, bottomPoint.y)) {
                return true
            }
        }

        return false
    }

    private CheckExtendsRayAboveTheLine(x: number, y: number, rayPoints: any[]): boolean {
        for (const pointDict of rayPoints) {
            for (const key in pointDict) {
                if (pointDict.hasOwnProperty(key)) {
                    const [point1, point2] = pointDict[key]
                    const rayCoords = this.GetRayCoords(point1, point2)
                    if (this.PointAboveTheLine(x, y, rayCoords[0], rayCoords[1], rayCoords[2], rayCoords[3])) {
                        return true
                    }
                }
            }
        }
        return false
    }

    PointAboveTheLine(x: number, y: number, x1: number, y1: number, x2: number, y2: number): boolean {
        // Create a rectangle that bounds the line
        const R = TRect.SetRect(Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2))

        // Inflate the rectangle by the mouse sensitivity
        R.InflateRect(
            GlobalOptions.Options.MouseSensitivity,
            GlobalOptions.Options.MouseSensitivity,
            GlobalOptions.Options.MouseSensitivity,
            GlobalOptions.Options.MouseSensitivity
        )

        // Check if the point is not within the inflated rectangle
        if (!R.PtInRect_numbers(x, y)) {
            return false
        }

        // Check if the point is within the mouse sensitivity distance from the line
        return Common.DistancePtoLine(x1, y1, x2, y2, x, y) <= GlobalOptions.Options.MouseSensitivity
    }

    public MouseAboveTheTool(x: number, y: number): boolean {
        return this.ReadyToMove(x, y) || this.ReadyToSize(x, y)
    }

    public ExportToDialog(): void {
        throw new NotImplementedError('TBasicPaintTool.ExportToDialog')
    }

    public ImportFromDialog(): void {
        throw new NotImplementedError('TBasicPaintTool.ImportFromDialog')
    }

    public EditTool(): boolean {
        this.ExportToDialog()
        return false
    }

    public applyNewSettings(): void {
        const { setTrendLineData } = TrendLineStore

        const updateData = (key: string, value: boolean) => {
            setTrendLineData((prevData) => ({ ...prevData, [key]: value }))
        }

        // Import settings from the dialog
        this.ImportFromDialog()

        this.updatePanelToolData()

        updateData('saveSettings', false)

        // Invalidate the chart to reflect changes
        this.invalidate()
    }

    // Handles mouse up events (implementation not provided in Delphi code)
    OnMouseUp(time: TDateTime, price: number, button: TMouseButton): void {
        // Intentionally left blank in base class
    }

    // Called when a point is placed (implementation not provided in Delphi code)
    OnPointPlaced(): void {
        // Intentionally left blank in base class
    }

    // Called when construction is complete (implementation not provided in Delphi code)
    OnComplete(): void {
        // Intentionally left blank in base class
    }

    ReportCancelOfWork(): void {
        this.fStatus = TPaintToolStatus.ts_Completed
        this.chart.ChartWindow.OnToolCancelledWork()
    }

    // Reports the end of work
    ReportEndOfWork(): void {
        this.fStatus = TPaintToolStatus.ts_Completed
        this.OnComplete()
        const chartWindow = this.chart.ChartWindow
        chartWindow.OnToolCompletedWork() //TODO: change for events?
        //TODO: PostMessage(this.fChart.parent.Handle, msg_ToolCompletedWork, 0, 0);
    }

    private setIconAndPaintMarker(index: number, baseIcon: number): void {
        let icon: number
        if (this.Selected) {
            icon = baseIcon + 1
        } else {
            icon = baseIcon
        }

        if (this.Highlighted && index === this.HighlightedPoint) {
            icon = icon + 2
        }

        const p: TPoint = this.PointToScreen(index)

        // Assuming PaintMarker(x, y, icon) is implemented
        this.PaintMarker(p.x, p.y, icon)
    }

    protected PaintMarker_byNumber(index: number): void {
        this.setIconAndPaintMarker(index, 0)
    }

    private PaintRectMarker_ByNumber(index: number): void {
        this.setIconAndPaintMarker(index, 4)
    }

    /**
     * PaintMarker method - Paints a marker on the chart.
     * @param x X-coordinate for the marker
     * @param y Y-coordinate for the marker
     * @param icon Index of the icon to be used for the marker
     */
    PaintMarker(x: number, y: number, icon: number): void {
        // Create an instance of TRect instead of using an object literal
        const rect = new TRect(x - 10, y - 10, x + 11, y + 11)
        MarkerImagesManager.MarkerImgs[icon].Draw_inRect(this.fChart.HTML_Canvas, rect)
    }

    protected MarkersVisible(): boolean {
        return this.fSelected || this.fHighlighted || this.fStatus !== TPaintToolStatus.ts_Completed
    }

    protected PaintMarkers(isRect = false, indexes: number[] | null = null): void {
        if (!this.MarkersVisible()) {
            return
        }

        let cnt: number = this.Points.length - 1
        if (this.fStatus !== TPaintToolStatus.ts_Completed) {
            cnt--
        }

        for (let i = 0; i <= cnt; i++) {
            if (isRect && indexes !== null && indexes.includes(i)) {
                this.PaintRectMarker_ByNumber(i)
            } else {
                this.PaintMarker_byNumber(i)
            }
        }
    }

    // protected SetPen(style: TLineStyle, clearBrush: boolean = true): void {
    //     const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
    //     gdiPlusCanvas.setPenStyle(style.style)
    //     gdiPlusCanvas.setPenColor(style.color, ColorHelperFunctions.GetOpacity(style.color))
    //     gdiPlusCanvas.setPenWidth(style.width)
    //     if (clearBrush) {
    //         gdiPlusCanvas.brush.Style = TBrushStyle.bsClear
    //     }
    // }

    // protected SetFont(style: TMkFontStyle = this.fFontStyle): void {
    //     const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
    //     // Assuming SetFont is implemented
    //     gdiPlusCanvas.setFont(style.name, style.style, style.size)
    //     gdiPlusCanvas.setPenColor(style.color, ColorHelperFunctions.GetOpacity(style.color))
    // }
    AdjustExtendPoints(): void {
        //blank
    }

    PaintExtendsRay(): void {
        this.AdjustExtendPoints()
        if (this.fExtendsRay.right) {
            for (const pointDict of this.fExtendsRay.rightfPoints) {
                for (const key in pointDict) {
                    if (pointDict.hasOwnProperty(key)) {
                        const [point1, point2, style] = pointDict[key]
                        this.PaintRayWithText(point1, point2, '', style)
                    }
                }
            }
        }
        if (this.fExtendsRay.left) {
            for (const pointDict of this.fExtendsRay.leftfPoints) {
                for (const key in pointDict) {
                    if (pointDict.hasOwnProperty(key)) {
                        const [point1, point2, style] = pointDict[key]
                        this.PaintRayWithText(point1, point2, '', style)
                    }
                }
            }
        }
        if (this.fExtendsRay.left && this.fExtendsRay.fillLeftfPoints) {
            const context = this.fChart.GdiCanvas.graphics.Context
            let hexColor: string
            let opacity: number
            for (const pointDict of this.fExtendsRay.fillLeftfPoints) {
                for (const key in pointDict) {
                    if (pointDict.hasOwnProperty(key)) {
                        const points = pointDict[key]

                        if (Array.isArray(points[0]) && Array.isArray(points[1])) {
                            // xy extend
                            const [[point1, point2], [point3, point4], style] = points as [
                                [TPointInfo, TPointInfo],
                                [TPointInfo, TPointInfo],
                                IGPPen | null
                            ]

                            if (style) {
                                hexColor = style.color
                                opacity = style.opacity
                            } else {
                                hexColor = this.brush.getColor()
                                opacity = this.brush.getOpacity()
                            }
                            const r = parseInt(hexColor.slice(1, 3), 16)
                            const g = parseInt(hexColor.slice(3, 5), 16)
                            const b = parseInt(hexColor.slice(5, 7), 16)
                            context.fillStyle = `rgba(${r}, ${g}, ${b}, ${0.15})`
                            const [ray1x1, ray1y1, ray1x2, ray1y2] = this.GetRayCoords(point1, point2, false)
                            const [ray2x1, ray2y1, ray2x2, ray2y2] = this.GetRayCoords(point3, point4, false)
                            context.beginPath()
                            context.moveTo(Math.round(ray1x1) + 0.5, Math.round(ray1y1) + 0.5)
                            context.lineTo(Math.round(ray1x2) + 0.5, Math.round(ray1y2) + 0.5)
                            context.lineTo(Math.round(ray2x2) + 0.5, Math.round(ray2y2) + 0.5)
                            context.lineTo(Math.round(ray2x1) + 0.5, Math.round(ray2y1) + 0.5)
                            context.closePath()
                            context.fill()
                        } else {
                            // x extend
                            const [point1, point2, style] = points as [TPointInfo, TPointInfo, IGPPen | null]
                            if (style) {
                                hexColor = style.color
                                opacity = style.opacity
                            } else {
                                hexColor = this.brush.getColor()
                                opacity = this.brush.getOpacity()
                            }

                            const r = parseInt(hexColor.slice(1, 3), 16)
                            const g = parseInt(hexColor.slice(3, 5), 16)
                            const b = parseInt(hexColor.slice(5, 7), 16)
                            context.fillStyle = `rgba(${r}, ${g}, ${b}, ${opacity})`

                            context.beginPath()
                            context.moveTo(0, Math.round(point1.y) + 0.5)
                            context.lineTo(Math.round(point1.x) + 0.5, Math.round(point1.y) + 0.5)
                            context.lineTo(Math.round(point2.x) + 0.5, Math.round(point2.y) + 0.5)
                            context.lineTo(0, Math.round(point2.y) + 0.5)
                            context.closePath()
                            context.fill()
                        }
                    }
                }
            }
        }

        if (this.fExtendsRay.right && this.fExtendsRay.fillRightfPoints) {
            const context = this.fChart.GdiCanvas.graphics.Context
            const canvasWidth = this.fChart.GdiCanvas.graphics.Context.canvas.width

            for (const pointDict of this.fExtendsRay.fillRightfPoints) {
                for (const key in pointDict) {
                    let hexColor: string
                    let opacity: number
                    if (pointDict.hasOwnProperty(key)) {
                        const points = pointDict[key]

                        if (Array.isArray(points[0]) && Array.isArray(points[1])) {
                            const [[point1, point2], [point3, point4], style] = points as [
                                [TPointInfo, TPointInfo],
                                [TPointInfo, TPointInfo],
                                IGPPen | null
                            ]

                            if (style) {
                                hexColor = style.color
                                opacity = style.opacity
                            } else {
                                hexColor = this.brush.getColor()
                                opacity = this.brush.getOpacity()
                            }
                            const r = parseInt(hexColor.slice(1, 3), 16)
                            const g = parseInt(hexColor.slice(3, 5), 16)
                            const b = parseInt(hexColor.slice(5, 7), 16)
                            context.fillStyle = `rgba(${r}, ${g}, ${b}, ${0.15})`
                            const [ray1x1, ray1y1, ray1x2, ray1y2] = this.GetRayCoords(point1, point2, false)
                            const [ray2x1, ray2y1, ray2x2, ray2y2] = this.GetRayCoords(point3, point4, false)
                            context.beginPath()
                            context.moveTo(Math.round(ray1x1) + 0.5, Math.round(ray1y1) + 0.5)
                            context.lineTo(Math.round(ray1x2) + 0.5, Math.round(ray1y2) + 0.5)
                            context.lineTo(Math.round(ray2x2) + 0.5, Math.round(ray2y2) + 0.5)
                            context.lineTo(Math.round(ray2x1) + 0.5, Math.round(ray2y1) + 0.5)
                            context.closePath()
                            context.fill()
                        } else {
                            const [point1, point2, style] = points as [TPointInfo, TPointInfo, IGPPen | null]
                            if (style) {
                                hexColor = style.color
                                opacity = style.opacity
                            } else {
                                hexColor = this.brush.getColor()
                                opacity = this.brush.getOpacity()
                            }

                            const r = parseInt(hexColor.slice(1, 3), 16)
                            const g = parseInt(hexColor.slice(3, 5), 16)
                            const b = parseInt(hexColor.slice(5, 7), 16)
                            context.fillStyle = `rgba(${r}, ${g}, ${b}, ${opacity})`

                            context.beginPath()
                            context.moveTo(Math.round(point1.x) + 0.5, Math.round(point1.y) + 0.5)
                            context.lineTo(canvasWidth, Math.round(point1.y) + 0.5)
                            context.lineTo(canvasWidth, Math.round(point2.y) + 0.5)
                            context.lineTo(Math.round(point2.x) + 0.5, Math.round(point2.y) + 0.5)
                            context.closePath()
                            context.fill()
                        }
                    }
                }
            }
        }
    }

    protected PaintLines(): void {
        if (this.Points.length < 2) {
            return
        }

        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        this.fLineStyle.getPen().applyDashPattern(gdiPlusCanvas.graphics.Context)

        gdiPlusCanvas.MoveTo(this.Points[0].x, this.Points[0].y)
        for (let i = 1; i < this.Points.length; i++) {
            gdiPlusCanvas.LineTo(this.Points[i].x, this.Points[i].y, this.fLineStyle.getPen())
        }

        if (this.fClosedPolygon) {
            gdiPlusCanvas.LineTo(this.Points[0].x, this.Points[0].y, this.fLineStyle.getPen())
        }

        this.fLineStyle.getPen().restoreDashPattern(gdiPlusCanvas.graphics.Context)
    }

    protected PaintLine(p1: TPointInfo, p2: TPointInfo): void {
        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas

        this.fLineStyle.getPen().applyDashPattern(gdiPlusCanvas.graphics.Context)

        gdiPlusCanvas.MoveTo(p1.x, p1.y)
        gdiPlusCanvas.LineTo(p2.x, p2.y, this.fLineStyle.getPen())

        this.fLineStyle.getPen().restoreDashPattern(gdiPlusCanvas.graphics.Context)
    }

    protected GetTextOnTheLine(text: string): string {
        const count: number = StrsConv.GetNumOfQuotes(text, '{', '}')
        if (this.fTextVAlign !== 1 || DelphiMathCompatibility.Mod(count, 2) === 0) {
            return ''
        }

        let result = ''
        const idx: number = Math.floor(count / 2)
        for (let i = 0; i <= idx; i++) {
            result = StrsConv.GetQuotedStr(text, '{', '}')
        }
        return result
    }

    public GetLineTextSplitCoords(p1: TPointInfo, p2: TPointInfo, text: string): [number, number, number, number] {
        let x1: number = p1.x
        let y1: number = p1.y
        let x2: number = p2.x
        let y2: number = p2.y

        const w: number = Math.round(this.Distance2D(p1.x, p1.y, p2.x, p2.y))
        const ws: number = this.fChart.GdiCanvas.TextWidth(text) + 8
        if (ws >= w || w === 0) {
            return [x1, y1, x2, y2]
        }

        switch (this.fTextHAlign) {
            case 0: {
                const dx: number = ((x2 - x1) * (ws - 4)) / w
                const dy: number = ((y2 - y1) * (ws - 4)) / w
                x2 = x1 + dx
                y2 = y1 + dy
                break
            }
            case 1: {
                const dx: number = ((x2 - x1) * (w - ws)) / w / 2
                const dy: number = ((y2 - y1) * (w - ws)) / w / 2
                x1 = x1 + dx
                y1 = y1 + dy
                x2 = x2 - dx
                y2 = y2 - dy
                break
            }
            case 2: {
                const dx: number = ((x2 - x1) * (ws - 4)) / w
                const dy: number = ((y2 - y1) * (ws - 4)) / w
                x1 = x2 - dx
                y1 = y2 - dy
                break
            }
            default: {
                throw new StrangeError('Invalid value for fTextHAlign')
            }
        }

        return [x1, y1, x2, y2]
    }

    // Paints a line and text along it
    public PaintLineWithText(p1: TPointInfo, p2: TPointInfo, text: string): void {
        if (p1.x > p2.x) {
            const temp = p1
            p1 = p2
            p2 = temp
        }

        this.PaintLineWithTextSpace(p1, p2, text)

        if (text !== '') {
            this.PaintTextAlongLine(p1, p2, text)
        }
    }

    protected PaintLineWithTextSpace(p1: TPointInfo, p2: TPointInfo, text: string): void {
        const s: string = this.GetTextOnTheLine(text)
        if (s === '') {
            this.PaintLine(p1, p2)
            return
        }

        const [x1, y1, x2, y2] = this.GetLineTextSplitCoords(p1, p2, s)

        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        if (!this.SamePoint(p1, x1, y1)) {
            gdiPlusCanvas.MoveTo(p1.x, p1.y)
            gdiPlusCanvas.LineTo(x1, y1, this.fLineStyle.getPen())
        }

        if (!this.SamePoint(p2, x2, y2)) {
            gdiPlusCanvas.MoveTo(x2, y2)
            gdiPlusCanvas.LineTo(p2.x, p2.y, this.fLineStyle.getPen())
        }
    }

    protected GetRayCoords(p1: TPointInfo, p2: TPointInfo, checkVisibility = true): [number, number, number, number] {
        let ix1: number = p1.x
        let iy1: number = p1.y
        let ix2: number = p2.x
        let iy2: number = p2.y

        // Assuming chart.GetRayCoords is a method that calculates the coordinates for a ray
        // and returns them as a tuple. If this method does not exist, it needs to be implemented.
        const rayCoords = this.chart.GetRayCoords(ix1, iy1, ix2, iy2, p1.price, p2.price, checkVisibility)
        ;[ix1, iy1, ix2, iy2] = rayCoords as unknown as [number, number, number, number]

        return [ix1, iy1, ix2, iy2]
    }

    protected PaintRayWithText(p1: TPointInfo, p2: TPointInfo, text: string, style: IGPPen | null = null): void {
        let inverted = false

        if (p1.x > p2.x) {
            const temp = p1
            p1 = p2
            p2 = temp
            inverted = true
        }

        this.PaintRayWithTextSpace(p1, p2, text, inverted, style)

        if (text !== '') {
            this.PaintTextAlongLine(p1, p2, text)
        }
    }

    protected PaintRayWithTextSpace(
        p1: TPointInfo,
        p2: TPointInfo,
        text: string,
        inverted: boolean,
        style: IGPPen | null = null
    ): void {
        let s: string
        let rx1: number, ry1: number, rx2: number, ry2: number
        let x1: number, y1: number, x2: number, y2: number
        if (inverted) {
            ;[rx1, ry1, rx2, ry2] = this.GetRayCoords(p2, p1)
            ;[rx1, rx2] = [rx2, rx1]
            ;[ry1, ry2] = [ry2, ry1]
        } else {
            ;[rx1, ry1, rx2, ry2] = this.GetRayCoords(p1, p2)
        }

        s = this.GetTextOnTheLine(text)
        if (s === '') {
            const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
            gdiPlusCanvas.MoveTo(rx1, ry1)
            if (style) {
                gdiPlusCanvas.LineTo(rx2, ry2, style)
            } else {
                gdiPlusCanvas.LineTo(rx2, ry2, this.fLineStyle.getPen())
            }
            return
        }

        ;[x1, y1, x2, y2] = this.GetLineTextSplitCoords(p1, p2, s)

        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        if (!this.SamePoint(rx1, ry1, x1, y1)) {
            gdiPlusCanvas.MoveTo(rx1, ry1)
            gdiPlusCanvas.LineTo(x1, y1, this.fLineStyle.getPen())
        }

        if (!this.SamePoint(rx2, ry2, x2, y2)) {
            gdiPlusCanvas.MoveTo(x2, y2)
            gdiPlusCanvas.LineTo(rx2, ry2, this.fLineStyle.getPen())
        }
    }

    GetAngle(p1: TPointInfo, p2: TPointInfo): number {
        if (p1.x === p2.x && p1.y === p2.y) {
            return 0
        }

        if (p1.x === p2.x) {
            return p2.y > p1.y ? 90 : 270
        }

        // The angle is calculated using the arctan function,
        // converted from radians to degrees.
        return (Math.atan((p2.y - p1.y) / (p2.x - p1.x)) / Math.PI) * 180
    }

    // Overload definitions
    protected SamePoint(x1: number, y1: number, x2: number, y2: number): boolean
    protected SamePoint(p1: TPointInfo, x2: number, y2: number): boolean

    // Implementation
    protected SamePoint(x1: number | TPointInfo, y1: number, x2: number, y2?: number): boolean {
        if (typeof x1 === 'object') {
            // Handling TPointInfo with x2 and y2 as numbers
            return x1.x === x2 && x1.y === y1
        } else if (typeof y2 === 'number') {
            // Handling coordinates comparison
            return x1 === x2 && y1 === y2
        }

        throw new StrangeError('Invalid arguments for SamePoint')
    }

    protected PaintTextAlongLine(p1: TPointInfo, p2: TPointInfo, text: string): void {
        const s: string = text
        const count: number = StrsConv.GetNumOfQuotes(text, '{', '}')
        const angle: number = this.GetAngle(p1, p2)
        const w: number = Math.round(this.Distance2D(p1.x, p1.y, p2.x, p2.y))

        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        // gdiPlusCanvas.setPenColor(this.fFontStyle.color, ColorHelperFunctions.GetOpacity(this.fFontStyle.color))
        const h: number = gdiPlusCanvas.TextHeight('1', this.font)

        let y: number
        switch (this.fTextVAlign) {
            case 0: {
                y = -Math.round(count * h + this.fLineStyle.width / 2)
                break
            }
            case 1: {
                y = -((count * h) / 2)
                break
            }
            default: {
                y = Math.round(2 + this.fLineStyle.width / 2)
            }
        }

        gdiPlusCanvas.graphics.TranslateTransform(p1.x, p1.y)
        gdiPlusCanvas.graphics.RotateTransform(angle)

        try {
            while (s !== '') {
                const s1: string = StrsConv.GetQuotedStr(s, '{', '}')

                if (s1 !== '') {
                    const w1: number = gdiPlusCanvas.TextWidth(s1, this.font)
                    let x: number
                    switch (this.fTextHAlign) {
                        case 0: {
                            x = 0
                            break
                        }
                        case 1: {
                            x = (w - w1) / 2
                            break
                        }
                        default: {
                            x = w - w1
                        }
                    }
                    gdiPlusCanvas.textOut(x, y, s1, this.font, this.brush)
                }

                y += h
            }
        } finally {
            // Reset transformation after painting
            gdiPlusCanvas.graphics.ResetTransform()
        }
    }

    public Paint(): void {
        this.PaintLines()
        this.PaintMarkers()
    }

    public PaintHoverLine(points: TPoint[]): void {
        const context = this.fChart.GdiCanvas.graphics.Context
        if (context) {
            context.save()
            const highlightLineWidth = 10 // Set constant line width
            // Use entire length if fClosedPolygon is true; otherwise, use length - 1
            const cnt: number = this.fClosedPolygon ? points.length : points.length - 1

            for (let i = 0; i < cnt; i++) {
                const p1: TPoint = points[i]
                // Determine the second point of the line segment
                let p2: TPoint
                if (i < points.length - 1) {
                    p2 = points[i + 1]
                } else {
                    // If this.fClosedPolygon is true, the last line segment will connect the last point to the first point
                    p2 = this.fClosedPolygon ? points[0] : points[i]
                }

                // Draw line
                context.beginPath()
                context.moveTo(p1.x, p1.y)
                context.lineTo(p2.x, p2.y)
                context.strokeStyle = this.fHighlightLinesColor
                context.lineWidth = highlightLineWidth
                context.stroke()
            }
            context.restore()
        }
    }

    public invalidate(): void {
        //TODO: PostMessage(fChart.parent.Handle, msg_RefreshWindow, 0, 0);
        this.chart.invalidate()
    }

    public LoadFromList(list: TOffsStringList, all = true): void {
        const vars = new TVarList()
        vars.LoadFromList(list)

        this.fScreenCoords = vars.GetBool('ScreenCoords')

        if (all) {
            this.ToolName = vars.GetValue('ToolName')
            this.ShortName = vars.GetValue('ShortName')
            // Explicitly cast the result of GetInt to TPaintToolType for type safety
            this.fToolType = vars.GetInt('ToolType') as TPaintToolType
            this.LoadPointsStr(vars.GetValue('Points'))
            this.fMaxPoints = vars.GetInt('MaxPoints')
        }

        this.LoadTimeframesFromStr(vars.GetValue('TimeFrames'))
        this.fClosedPolygon = vars.GetBool('ClosedPolygon')
        this.fShouldFillInside = vars.GetBool('FillInside')
        this.description = vars.GetValue('Description')
        this.fText = vars.GetValue('Text')
        // Use the fit function to ensure the alignment values are within the expected range
        this.fTextVAlign = Common.fitNumberIntoRange(vars.GetInt('TextVAlign'), 0, 2)
        this.fTextHAlign = Common.fitNumberIntoRange(vars.GetInt('TextHAlign'), 0, 2)

        if (all) {
            this.LinkNumber = vars.GetInt('LinkNumber')
        } else {
            this.LinkNumber = 0
        }

        const lineStyle = vars.GetValue('LineStyle')
        this.fLineStyle.LoadFromStr(lineStyle)

        const fillStyle = vars.GetValue('FillStyle')
        // Use utility functions to parse the brush style and color from the string
        let remainingFillStyle: string
        ;[this.fBrushStyle, remainingFillStyle] = StrsConv.GetStrBrushStyle(fillStyle, ',')
        this.fBrushColor = StrsConv.GetColor(remainingFillStyle)

        const fontStyle = vars.GetValue('FontStyle')
        this.fFontStyle.LoadFromStr(fontStyle)

        if (all) {
            this.fStatus = TPaintToolStatus.ts_Completed
        }
    }

    public PointsToStr(): string {
        let result = ''
        for (let i = 0; i < this.fPoints.Count; i++) {
            if (this.fScreenCoords) {
                result += `{${Math.round(this.fPoints[i].time)},${Math.round(this.fPoints[i].price)}}`
            } else {
                result += `{${StrsConv.StrDateTime(this.fPoints[i].time, true)},${StrsConv.StrDouble(
                    this.fPoints[i].price,
                    6
                )}}`
            }
        }
        return result
    }

    public SaveTimeframesToStr(): string {
        let result = ''
        for (let i = 0; i < this.fTimeframes.length; i++) {
            result += `${this.fTimeframes[i].toString()},`
        }
        // Remove the trailing comma from the result string
        result = result.replace(/,$/, '')
        return result
    }

    public SaveToList(list: TOffsStringList, all = true): void {
        const vars = new TVarList()
        try {
            if (all) {
                vars.AddVarStr('ToolName', this.ToolName)
                vars.AddVarStr('ShortName', this.ShortName)
                vars.AddVarInt('ToolType', this.fToolType as unknown as number)
                vars.AddVarStr('Points', this.PointsToStr())
                vars.AddVarInt('MaxPoints', this.fMaxPoints)
            }

            vars.AddVarBool('ScreenCoords', this.fScreenCoords)
            vars.AddVarStr('TimeFrames', this.SaveTimeframesToStr())
            vars.AddVarBool('ClosedPolygon', this.fClosedPolygon)
            vars.AddVarBool('FillInside', this.fShouldFillInside)
            vars.AddVarStr('LineStyle', this.fLineStyle.SaveToStr())
            vars.AddVarStr('FontStyle', this.fFontStyle.SaveToStr())
            vars.AddVarStr('Description', this.description)
            vars.AddVarStr('Text', this.fText)
            vars.addVar('TextVAlign', this.fTextVAlign)
            vars.addVar('TextHAlign', this.fTextHAlign)

            if (all) {
                vars.AddVarInt('LinkNumber', this.LinkNumber)
            }

            vars.SaveToList(list, '')
        } finally {
            vars.Clear()
        }
    }

    public LoadPointsStr(s: string): void {
        this.fPoints.clear()
        s = s.trim()
        while (s !== '') {
            const s1: string = StrsConv.GetQuotedStr(s, '{', '}')
            let dt: TDateTime
            let value: number
            let remainingStr: string = s1

            if (this.fScreenCoords) {
                ;[dt, remainingStr] = StrsConv.GetStrInt(remainingStr, ',')
                ;[value, remainingStr] = StrsConv.GetStrInt(remainingStr)
            } else {
                ;[dt, remainingStr] = StrsConv.GetStrDateTime(remainingStr, ',')
                dt = dt + DateUtils.OneSecond / 10 // for renko and range bars
                ;[value, remainingStr] = StrsConv.GetStrDouble(remainingStr)
            }

            this.AddPoint(dt, value)
        }
    }

    protected LoadTimeframesFromStr(s: string): void {
        this.fTimeframes = new DelphiLikeArray<number>() // Assuming fTimeframes is an array, we initialize it as an empty array.
        s = s.trim()
        while (s !== '') {
            let result: [number, string]
            result = StrsConv.GetStrInt(s, ',')
            this.fTimeframes.push(result[0]) // We use push to add elements to the array instead of add method which is not standard for arrays.
            s = result[1].trim() // Trim the string after each extraction to ensure no whitespace is leading before the next number.
        }
    }

    onDblClick(event: MouseEvent): void {
        // show modal if double click on the tool

        if (this.fSelected) {
            this.EditTool()
        }
    }

    AddOpacityToColor(color: string, opacity: number): string {
        // Ensure the opacity is between 0 and 1
        const updatedOpacity = Math.max(0, Math.min(1, opacity))

        // Convert the opacity to an integer and then to a hexadecimal string
        const alphaHex = Math.round(updatedOpacity * 255)
            .toString(16)
            .padStart(2, '0')

        // Check if the color already has an alpha value
        if (color.length === 9) {
            // Replace the existing alpha value
            return color.slice(0, 7) + alphaHex
        } else {
            // Append the new alpha value
            return color + alphaHex
        }
    }

    getLineStyleParams(): {
        color: {
            value: TLineStyle['color']
            hasDifferentValues: boolean
        }
        style: {
            value: TLineStyle['style']
            hasDifferentValues: boolean
        }
        width: {
            value: TLineStyle['width']
            hasDifferentValues: boolean
        }
    } {
        return {
            color: {
                value: this.fLineStyle.color,
                hasDifferentValues: false
            },
            width: {
                value: this.fLineStyle.width,
                hasDifferentValues: false
            },
            style: {
                value: this.fLineStyle.style,
                hasDifferentValues: false
            }
        }
    }

    updatedToolAndLinkedTools() {
        const activeChart = GlobalChartsController.Instance.getActiveChart()

        activeChart && GlobalChartsController.Instance.OnUpdateLinkedTools(activeChart)
        GlobalChartsController.Instance.updateCharts()
    }

    setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        this.chart.ChartWindow.saveStateWithNotify()

        const { color, style, width } = styles
        const updatedLineStyles = new TLineStyle(color, style, width)

        this.fLineStyle = updatedLineStyles.clone()

        this.updatedToolAndLinkedTools()
    }

    updatePanelToolData() {
        const { updateParams } = GraphToolPanelStore

        if (this.ToolType !== TPaintToolType.tt_OrderMarker) {
            updateParams((prevSettings) => ({
                ...prevSettings,
                tools: prevSettings.tools.map((item) => (item.fToolName === this.fToolName ? this : item))
            }))
        }
    }

    getFillColorParams(): FillColorParamsType {
        return {
            color: {
                value: this.brush.getColor(),
                hasDifferentValues: false
            },
            opacity: this.brush.getOpacity(),
            shouldFillInside: this.fShouldFillInside
        }
    }

    setFillColorParams(color: string, opacity: number) {
        this.chart.ChartWindow.saveStateWithNotify()

        this.brush.setColor(color)
        this.brush.setOpacity(opacity)
        this.updatedToolAndLinkedTools()
    }

    getFontStyles(): FontStylesType {
        return {
            color: {
                value: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                hasDifferentValues: false
            },
            fontSize: this.font.getFontParams().size
        }
    }

    setFontStyles(color: string, fontSize: number) {
        // NOTE: method defined as default, will not call from THIS class such as hasText flag is always false.
        // Define hasText in selected tool to start use override method
        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            fontSize,
            StylingHelper.getTGpFontStyle({
                style: fontStyleParams.style,
                weight: fontStyleParams.weight
            })
        )
        this.brush.setColor(color)
        this.updatedToolAndLinkedTools()
    }

    public lock(): void {
        this.fLocked = true
        this.chart.ChartWindow.updateLinkedTool()
    }

    public unlock(): void {
        this.fLocked = false
        this.chart.ChartWindow.updateLinkedTool()
    }
}
