import OBV from '@fto/lib/extension_modules/indicators/built_in_indicators/OBV'
import WPR from '@fto/lib/extension_modules/indicators/built_in_indicators/WPR'

import { TChartType } from '../../../../ft_types/common/BasicClasses/BasicEnums'
import { TSymbolData } from '../../../../ft_types/data/SymbolData'
import { NotImplementedError } from '../../../../utils/common_utils'
import { TRuntimeIndicator } from '../../DllIndicatorUnit'
import { TIndicatorDescriptor } from '../../IndicatorDescriptor'
import { TIndicator } from '../../IndicatorUnit'
import ADX from '../ADX'
import ATR from '../ATR'
import BollingerBands from '../BollingerBands'
import CCI from '../CCI'
import DeMarker from '../DeMarker'
import Ichimoku from '../Ichimoku'
import MACD from '../MACD'
import Momentum from '../Momentum'
import MovingAverage from '../MovingAverage'
import RSI from '../RSI'
import Stochastic from '../Stochastic'
import Volume from '../Volume'
import MFI from '@fto/lib/extension_modules/indicators/built_in_indicators/MFI'
import iSession from '@fto/lib/extension_modules/indicators/built_in_indicators/ISession'
import FairValueGap from '@fto/lib/extension_modules/indicators/built_in_indicators/FVG'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TMAType, TOptValue_number } from '../../api/IndicatorInterfaceUnit'

function generateLibName(indicatorName: string): string {
    return `BuildInRuntimeIndicator_${indicatorName}_FTO_ourbestteam`
}

export default class BuiltInIndicatorDescriptors extends Array<TIndicatorDescriptor> {
    constructor() {
        super()
        this.push(new TIndicatorDescriptor('DeMarker'))
        this.push(new TIndicatorDescriptor('Volume'))
        this.push(new TIndicatorDescriptor('Momentum'))
        this.push(new TIndicatorDescriptor('CCI'))
        this.push(new TIndicatorDescriptor('RSI'))
        this.push(new TIndicatorDescriptor('Williams Percent Range'))
        this.push(new TIndicatorDescriptor('OBV'))
        this.push(new TIndicatorDescriptor('ATR'))
        this.push(new TIndicatorDescriptor('Ichimoku'))
        this.push(new TIndicatorDescriptor('Stochastic'))
        this.push(new TIndicatorDescriptor('ADX'))
        this.push(new TIndicatorDescriptor('Moving Average'))
        this.push(new TIndicatorDescriptor('Bollinger Bands'))
        this.push(new TIndicatorDescriptor('MACD'))
        this.push(new TIndicatorDescriptor('MFI'))
        this.push(new TIndicatorDescriptor('Trading Sessions'))
        this.push(new TIndicatorDescriptor('Fair Value Gap'))
        this.push(new TIndicatorDescriptor('SMA'))
        this.push(new TIndicatorDescriptor('EMA'))
        this.push(new TIndicatorDescriptor('SSMA'))
        this.push(new TIndicatorDescriptor('WMA'))
        // this.push(new TIndicatorDescriptor('ZigZag'))
        // this.push(new TIndicatorDescriptor('Heikin Ashi'))
        // this.push(new TIndicatorDescriptor('Pivot Points'))
        // this.push(new TIndicatorDescriptor('Parabolic SAR'))
    }

    findByName(name: string): TIndicatorDescriptor {
        for (let i = 0; i < this.length; i++) {
            if (this[i].ShortName.toLowerCase() === name.toLowerCase()) {
                return this[i]
            }
        }
        throw new StrangeError('Indicator not found.')
    }

    GetOrCreateRuntimeIndicator(
        indicator_descriptor: TIndicator,
        symbol: TSymbolData,
        timeframe: number,
        ChartType: TChartType
    ): TRuntimeIndicator {
        switch (indicator_descriptor.ShortName) {
            case 'Williams Percent Range':
                return new TRuntimeIndicator(new WPR(), symbol, timeframe, generateLibName('WPR'), ChartType)
            case 'OBV':
                return new TRuntimeIndicator(new OBV(), symbol, timeframe, generateLibName('OBV'), ChartType)
            case 'DeMarker':
                return new TRuntimeIndicator(new DeMarker(), symbol, timeframe, generateLibName('DeMarker'), ChartType)
            case 'Volume':
                return new TRuntimeIndicator(new Volume(), symbol, timeframe, generateLibName('Volume'), ChartType)
            case 'Momentum':
                return new TRuntimeIndicator(new Momentum(), symbol, timeframe, generateLibName('Momentum'), ChartType)
            case 'CCI':
                return new TRuntimeIndicator(new CCI(), symbol, timeframe, generateLibName('CCI'), ChartType)
            case 'RSI':
                return new TRuntimeIndicator(new RSI(), symbol, timeframe, generateLibName('RSI'), ChartType)
            case 'ATR':
                return new TRuntimeIndicator(new ATR(), symbol, timeframe, generateLibName('ATR'), ChartType)
            case 'Ichimoku':
                return new TRuntimeIndicator(new Ichimoku(), symbol, timeframe, generateLibName('Ichimoku'), ChartType)
            case 'Stochastic':
                return new TRuntimeIndicator(
                    new Stochastic(),
                    symbol,
                    timeframe,
                    generateLibName('Stochastic'),
                    ChartType
                )
            case 'ADX':
                return new TRuntimeIndicator(new ADX(), symbol, timeframe, generateLibName('ADX'), ChartType)
            case 'Moving Average':
                return new TRuntimeIndicator(
                    new MovingAverage(),
                    symbol,
                    timeframe,
                    generateLibName('MovingAverage'),
                    ChartType
                )
            case 'Bollinger Bands':
                return new TRuntimeIndicator(
                    new BollingerBands(),
                    symbol,
                    timeframe,
                    generateLibName('BollingerBands'),
                    ChartType
                )
            case 'MACD':
                return new TRuntimeIndicator(new MACD(), symbol, timeframe, generateLibName('MACD'), ChartType)
            case 'MFI':
                return new TRuntimeIndicator(new MFI(), symbol, timeframe, generateLibName('MFI'), ChartType)
            case 'Trading Sessions':
                return new TRuntimeIndicator(new iSession(), symbol, timeframe, generateLibName('iSession'), ChartType)
            case 'Fair Value Gap':
                return new TRuntimeIndicator(
                    new FairValueGap(),
                    symbol,
                    timeframe,
                    generateLibName('FairValueGap'),
                    ChartType
                )
            case 'EMA': {
                const MA = new MovingAverage()
                MA.MAtype = new TOptValue_number(TMAType.ma_EMA)
                return new TRuntimeIndicator(MA, symbol, timeframe, generateLibName('MovingAverage'), ChartType)
            }
            case 'SMA': {
                const MA = new MovingAverage()
                MA.MAtype = new TOptValue_number(TMAType.ma_SMA)
                return new TRuntimeIndicator(MA, symbol, timeframe, generateLibName('MovingAverage'), ChartType)
            }
            case 'SSMA': {
                const MA = new MovingAverage()
                MA.MAtype = new TOptValue_number(TMAType.ma_SSMA)
                return new TRuntimeIndicator(MA, symbol, timeframe, generateLibName('MovingAverage'), ChartType)
            }
            case 'WMA': {
                const MA = new MovingAverage()
                MA.MAtype = new TOptValue_number(TMAType.ma_WMA)
                return new TRuntimeIndicator(MA, symbol, timeframe, generateLibName('MovingAverage'), ChartType)
            }
            // case 'ZigZag':
            //     return new TRuntimeIndicator(new ZigZag(), symbol, timeframe, '', ChartType)
            // case 'Heikin Ashi':
            //     return new TRuntimeIndicator(new HeikinAshi(), symbol, timeframe, '', ChartType)
            // case 'Pivot Points':
            //     return new TRuntimeIndicator(new PivotPoints(), symbol, timeframe, '', ChartType)
            // case 'Parabolic SAR':
            //     return new TRuntimeIndicator(new ParabolicSAR(), symbol, timeframe, '', ChartType)

            default: {
                throw new NotImplementedError(`Indicator ${indicator_descriptor.ShortName} is not available`)
            }
        }
    }
}
