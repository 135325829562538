import { TDateTime } from '../delphi_compatibility/DateUtils'
import { DelphiColors, TColor, TPenStyle, TPoint } from '../delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '../drawing_interface/ColorHelperFunctions'
import { TLineStyle } from '../drawing_interface/vclCanvas'
import { TChartStyle } from './auxiliary_classes_charting/ChartingEnums'
import { OneClickTradingMode } from '@fto/chart_components/OneClickTrading'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'

export enum TCursorMode {
    cm_Default,
    cm_CrossHair,
    cm_CrossHairRooler
}

export interface TChartScale {
    BarWidth2: number // Half of the bar width
    PixBetweenBars: number // distance between centers of 2 bars
}

const NumOfColorSchemes = 1

export class TChartOptions {
    HorzMagnifier: number // scale of graph (1, 2, 4, 8, 16)
    MinHorzMagnifier: number
    MaxHorzMagnifier: number
    ColorScheme!: TColorScheme // Replace with actual type
    FixedGrid: boolean
    GridSize: number
    FixedScale: boolean
    FixedScaleBaseLevel: number
    VerticalScale: number
    FixedVScale: number
    Timeframe: number
    ChartStyle: TChartStyle
    ShowGrid: boolean
    AutoScroll: boolean
    RightOffset: boolean
    OffsetPercentage: number
    ShowVolume: boolean
    ShowPeriodSeparators: boolean
    ChartOnForeground: boolean
    ShowBidLevel: boolean
    ShowAskLevel: boolean
    ShowIndicatorValues: boolean
    ShowBalance!: boolean
    ShowMargin!: boolean
    ShowDrawdown!: boolean
    ProfitChartOldStyle: boolean
    ShowNotes: boolean
    ShowNews: boolean
    ShowHistory: boolean
    Version: number
    OneClickTradingMode: number
    MatchPriceWithBarsColor: boolean
    CustomPriceGoesUp: boolean
    CustomPriceGoesDown: boolean

    constructor() {
        this.HorzMagnifier = 12
        this.MinHorzMagnifier = 1
        this.MaxHorzMagnifier = 500
        this.SetColorScheme(0)
        this.FixedGrid = false
        this.GridSize = 20

        //scale block
        this.VerticalScale = 1
        this.FixedScale = false
        this.FixedScaleBaseLevel = 0
        this.FixedVScale = 1

        this.Timeframe = 15
        this.ChartStyle = TChartStyle.cs_Bar
        this.ShowGrid = true
        this.AutoScroll = true // TODO: Verify this is the correct default value check it when we will have last testing date
        this.RightOffset = true
        this.OffsetPercentage = 0.2
        this.ShowVolume = true
        this.ShowPeriodSeparators = true
        this.ChartOnForeground = false
        this.ShowBidLevel = true
        this.ShowAskLevel = false
        this.ShowIndicatorValues = true
        this.ProfitChartOldStyle = false
        this.ShowNotes = true
        this.ShowNews = true
        this.ShowHistory = true
        this.Version = 1
        this.OneClickTradingMode = OneClickTradingMode.FULL
        this.MatchPriceWithBarsColor = false
        this.CustomPriceGoesUp = false
        this.CustomPriceGoesDown = false
    }

    public getDefault(): TChartOptions {
        return new TChartOptions()
    }

    public reset() {
        const defaultOptions = this.getDefault()
        this.copyFrom(defaultOptions)
    }

    public copyFrom(other: TChartOptions) {
        this.HorzMagnifier = other.HorzMagnifier
        this.MinHorzMagnifier = other.MinHorzMagnifier
        this.MaxHorzMagnifier = other.MaxHorzMagnifier
        this.ColorScheme = other.ColorScheme.Clone()
        this.FixedGrid = other.FixedGrid
        this.GridSize = other.GridSize
        this.VerticalScale = other.VerticalScale
        this.FixedScale = other.FixedScale
        this.FixedScaleBaseLevel = other.FixedScaleBaseLevel
        this.FixedVScale = other.FixedVScale
        this.Timeframe = other.Timeframe
        this.ChartStyle = other.ChartStyle
        this.ShowGrid = other.ShowGrid
        this.AutoScroll = other.AutoScroll
        this.RightOffset = other.RightOffset
        this.OffsetPercentage = other.OffsetPercentage
        this.ShowVolume = other.ShowVolume
        this.ShowPeriodSeparators = other.ShowPeriodSeparators
        this.ChartOnForeground = other.ChartOnForeground
        this.ShowBidLevel = other.ShowBidLevel
        this.ShowAskLevel = other.ShowAskLevel
        this.ShowIndicatorValues = other.ShowIndicatorValues
        this.ProfitChartOldStyle = other.ProfitChartOldStyle
        this.ShowNotes = other.ShowNotes
        this.ShowNews = other.ShowNews
        this.MatchPriceWithBarsColor = other.MatchPriceWithBarsColor
        this.CustomPriceGoesUp = other.CustomPriceGoesUp
        this.CustomPriceGoesDown = other.CustomPriceGoesDown
    }

    public Clone(): TChartOptions {
        const cloneObj = this.getDefault()
        cloneObj.copyFrom(this)
        return cloneObj
    }

    public ApplyOptions(options: TChartOptions): void {
        //'as string & number' is a typescript bug workaround, more info https://stackoverflow.com/questions/74593347/type-never-inferred-on-thiskey-in-class-constructor
        for (const key of Object.keys(options)) {
            if (key === 'ColorScheme') {
                for (const colorKey of Object.keys(options.ColorScheme)) {
                    this.ColorScheme[colorKey as keyof TColorScheme] = options.ColorScheme[
                        colorKey as keyof TColorScheme
                    ] as string & number
                }
                continue
            }
            this[key as keyof TChartOptions] = options[key as keyof TChartOptions] as string & number
        }
    }

    SetColorScheme(index: number): void {
        // Assuming ColorSchemes is an array of TColorScheme objects available in the scope
        this.ColorScheme = ColorSchemes[index].Clone()
        // Assuming MakeColor is a function that modifies the color's opacity
        this.ColorScheme.PeriodSeparator.color = ColorHelperFunctions.MakeColor(
            this.ColorScheme.PeriodSeparator.color,
            50
        )
    }

    ColorSchemeIndex(ColorScheme: TColorScheme): number {
        return ColorSchemes.indexOf(ColorScheme)
    }

    SaveToList(list: unknown): void {
        // Implementation needed
    }

    LoadFromList(list: unknown): void {
        // Implementation needed
    }

    GetScaleInfo(): TChartScale {
        const result: TChartScale = {
            PixBetweenBars: this.HorzMagnifier,
            BarWidth2: Math.round((this.HorzMagnifier - 1) / 2.5)
        }

        return result
    }

    IsHScaleUpPossible(): boolean {
        return this.HorzMagnifier < this.MaxHorzMagnifier
    }

    IsHScaleDownPossible(): boolean {
        return this.HorzMagnifier > this.MinHorzMagnifier
    }

    public ZoomIn(zoomSpeedCoefficient = 1): void {
        // Calculate the increment size based on the current value
        const increment = this.calculateZoomIncrement(this.HorzMagnifier)
        this.HorzMagnifier += increment * zoomSpeedCoefficient

        // Ensure the magnifier does not exceed the maximum value
        if (this.HorzMagnifier > this.MaxHorzMagnifier) {
            this.HorzMagnifier = this.MaxHorzMagnifier
        }
    }

    public ZoomOut(zoomSpeedCoefficient = 1): void {
        // Calculate the decrement size based on the current value
        const decrement = this.calculateZoomIncrement(this.HorzMagnifier)
        this.HorzMagnifier -= decrement * zoomSpeedCoefficient

        // Ensure the magnifier does not go below the minimum value
        if (this.HorzMagnifier < this.MinHorzMagnifier) {
            this.HorzMagnifier = this.MinHorzMagnifier
        }
    }

    // Function to calculate the increment size based on the current magnifier value
    private calculateZoomIncrement(value: number): number {
        const minIncrement = 0.05
        const maxIncrement = 40.0
        const minRange = this.MinHorzMagnifier
        const maxRange = this.MaxHorzMagnifier

        // Normalize the current value to a range from 0 to 1
        const normalizedValue = (value - minRange) / (maxRange - minRange)

        // Calculate the increment based on the normalized value
        // Use an exponential function to make increments smaller near the minimum and larger near the maximum
        return minIncrement + (maxIncrement - minIncrement) * normalizedValue
    }

    findSchemeIndex(schemeName: string) {
        const schemeNames: Record<string, number> = {
            FT6: 0,
            'Green on Black': 1,
            'Red and Blue': 2,
            'Black and White': 3,
            'White on Black': 4,
            'Blue and Gray Medium': 5,
            'Blue and Gray': 6,
            'Green and Red Medium': 7,
            'Midnight Blue': 8
        }
        return schemeNames[schemeName] ?? -1
    }

    getCurrentSchemeIndex() {
        return this.ColorSchemeIndex(this.ColorScheme)
    }

    SetBarStyle(style: TChartStyle): void {
        this.ChartStyle = parseInt(style as unknown as string) as TChartStyle
    }

    applyAdditionalSettings(newSettings: any) {
        for (const key in newSettings) {
            if (this.hasOwnProperty(key)) {
                this[key as keyof typeof this] = newSettings[key]
            }
        }
    }
}

export class TColorScheme {
    public PeriodSeparator!: TLineStyle
    public BackgroundColor!: TColor
    public FrameAndTextColor!: TColor
    public FrameColor!: TColor
    public GridColor!: TColor
    public CandleUpColor!: TColor
    public CandleDownColor!: TColor
    public CandleUpFillerColor!: TColor
    public CandleDownFillerColor!: TColor
    public CandleUpShadowColor!: TColor
    public CandleDownShadowColor!: TColor
    public VolumeColor!: TColor
    public OrderLevelColor!: TColor
    public StopLossColor!: TColor
    public TakeProfitColor!: TColor
    public ProfitTransactionColor!: TColor
    public LossTransactionColor!: TColor
    public BuyMarkerColor!: TColor
    public SellMarkerColor!: TColor
    public BalanceColor!: TColor
    public EquityColor!: TColor
    public PeriodSeparatorsColor!: TColor
    public PriceLevelColor!: TColor
    public BidColor!: TColor
    public AskColor!: TColor
    public MarginColor!: TColor
    public DrawdownColor!: TColor
    public OscSplitterDefaultColor: TColor
    public OscSplitterHoverColor: TColor
    public CustomPriceGoesUpColor!: TColor
    public CustomPriceGoesDownColor!: TColor
    // public colors!: number[];
    // public colors2!: TColor[];

    constructor(params: {
        PeriodSeparator: TLineStyle
        BackgroundColor: TColor
        FrameAndTextColor: TColor
        FrameColor: TColor
        GridColor: TColor
        CandleUpColor: TColor
        CandleDownColor: TColor
        CandleUpFillerColor: TColor
        CandleDownFillerColor: TColor
        CandleUpShadowColor: TColor
        CandleDownShadowColor: TColor
        VolumeColor: TColor
        OrderLevelColor: TColor
        StopLossColor: TColor
        TakeProfitColor: TColor
        ProfitTransactionColor: TColor
        LossTransactionColor: TColor
        BuyMarkerColor: TColor
        SellMarkerColor: TColor
        BalanceColor: TColor
        EquityColor: TColor
        PeriodSeparatorsColor: TColor
        PriceLevelColor: TColor
        BidColor: TColor
        AskColor: TColor
        MarginColor: TColor
        DrawdownColor: TColor
        OscSplitterDefaultColor: TColor
        OscSplitterHoverColor: TColor
    }) {
        this.PeriodSeparator = params.PeriodSeparator
        this.BackgroundColor = params.BackgroundColor
        this.FrameAndTextColor = params.FrameAndTextColor
        this.FrameColor = params.FrameColor
        this.GridColor = params.GridColor
        this.CandleUpColor = params.CandleUpColor
        this.CandleDownColor = params.CandleDownColor
        this.CandleUpFillerColor = params.CandleUpFillerColor
        this.CandleDownFillerColor = params.CandleDownFillerColor
        this.CandleUpShadowColor = params.CandleUpShadowColor
        this.CandleDownShadowColor = params.CandleDownShadowColor
        this.VolumeColor = params.VolumeColor
        this.OrderLevelColor = params.OrderLevelColor
        this.StopLossColor = params.StopLossColor
        this.TakeProfitColor = params.TakeProfitColor
        this.ProfitTransactionColor = params.ProfitTransactionColor
        this.LossTransactionColor = params.LossTransactionColor
        this.BuyMarkerColor = params.BuyMarkerColor
        this.SellMarkerColor = params.SellMarkerColor
        this.BalanceColor = params.BalanceColor
        this.EquityColor = params.EquityColor
        this.PeriodSeparatorsColor = params.PeriodSeparatorsColor
        this.PriceLevelColor = params.PriceLevelColor
        this.BidColor = params.BidColor
        this.AskColor = params.AskColor
        this.MarginColor = params.MarginColor
        this.DrawdownColor = params.DrawdownColor
        this.OscSplitterDefaultColor = params.OscSplitterDefaultColor
        this.OscSplitterHoverColor = params.OscSplitterHoverColor
        this.CustomPriceGoesUpColor = params.CandleUpColor
        this.CustomPriceGoesDownColor = params.CandleDownColor
    }

    public getDefault(): TColorScheme {
        return new TColorScheme({
            PeriodSeparator: new TLineStyle('#000000', TPenStyle.psDot, 1), // clBlack in Delphi
            BackgroundColor: '#FFFFFF', // $FFFFFF in Delphi
            FrameAndTextColor: '#2C2C2C', // $2C2C2C in Delphi
            FrameColor: '#98A2B3', // $2C2C2C in Delphi
            GridColor: '#D0D5DD', // Converted from $F2ECE1 in Delphi BGR
            CandleUpColor: '#26A69A', // Converted from $9AA626 in Delphi BGR
            CandleDownColor: '#EF5350', // Converted from $5053EF in Delphi BGR
            CandleUpShadowColor: '#26A69A',
            CandleDownShadowColor: '#EF5350',
            // CandleDownColor: '#EF5350', // Converted from $5053EF in Delphi BGR
            CandleUpFillerColor: '#26A69A', // Same as CandleUpColor
            CandleDownFillerColor: '#EF5350', // Same as CandleDownColor
            // CandleDownFillerColor: '#EF5350', // Same as CandleDownColor
            VolumeColor: '#92D2CC', // Converted from $CCD292 in Delphi BGR
            OrderLevelColor: '#19D276', // Converted from $D27619 in Delphi BGR
            StopLossColor: '#E99537', // Same as CandleDownColor
            TakeProfitColor: '#01987C', // Same as CandleDownColor
            ProfitTransactionColor: '#01987C', // Same as CandleUpColor
            LossTransactionColor: '#FF6462', // Same as CandleDownColorr
            BuyMarkerColor: '#2F80ED', // Same as CandleUpColor
            SellMarkerColor: '#FF4846', // Same as CandleDownColor
            BalanceColor: '#D30094', // Converted from TColors.DarkViolet in Delphi BGR
            EquityColor: '#FF0000', // Converted from $0000FF in Delphi BGR
            PeriodSeparatorsColor: '#98A2B3',
            PriceLevelColor: '#2C2C2C', // Same as FrameAndTextColor
            BidColor: '#2F80ED', // Same as FrameAndTextColor
            AskColor: '#F2B347', // Same as FrameAndTextColor
            MarginColor: '#808080', // clGray in Delphi
            DrawdownColor: '#000080', // Converted from clMaroon in Delphi BGR
            OscSplitterDefaultColor: '#000000',
            OscSplitterHoverColor: '#000000'
        })
    }

    public reset() {
        const defaultScheme = this.getDefault()
        this.copyFrom(defaultScheme)
    }

    public copyFrom(other: TColorScheme) {
        this.PeriodSeparator = other.PeriodSeparator.clone()
        this.BackgroundColor = other.BackgroundColor
        this.FrameAndTextColor = other.FrameAndTextColor
        this.GridColor = other.GridColor
        this.CandleUpColor = other.CandleUpColor
        this.CandleDownColor = other.CandleDownColor
        this.CandleUpFillerColor = other.CandleUpFillerColor
        this.CandleDownFillerColor = other.CandleDownFillerColor
        this.VolumeColor = other.VolumeColor
        this.OrderLevelColor = other.OrderLevelColor
        this.StopLossColor = other.StopLossColor
        this.TakeProfitColor = other.TakeProfitColor
        this.ProfitTransactionColor = other.ProfitTransactionColor
        this.LossTransactionColor = other.LossTransactionColor
        this.BuyMarkerColor = other.BuyMarkerColor
        this.SellMarkerColor = other.SellMarkerColor
        this.BalanceColor = other.BalanceColor
        this.EquityColor = other.EquityColor
        this.PeriodSeparatorsColor = other.PeriodSeparatorsColor
        this.PriceLevelColor = other.PriceLevelColor
        this.BidColor = other.BidColor
        this.AskColor = other.AskColor
        this.MarginColor = other.MarginColor
        this.DrawdownColor = other.DrawdownColor
        this.OscSplitterDefaultColor = other.OscSplitterDefaultColor
        this.OscSplitterHoverColor = other.OscSplitterHoverColor
        this.CustomPriceGoesUpColor = other.CandleUpColor
        this.CustomPriceGoesDownColor = other.CandleDownColor
    }

    public Clone(): TColorScheme {
        const cloneObj = this.getDefault()
        cloneObj.copyFrom(this)
        return cloneObj
    }

    public processDefaultValuesByVersion(version: number | undefined): void {
        if (version === undefined) {
            this.PeriodSeparatorsColor = '#98A2B3'
            this.CandleUpShadowColor = '#26A69A'
            this.CandleDownShadowColor = '#EF5350'
        }
    }

    public SaveToStr(): string {
        return JSON.stringify(this)
    }

    public LoadFromStr(s: string): void {
        try {
            const obj = JSON.parse(s)

            this.PeriodSeparator = new TLineStyle(
                obj.PeriodSeparator.color,
                obj.PeriodSeparator.style,
                obj.PeriodSeparator.width
            )
            this.BackgroundColor = obj.BackgroundColor
            this.FrameAndTextColor = obj.FrameAndTextColor
            this.FrameColor = obj.FrameColor
            this.GridColor = obj.GridColor
            this.CandleUpColor = obj.CandleUpColor
            this.CandleDownColor = obj.CandleDownColor
            this.CandleUpFillerColor = obj.CandleUpFillerColor
            this.CandleDownFillerColor = obj.CandleDownFillerColor
            this.CandleUpShadowColor = obj.CandleUpShadowColor
            this.CandleDownShadowColor = obj.CandleDownShadowColor
            this.VolumeColor = obj.VolumeColor
            this.OrderLevelColor = obj.OrderLevelColor
            this.StopLossColor = obj.StopLossColor
            this.TakeProfitColor = obj.TakeProfitColor
            this.ProfitTransactionColor = obj.ProfitTransactionColor
            this.LossTransactionColor = obj.LossTransactionColor
            this.BuyMarkerColor = obj.BuyMarkerColor
            this.SellMarkerColor = obj.SellMarkerColor
            this.BalanceColor = obj.BalanceColor
            this.EquityColor = obj.EquityColor
            this.PeriodSeparatorsColor = obj.PeriodSeparatorsColor
            this.PriceLevelColor = obj.PriceLevelColor
            this.BidColor = obj.BidColor
            this.AskColor = obj.AskColor
            this.MarginColor = obj.MarginColor
            this.DrawdownColor = obj.DrawdownColor
            this.OscSplitterDefaultColor = obj.OscSplitterDefaultColor
            this.OscSplitterHoverColor = obj.OscSplitterHoverColor
            this.CustomPriceGoesUpColor = obj.CustomPriceGoesUpColor ?? this.CustomPriceGoesUpColor
            this.CustomPriceGoesDownColor = obj.CustomPriceGoesDownColor ?? this.CustomPriceGoesDownColor
            this.processDefaultValuesByVersion(obj.Version)
        } catch {
            // do nothing, for old projects if there is no color scheme yet
        }
    }
}

const ColorSchemes: TColorScheme[] = [
    // new scheme FT6
    new TColorScheme({
        PeriodSeparator: new TLineStyle('#000000', TPenStyle.psDot, 1), // clBlack in Delphi
        BackgroundColor: '#FFFFFF', // $FFFFFF in Delphi
        FrameAndTextColor: '#2C2C2C', // $2C2C2C in Delphi
        FrameColor: '#98A2B3',
        GridColor: '#D0D5DD', // Converted from $F2ECE1 in Delphi BGR
        CandleUpColor: '#26A69A', // Converted from $9AA626 in Delphi BGR
        CandleDownColor: '#EF5350', // Converted from $5053EF in Delphi BGR
        // CandleDownColor: '#EF5350', // Converted from $5053EF in Delphi BGR
        CandleUpFillerColor: '#26A69A', // Same as CandleUpColor
        CandleDownFillerColor: '#EF5350', // Same as CandleDownColor
        CandleUpShadowColor: '#26A69A',
        CandleDownShadowColor: '#EF5350',
        // CandleDownFillerColor: '#EF5350', // Same as CandleDownColor
        VolumeColor: '#92D2CC', // Converted from $CCD292 in Delphi BGR
        OrderLevelColor: '#19D276', // Converted from $D27619 in Delphi BGR
        StopLossColor: '#E99537', // Same as CandleDownColor
        TakeProfitColor: '#01987C', // Same as CandleDownColor
        ProfitTransactionColor: '#01987C', // Same as CandleUpColor
        LossTransactionColor: '#FF6462', // Same as CandleDownColor
        BuyMarkerColor: '#2F80ED', // Same as CandleUpColor
        SellMarkerColor: '#FF4846', // Same as CandleDownColor
        BalanceColor: '#D30094', // Converted from TColors.DarkViolet in Delphi BGR
        EquityColor: '#FF0000', // Converted from $0000FF in Delphi BGR
        PeriodSeparatorsColor: '#98A2B3', // clBlack in Delphi
        PriceLevelColor: '#2C2C2C', // Same as FrameAndTextColor
        BidColor: '#2F80ED', // Same as FrameAndTextColor
        AskColor: '#F2B347', // Same as FrameAndTextColor
        MarginColor: '#808080', // clGray in Delphi
        DrawdownColor: '#000080', // Converted from clMaroon in Delphi BGR
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),
    // Green on black
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clWhite, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clBlack,
        FrameAndTextColor: DelphiColors.clWhite,
        FrameColor: '#98A2B3',
        GridColor: '#502525',
        CandleUpColor: DelphiColors.clLime,
        CandleDownColor: DelphiColors.clLime,
        CandleUpFillerColor: DelphiColors.clBlack,
        CandleDownFillerColor: DelphiColors.clWhite,
        CandleUpShadowColor: DelphiColors.clLime,
        CandleDownShadowColor: DelphiColors.clLime,
        VolumeColor: '#32CD32',
        OrderLevelColor: DelphiColors.clGreen,
        StopLossColor: DelphiColors.clRed,
        TakeProfitColor: DelphiColors.clRed,
        ProfitTransactionColor: DelphiColors.clYellow,
        LossTransactionColor: DelphiColors.clRed,
        BuyMarkerColor: DelphiColors.clRed,
        SellMarkerColor: DelphiColors.clYellow,
        BalanceColor: '#9400d3', //darkviolet
        EquityColor: DelphiColors.clBlue,
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#8B3D48',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: DelphiColors.clGray,
        DrawdownColor: DelphiColors.clMaroon,
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // Red and blue
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clBlack, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clWhite,
        FrameAndTextColor: DelphiColors.clBlack,
        FrameColor: '#98A2B3',
        GridColor: '#BFD7BF',
        CandleUpColor: DelphiColors.clBlue,
        CandleDownColor: DelphiColors.clRed,
        CandleUpFillerColor: DelphiColors.clBlue,
        CandleDownFillerColor: DelphiColors.clRed,
        CandleUpShadowColor: DelphiColors.clBlue,
        CandleDownShadowColor: DelphiColors.clRed,
        VolumeColor: DelphiColors.clGreen,
        OrderLevelColor: DelphiColors.clGreen,
        StopLossColor: DelphiColors.clRed,
        TakeProfitColor: DelphiColors.clRed,
        ProfitTransactionColor: DelphiColors.clGreen,
        LossTransactionColor: DelphiColors.clRed,
        BuyMarkerColor: DelphiColors.clRed,
        SellMarkerColor: DelphiColors.clGreen,
        BalanceColor: DelphiColors.clBlue,
        EquityColor: DelphiColors.clRed,
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#BFD7BF',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: DelphiColors.clGray,
        DrawdownColor: DelphiColors.clMaroon,
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // Black and white
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clBlack, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clWhite,
        FrameAndTextColor: DelphiColors.clBlack,
        FrameColor: '#98A2B3',
        GridColor: DelphiColors.clSilver,
        CandleUpColor: DelphiColors.clBlack,
        CandleDownColor: DelphiColors.clBlack,
        CandleUpFillerColor: DelphiColors.clWhite,
        CandleDownFillerColor: DelphiColors.clBlack,
        CandleUpShadowColor: DelphiColors.clBlack,
        CandleDownShadowColor: DelphiColors.clBlack,
        VolumeColor: DelphiColors.clGreen,
        OrderLevelColor: DelphiColors.clGreen,
        StopLossColor: DelphiColors.clRed,
        TakeProfitColor: DelphiColors.clRed,
        ProfitTransactionColor: DelphiColors.clGreen,
        LossTransactionColor: DelphiColors.clRed,
        BuyMarkerColor: DelphiColors.clRed,
        SellMarkerColor: DelphiColors.clGreen,
        BalanceColor: DelphiColors.clBlue,
        EquityColor: DelphiColors.clRed,
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#BFD7BF',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: DelphiColors.clGray,
        DrawdownColor: DelphiColors.clMaroon,
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // White on black
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clBlack, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clBlack,
        FrameAndTextColor: DelphiColors.clWhite,
        FrameColor: '#98A2B3',
        GridColor: '#808070',
        CandleUpColor: DelphiColors.clWhite,
        CandleDownColor: DelphiColors.clWhite,
        CandleUpFillerColor: DelphiColors.clBlack,
        CandleDownFillerColor: DelphiColors.clWhite,
        CandleUpShadowColor: DelphiColors.clWhite,
        CandleDownShadowColor: DelphiColors.clWhite,
        VolumeColor: '#8FBC8F',
        OrderLevelColor: '#B48246',
        StopLossColor: '#0000FF',
        TakeProfitColor: '#0000FF',
        ProfitTransactionColor: '#32CD32',
        LossTransactionColor: '#0000FF',
        BuyMarkerColor: '#FFBF00',
        SellMarkerColor: '#FF00FF',
        BalanceColor: '#32CD32',
        EquityColor: '#EBCE87',
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#998877',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: '#A9A9A9',
        DrawdownColor: '#7AA0FF',
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // Blue and Gray medium
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clBlack, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clWhite,
        FrameAndTextColor: DelphiColors.clBlack,
        FrameColor: '#98A2B3',
        GridColor: '#D3D3D3',
        CandleUpColor: '#8B3D48',
        CandleDownColor: '#8B3D48',
        CandleUpFillerColor: '#E6E0B0',
        CandleDownFillerColor: '#A9A9A9',
        CandleUpShadowColor: '#8B3D48',
        CandleDownShadowColor: '#8B3D48',
        VolumeColor: '#71B33C',
        OrderLevelColor: '#008000',
        StopLossColor: '#0000FF',
        TakeProfitColor: '#0000FF',
        ProfitTransactionColor: '#008000',
        LossTransactionColor: '#0000FF',
        BuyMarkerColor: '#CD0000',
        SellMarkerColor: '#9314FF',
        BalanceColor: '#FF0000',
        EquityColor: '#0000FF',
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#BFD7BF',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: '#808080',
        DrawdownColor: '#13458B',
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // Blue and Gray
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clBlack, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clWhite,
        FrameAndTextColor: DelphiColors.clBlack,
        FrameColor: '#98A2B3',
        GridColor: '#D3D3D3',
        CandleUpColor: '#E16941',
        CandleDownColor: '#696969',
        CandleUpFillerColor: '#FFF8F0',
        CandleDownFillerColor: '#A9A9A9',
        CandleUpShadowColor: '#E16941',
        CandleDownShadowColor: '#696969',
        VolumeColor: '#008000',
        OrderLevelColor: '#008000',
        StopLossColor: '#0000FF',
        TakeProfitColor: '#0000FF',
        ProfitTransactionColor: '#008000',
        LossTransactionColor: '#0000FF',
        BuyMarkerColor: '#CD0000',
        SellMarkerColor: '#9314FF',
        BalanceColor: '#FF0000',
        EquityColor: '#0000FF',
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#BFD7BF',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: '#808080',
        DrawdownColor: '#000080',
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // Green and Red medium
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clBlack, TPenStyle.psDot, 1),
        BackgroundColor: DelphiColors.clWhite,
        FrameAndTextColor: DelphiColors.clBlack,
        FrameColor: '#98A2B3',
        GridColor: '#D3D3D3',
        CandleUpColor: '#008000',
        CandleDownColor: '#0000FF',
        CandleUpFillerColor: '#32CD32',
        CandleDownFillerColor: '#4763FF',
        CandleUpShadowColor: '#008000',
        CandleDownShadowColor: '#0000FF',
        VolumeColor: '#7AA0FF',
        OrderLevelColor: '#008000',
        StopLossColor: '#0000FF',
        TakeProfitColor: '#0000FF',
        ProfitTransactionColor: '#FF901E',
        LossTransactionColor: '#FF00FF',
        BuyMarkerColor: '#CD0000',
        SellMarkerColor: '#9314FF',
        BalanceColor: '#FF0000',
        EquityColor: '#0000FF',
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#CCD148',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: '#808080',
        DrawdownColor: '#13458B',
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    }),

    // Midnight blue
    new TColorScheme({
        PeriodSeparator: new TLineStyle(DelphiColors.clWhite, TPenStyle.psDot, 1),
        BackgroundColor: '#701919',
        FrameAndTextColor: DelphiColors.clWhite,
        FrameColor: '#98A2B3',
        GridColor: '#808070',
        CandleUpColor: DelphiColors.clWhite,
        CandleDownColor: DelphiColors.clWhite,
        CandleUpFillerColor: '#701919',
        CandleDownFillerColor: DelphiColors.clWhite,
        CandleUpShadowColor: DelphiColors.clWhite,
        CandleDownShadowColor: DelphiColors.clWhite,
        VolumeColor: '#8FBC8F',
        OrderLevelColor: '#998877',
        StopLossColor: '#AAB220',
        TakeProfitColor: '#AAB220',
        ProfitTransactionColor: '#32CD32',
        LossTransactionColor: '#0000FF',
        BuyMarkerColor: '#FFBF00',
        SellMarkerColor: '#FF00FF',
        BalanceColor: '#00FC7C',
        EquityColor: '#FFFFFF',
        PeriodSeparatorsColor: '#98A2B3',
        PriceLevelColor: '#998877',
        BidColor: '#2F80ED',
        AskColor: '#F2B347',
        MarginColor: '#A9A9A9',
        DrawdownColor: '#C1B6FF',
        OscSplitterDefaultColor: '#000000',
        OscSplitterHoverColor: '#f1f1f1' + '95'
    })
]

export class TCrossHair {
    public DateTime!: TDateTime
    public point!: TPoint
    public roolerDateTime!: TDateTime
    public roolerPoint!: TPoint
    public RoundRoolerV!: boolean
}

export class TPosMarker {
    public DateTime!: TDateTime
    public price!: number

    constructor() {
        this.DateTime = -1
        this.price = -1
    }
}
