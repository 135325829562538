import { TBufferStyle } from '@fto/lib/drawing_interface/vclCanvas'

export const prepareBuffer = (prevBufferData: any, values: { [key: string]: any }) => {
    const bufferData = {
        color: prevBufferData.color,
        style: prevBufferData.style,
        width: prevBufferData.width,
        isVisible: prevBufferData.isVisible,
        ...values
    }
    return new TBufferStyle(bufferData.color, bufferData.style, bufferData.width, bufferData.isVisible)
}
