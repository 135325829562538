import { ChartControl, chartControlEvent, ChartControlId, ChartControlParams } from '@fto/chart_components/ChartControl'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { addContextMenu, removeContextMenu } from '@fto/ui'
import { TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import oneClickTradingStore from '@fto/lib/store/oneClickTradingStore'

export enum OneClickTradingMode {
    FULL,
    READONLY,
    OFF
}
export class OneClickTrading extends ChartControl {
    private mode: OneClickTradingMode = OneClickTradingMode.FULL
    private colors = {
        sell: {
            default: new IGPSolidBrush('#FF6462'),
            hover: new IGPSolidBrush('#FF4846'),
            label: new IGPSolidBrush('#FFFFFF')
        },
        spread: {
            default: new IGPSolidBrush('#FFFFFF'),
            hover: new IGPSolidBrush('#FFFFFF'),
            label: new IGPSolidBrush('#000000')
        },
        buy: {
            default: new IGPSolidBrush('#1B70E2'),
            hover: new IGPSolidBrush('#115EC7'),
            label: new IGPSolidBrush('#FFFFFF')
        }
    }
    private font = new IGPFont(new TGPFontFamily('Roboto Flex'), 12, [])
    private fontTail = new IGPFont(new TGPFontFamily('Roboto Flex'), 8, [])
    private labels = {
        sell: '1.2345',
        spread: '0.2',
        buy: '1.9876'
    }

    private sellControl!: ChartControl
    private spreadControl!: ChartControl
    private buyControl!: ChartControl
    private images: { sell: HTMLImageElement | null; buy: HTMLImageElement | null } = {
        sell: GlobalImageManager.Instance.trendDown,
        buy: GlobalImageManager.Instance.trendUp
    }

    private isInputVisible = false

    private offsetGrid = [20, 18, 16, 14, 12, 10, 8, 6, 4, 3, 2, 2]

    constructor(chartControlParams: ChartControlParams, mode: OneClickTradingMode | number = OneClickTradingMode.FULL) {
        super(chartControlParams)

        this.mode =
            typeof mode === 'number' && mode in OneClickTradingMode
                ? (mode as OneClickTradingMode)
                : OneClickTradingMode.FULL

        this.sellControl = new ChartControl(chartControlParams.clone())
        this.sellControl.controlId = ChartControlId.ONE_CLICK_SELL

        this.spreadControl = new ChartControl(chartControlParams.clone())
        this.spreadControl.controlId = ChartControlId.ONE_CLICK_SPREAD

        this.buyControl = new ChartControl(chartControlParams.clone())
        this.buyControl.controlId = ChartControlId.ONE_CLICK_BUY

        const chartWindow = this.getOwnerLayer()?.getChartWindow()
        if(chartWindow) {
            this.sellControl.attachObserver(chartWindow)
            this.spreadControl.attachObserver(chartWindow)
            this.buyControl.attachObserver(chartWindow)
        }

        this.repositionControls()

    }

    private repositionControls(x: number = 0) {
        let gridLocationControls = [0, 84, 118]

        let locationBackplate = this.getLocation()

        if(x !== 0) {
            locationBackplate.Left = x
        }

        let locationSell = this.getLocation()
        locationSell.Left = locationBackplate.Left + gridLocationControls[0]
        locationSell.Right = locationBackplate.Left + gridLocationControls[1]
        this.sellControl.setLocation(locationSell)

        let locationSpread = this.getLocation()
        locationSpread.Right = locationBackplate.Left + gridLocationControls[2]
        locationSpread.Left = locationBackplate.Left + gridLocationControls[1]
        this.spreadControl.setLocation(locationSpread)

        let locationBuy = this.getLocation()
        locationBuy.Left = locationBackplate.Left + gridLocationControls[2]
        locationBuy.Right = locationBackplate.Left + 202
        this.buyControl.setLocation(locationBuy)

        locationBackplate.Right = locationBuy.Right
        this.setLocation(locationBackplate)

    }

    public setStartLeftCoordinate(x: number): void {
        this.repositionControls(x)
    }

    draw(canvas: TGdiPlusCanvas) {
        if (!this.IsVisible() || this.mode === OneClickTradingMode.OFF) {
            return
        }

        // debug - show control borders
        // canvas.strokeRect(this.getLocation(), TRoundRectType.BOTH, 5, false, new IGPPen('#ff0000'), new IGPSolidBrush('#ff0000'))

        // Fill sell control
        canvas.FillRectRounded(
            this.sellControl.getLocation(),
            this.mode === OneClickTradingMode.FULL && this.sellControl.isMouseInside()
                ? this.colors.sell.hover
                : this.colors.sell.default,
            5,
            true,
            false,
            false,
            true
        )

        // Fill spread control
        canvas.FillRect(
            this.spreadControl.getLocation(),
            this.mode === OneClickTradingMode.FULL && this.spreadControl.isMouseInside()
                ? this.colors.spread.hover
                : this.colors.spread.default
        )

        // Fill buy control
        canvas.FillRectRounded(
            this.buyControl.getLocation(),
            this.mode === OneClickTradingMode.FULL && this.buyControl.isMouseInside()
                ? this.colors.buy.hover
                : this.colors.buy.default,
            5,
            false,
            true,
            true,
            false
        )

        let sellParts = this.extractLabelParts(this.labels.sell)
        let buyParts = this.extractLabelParts(this.labels.buy)

        let offsetSellImage = this.offsetGrid[this.labels.sell.length]
        let offsetBuyImage = this.offsetGrid[this.labels.buy.length]
        let offsetSellText = this.offsetGrid[this.labels.sell.length] + offsetSellImage + ((this.images.sell) ? this.images.sell.width : 0)
        let offsetBuyText = this.offsetGrid[this.labels.buy.length] + offsetBuyImage + ((this.images.buy) ? this.images.buy.width : 0)

        canvas.graphics.Context.textAlign = 'right'

        canvas.textOut(
            this.sellControl.getLocation().Right - offsetSellText,
            this.sellControl.getLocation().Bottom - 12,
            sellParts.tail,
            this.fontTail,
            this.colors.sell.label
        )
        canvas.textOut(
            this.buyControl.getLocation().Right - offsetBuyText,
            this.buyControl.getLocation().Bottom - 12,
            buyParts.tail,
            this.fontTail,
            this.colors.buy.label
        )

        canvas.textOut(
            this.sellControl.getLocation().Right - offsetSellText - 6,
            this.sellControl.getLocation().Bottom - 8,
            sellParts.main,
            this.font,
            this.colors.sell.label
        )
        canvas.textOut(
            this.buyControl.getLocation().Right - offsetBuyText - 6,
            this.buyControl.getLocation().Bottom - 8,
            buyParts.main,
            this.font,
            this.colors.buy.label
        )

        canvas.graphics.Context.textAlign = 'center'
        canvas.textOut(
            this.spreadControl.getLocation().Left + 17,
            this.spreadControl.getLocation().Bottom - 8,
            this.labels.spread,
            this.font,
            this.colors.spread.label
        )

        canvas.graphics.Context.textAlign = 'start'

        if (this.images.sell) {
            const imageX = this.sellControl.getLocation().Right - this.images.sell.width - offsetSellImage
            const centerY = this.sellControl.getLocation().Top + this.sellControl.getLocation().Height / 2
            const imageY = centerY - this.images.sell.height / 2
            canvas.graphics.Context.drawImage(
                this.images.sell,
                imageX,
                imageY,
                this.images.sell.width,
                this.images.sell.height
            )
        }

        if (this.images.buy) {
            const imageX = this.buyControl.getLocation().Right - this.images.buy.width - offsetSellImage
            const centerY = this.buyControl.getLocation().Top + this.buyControl.getLocation().Height / 2
            const imageY = centerY - this.images.buy.height / 2
            canvas.graphics.Context.drawImage(
                this.images.buy,
                imageX,
                imageY,
                this.images.buy.width,
                this.images.buy.height
            )
        }
    }

    public updateLabels(chartData: TSymbolData) {
        if (chartData) {
            this.labels.sell = chartData.bid.toFixed(chartData.symbolInfo.decimals)
            this.labels.buy = chartData.ask.toFixed(chartData.symbolInfo.decimals)
            this.labels.spread = chartData.Spread().toString()
        }
    }

    public setMode(mode: OneClickTradingMode): void {
        this.mode = mode
    }

    public getMode(): OneClickTradingMode {
        return this.mode
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        if (this.mode === OneClickTradingMode.READONLY || this.mode === OneClickTradingMode.OFF) {
            return null
        }

        let result: ChartControl | null = null

        result = this.sellControl.onMouseDown(event, sender)
        if (result) {
            this.sellControl.notify(chartControlEvent.BUTTON_PRESSED)
            return result
        }
        result = this.spreadControl.onMouseDown(event, sender)
        if (result) {
            this.spreadControl.notify(chartControlEvent.BUTTON_PRESSED)
            return result
        }
        result = this.buyControl.onMouseDown(event, sender)
        if (result) {
            this.buyControl.notify(chartControlEvent.BUTTON_PRESSED)
            return result
        }
        return result
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        const { data } = oneClickTradingStore
        if (this.mode === OneClickTradingMode.READONLY || this.mode === OneClickTradingMode.OFF) {
            return null
        }

        let result: ChartControl | null = null

        const { x: relativeX, y: relativeY } = sender.getBoundingClientRect()
        const sellLocation = this.sellControl.getLocation()
        const buyLocation = this.buyControl.getLocation()
        const inputLength = 115
        const buttonsLocation = new TRect(sellLocation.Left, sellLocation.Top, buyLocation.Right, sellLocation.Bottom)
        const buttonsAndInput = new TRect(
            sellLocation.Left,
            sellLocation.Top,
            buyLocation.Right + inputLength,
            sellLocation.Bottom
        )
        if (buttonsLocation.PtInRect_numbers(event.clientX, event.clientY)) {
            addContextMenu('oneClickTrading', {
                anchorX: relativeX + buyLocation.Right + 8,
                anchorY: relativeY + buyLocation.Top + 24
            })
            this.isInputVisible = true
        } else if (
            this.isInputVisible &&
            !data.focused &&
            !buttonsAndInput.PtInRect_numbers(event.clientX, event.clientY)
        ) {
            removeContextMenu('oneClickTrading')
            this.isInputVisible = false
        }

        result = this.sellControl.onMouseMove(event, sender)
        if (result) {
            return result
        }
        result = this.spreadControl.onMouseMove(event, sender)
        if (result) {
            return result
        }
        result = this.buyControl.onMouseMove(event, sender)
        if (result) {
            return result
        }
        return result
    }

    onMouseLeave(event: MouseEvent): ChartControl | null {
        this.sellControl.onMouseLeave(event)
        this.spreadControl.onMouseLeave(event)
        this.buyControl.onMouseLeave(event)
        return null
    }

    private extractLabelParts(label: string) {
        if (label.length > 1)
            if (label.length === 0) {
                return { main: '', tail: '' }
            }
        if (label.length === 1) {
            return { main: label, tail: '' }
        }
        const tail = label.slice(-1)
        const main = label.slice(0, -1)
        return { main, tail }
    }
}
