import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { ChartControlId, ChartControlParams, LocationParams } from '@fto/chart_components/ChartControl'
import { ButtonSimple } from '@fto/lib/charting/auxiliary_classes_charting/Layers/ButtonSimple'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { ServerSymbolInfo } from '@fto/lib/globals/GlobalServerSymbolInfo'
import { t } from 'i18next'
import { EBlockingLayerState } from './ChartLayersEnums'
import { CanvasLayer, LayerType } from '@fto/lib/charting/auxiliary_classes_charting/Layers/Layers'

export class LayerBlockingChart extends CanvasLayer {
    private animationStart = 0
    private loaderFrameId: number | null = null
    private readyToDraw = false
    private loaderTimer = 30 //sec
    private availableSymbols: ServerSymbolInfo[] = []
    private settings = {
        radius: 20,
        colors: {
            loader: '#2F80ED',
            background: '#ffffff',
            grid: '#F1F2F5',
            ellipse: '#F2F4F7',
            border: '#D0D5DD'
        },
        lineWidth: 4
    }
    private state: EBlockingLayerState = EBlockingLayerState.off
    private buttonTimeoutId: number | null = null
    private isPaintedCanvas = false
    private symbolName = ''
    private textTitle: { off: string; loader: string; try: string; goto: string; alt: string } = {
        off: '',
        loader: '',
        try: t('layers.blockingLayer.couldnt-load-chart'),
        goto: t('layers.blockingLayer.no-historical-data'),
        alt: ''
    }
    private textSubTitle: { off: string[]; loader: string[]; try: string[]; goto: string[]; alt: string[] } = {
        off: [],
        loader: [],
        try: [t('layers.blockingLayer.Please-try-again-or-change-a-symbol')],
        goto: [t('layers.blockingLayer.no-data-is-available-for')],
        alt: []
    }
    private textGotoNearestDate = 'The nearest date available for the selected symbol is: '
    private textGotoCurrentDate = t('layers.blockingLayer.current-testing-date')
    private textGotoChangeSymbol = 'Please change the symbol to one of the following: '

    private firstDate = ''
    private currentDate = ''

    private isTextAlternative = false
    // private btnGoToDate: ButtonSimple
    private btnTryAgain: ButtonSimple
    private btnChangeSymbol: ButtonSimple
    private btnChangeSymbol2: ButtonSimple

    constructor(canvas: HTMLCanvasElement, chartWindow: TChartWindow, size: LayerType, name: string) {
        super(canvas, chartWindow, size, name)

        const btnCCP1 = new ChartControlParams(this, new LocationParams(200, 200, 84, 24), ChartControlId.TRY_AGAIN)
        this.btnTryAgain = new ButtonSimple(btnCCP1)
        this.btnTryAgain.setTextOffset(12, 6)
        this.btnTryAgain.setText(t('layers.blockingLayer.try-again'))
        this.btnTryAgain.Hide()
        this.addControl(this.btnTryAgain)

        const btnCCP2 = new ChartControlParams(
            this,
            new LocationParams(300, 200, 122, 24),
            ChartControlId.BUTTON_CHANGE_SYMBOL
        )
        this.btnChangeSymbol = new ButtonSimple(btnCCP2)
        this.btnChangeSymbol.setTextOffset(12, 6)
        this.btnChangeSymbol.setText(t('layers.blockingLayer.change-symbol'))
        this.btnChangeSymbol.Hide()
        this.addControl(this.btnChangeSymbol)

        const btnCCP3 = new ChartControlParams(this, new LocationParams(5, 5, 70, 24), ChartControlId.BUTTON_CHANGE_SYMBOL)
        this.btnChangeSymbol2 = new ButtonSimple(btnCCP3)
        // this.btnChangeSymbol2.setSizeMode('auto')
        this.btnChangeSymbol2.setTextOffset(6, 6)
        this.btnChangeSymbol2.Hide()
        this.addControl(this.btnChangeSymbol2)

        this.tGdiPlusCanvas = new TGdiPlusCanvas(this.canvas)

        this.initializeCanvas()
        this.readyToDraw = true
    }

    private initializeCanvas(): void {
        const ctx = this.context

        // Adjust for device pixel ratio
        const dpr = window.devicePixelRatio || 1
        ctx.canvas.width = ctx.canvas.width * dpr
        ctx.canvas.height = ctx.canvas.height * dpr
        ctx.scale(dpr, dpr)
    }

    public draw(): void {
        if (!this.readyToDraw || !this.isActive) {
            return
        }

        if (this.state === EBlockingLayerState.off) {
            if (this.isPaintedCanvas) {
                this.clearCanvas()
                return
            } else {
                return
            }
        }

        if (this.context && this.canvas) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.drawBackground()
        }
    }

    public resetView(): void {
        this.btnTryAgain.Hide()
        // this.btnGoToDate.Hide()
        this.btnChangeSymbol.Hide()
        this.isActive = true
        this.canvas.style.pointerEvents = 'auto'
    }

    public stopLayer(): void {
        this.isActive = false
        if (this.state === EBlockingLayerState.off) {
            return
        }
        this.state = EBlockingLayerState.off
        this.stopLoaderAnimation()
        this.clearCanvas()
        if (this.canvas) {
            this.canvas.style.pointerEvents = 'none'
        }
    }

    private startLoaderAnimation(): void {
        if (this.state === EBlockingLayerState.loader) {
            return
        }
        if (!this.tGdiPlusCanvas) {
            this.tGdiPlusCanvas = new TGdiPlusCanvas(this.canvas)
        }
        this.state = EBlockingLayerState.loader
        this.context.fillStyle = this.settings.colors.background
        this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height)

        this.buttonTimeoutId = window.setTimeout(() => {
            this.switchStateTo(EBlockingLayerState.try)
        }, this.loaderTimer * 1000)

        const animate = () => {
            this.draw()
            this.isPaintedCanvas = true

            const elapsedTime = performance.now() - this.animationStart
            const rotation = (elapsedTime / 1000) * 2 * Math.PI

            this.context.save()
            this.context.beginPath()

            this.context.arc(
                30 + this.settings.radius / 2,
                this.context.canvas.height / window.devicePixelRatio - 52 - this.settings.radius / 2,
                this.settings.radius,
                rotation,
                rotation + Math.PI * 0.5
            )
            this.context.strokeStyle = this.settings.colors.loader
            this.context.lineWidth = this.settings.lineWidth
            this.context.stroke()

            this.context.restore()
            this.loaderFrameId = requestAnimationFrame(animate)
        }

        this.animationStart = performance.now()
        this.loaderFrameId = requestAnimationFrame(animate)
    }

    private stopLoaderAnimation(): void {
        if (this.loaderFrameId !== null) {
            cancelAnimationFrame(this.loaderFrameId)
            this.loaderFrameId = null
        }
        if (this.buttonTimeoutId !== null) {
            clearTimeout(this.buttonTimeoutId)
            this.buttonTimeoutId = null
        }
    }

    clearCanvas(): void {
        if (this.context) {
            this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height)
            this.isPaintedCanvas = false
        }
    }

    private drawBackground(): void {
        const ctx = this.context

        this.isPaintedCanvas = true

        const dpr = window.devicePixelRatio || 1
        const width = ctx.canvas.width / dpr
        const height = ctx.canvas.height / dpr

        ctx.save()

        if (this.settings.colors.background) {
            ctx.fillStyle = this.settings.colors.background
            ctx.fillRect(0, 0, width, height)
        }

        const rightOffset = 60
        ctx.strokeStyle = this.settings.colors.border
        ctx.lineWidth = 1
        ctx.beginPath()
        ctx.moveTo(width - rightOffset, 0)
        ctx.lineTo(width - rightOffset, height)
        ctx.stroke()

        const bottomOffset = 24
        ctx.beginPath()
        ctx.moveTo(0, height - bottomOffset)
        ctx.lineTo(width - rightOffset, height - bottomOffset)
        ctx.stroke()

        this.drawGrid(width - rightOffset, height - bottomOffset)
        this.drawRoundedRectangles(width, height, rightOffset, bottomOffset)

        //TODO: make this a clickable button
        // shadow symbol button
        // ctx.fillStyle = '#ffffff'
        // ctx.textAlign = 'left'
        // ctx.textBaseline = 'alphabetic'
        // ctx.strokeStyle = '#D0D5DD'
        // ctx.lineWidth = 0.5
        // this.drawRoundedRect(5, 5, 70, 24, 4)
        //
        //
        // ctx.font = '14px Arial'
        // ctx.fillStyle = '#777777'
        // ctx.fillText(this.symbolName, 10, 22)

        this.btnChangeSymbol2.Show()
        this.btnChangeSymbol2.setText(this.symbolName)
        this.btnChangeSymbol2.draw(this.tGdiPlusCanvas)
        ctx.font = '16px Roboto Flex'
        if (this.state === EBlockingLayerState.loader) {
            const text = t('layers.blockingLayer.preparingChart')
            const x = 90
            const y = ctx.canvas.height - 55
            ctx.fillStyle = '#101828'
            ctx.font = '14px Roboto Flex'
            ctx.fillText(text, x, y)
        }

        if (this.state === EBlockingLayerState.try || this.state === EBlockingLayerState.goto) {
            this.drawErrorPlate()
        }

        ctx.restore()
    }

    private drawGrid(width: number, height: number): void {
        const ctx = this.context
        ctx.strokeStyle = this.settings.colors.grid
        ctx.lineWidth = 0.5

        const stepX = 46
        const stepY = 40

        for (let x = 0; x <= width; x += stepX) {
            ctx.beginPath()
            ctx.moveTo(x, 0)
            ctx.lineTo(x, height)
            ctx.stroke()
        }

        for (let y = 0; y <= height; y += stepY) {
            ctx.beginPath()
            ctx.moveTo(0, y)
            ctx.lineTo(width, y)
            ctx.stroke()
        }
    }

    private drawErrorPlate(): void {
        let title: string
        let subTitle: string[]
        if (this.isTextAlternative) {
            title = this.textTitle.alt
            subTitle = this.textSubTitle.alt
        } else {
            title = this.textTitle[this.state]
            subTitle = this.textSubTitle[this.state]
        }
        const ctx = this.context

        const dpr = window.devicePixelRatio || 1

        const availableWidth = ctx.canvas.width / dpr - 61
        const availableHeight = ctx.canvas.height / dpr - 25
        let rectWidth = 360
        let rectHeight = 152

        if (this.state === EBlockingLayerState.goto) {
            rectWidth = 500
            rectHeight = 240
        }

        ctx.save()
        const scale = availableWidth < rectWidth ? availableWidth / rectWidth : 1
        ctx.scale(scale, scale)

        const rectX = (availableWidth / scale - rectWidth) / 2
        const rectY = (availableHeight / scale - rectHeight) / 2

        ctx.fillStyle = '#ffffff'
        ctx.fillRect(rectX, rectY, rectWidth, rectHeight)

        if (GlobalImageManager.Instance.warningTriangleYellow) {
            const iconSize = 24
            const iconX = rectX + (rectWidth - iconSize) / 2
            const iconY = rectY + 16
            ctx.drawImage(GlobalImageManager.Instance.warningTriangleYellow, iconX, iconY, iconSize, iconSize)
        }

        ctx.fillStyle = '#101828'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'alphabetic'

        ctx.font = 'bold 14px Roboto Flex'
        ctx.fillText(title, rectX + rectWidth / 2, rectY + 70)

        ctx.font = '14px Roboto Flex'
        let textY = rectY + 92
        for (const line of subTitle) {
            ctx.fillText(line, rectX + rectWidth / 2, textY)
            textY += 24
        }

        if (this.state === EBlockingLayerState.goto) {
            textY += 12
            ctx.fillText(this.textGotoNearestDate + this.firstDate?.toString(), rectX + rectWidth / 2, textY)
            textY += 24
            ctx.fillText(this.textGotoCurrentDate + ' ' + this.currentDate?.toString(), rectX + rectWidth / 2, textY)
            textY += 24
            ctx.fillText(
                this.textGotoChangeSymbol + this.availableSymbols.map((symbol) => symbol.Symbol).join(', '),
                rectX + rectWidth / 2,
                textY
            )
            textY += 24
        }

        ctx.restore()

        const buttonY = textY * scale
        let buttonChangeX = availableWidth / 2 - 12
        let btnChangeSymbolLoc = this.btnChangeSymbol.getLocation()
        if (btnChangeSymbolLoc) {
            btnChangeSymbolLoc.Left = buttonChangeX
            btnChangeSymbolLoc.Top = buttonY
            btnChangeSymbolLoc.Right = buttonChangeX + 122
            btnChangeSymbolLoc.Bottom = buttonY + 24
        }

        switch (this.state) {
            case EBlockingLayerState.goto: {
                buttonChangeX = availableWidth / 2 - 74
                btnChangeSymbolLoc = this.btnChangeSymbol.getLocation()
                if (btnChangeSymbolLoc) {
                    btnChangeSymbolLoc.Left = buttonChangeX
                    btnChangeSymbolLoc.Top = buttonY
                    btnChangeSymbolLoc.Right = buttonChangeX + 149
                    btnChangeSymbolLoc.Bottom = buttonY + 24
                }
                this.btnChangeSymbol.setTextOffset(24, 6)
                break
            }

            case EBlockingLayerState.try: {
                this.btnChangeSymbol.setTextOffset(12, 6)
                const buttonTryX = availableWidth / 2 - 105
                const btnTryAgainLoc = this.btnTryAgain.getLocation()
                if (btnTryAgainLoc) {
                    btnTryAgainLoc.Left = buttonTryX
                    btnTryAgainLoc.Top = buttonY
                    btnTryAgainLoc.Right = buttonTryX + 84
                    btnTryAgainLoc.Bottom = buttonY + 24
                }
                this.btnTryAgain.setLocation(btnTryAgainLoc)
                this.btnTryAgain.Show()
                this.btnTryAgain.draw(this.tGdiPlusCanvas)
                break
            }
        }

        this.btnChangeSymbol.setLocation(btnChangeSymbolLoc)
        this.btnChangeSymbol.Show()
        this.btnChangeSymbol.draw(this.tGdiPlusCanvas)
    }

    private drawRoundedRectangles(width: number, height: number, rightOffset: number, bottomOffset: number): void {
        const ctx = this.tGdiPlusCanvas.graphics.Context

        const rectWidthRight = 55
        const rectHeightRight = 15
        const rectWidthBottom = 40
        const rectHeightBottom = 15
        const radius = 8

        const verticalSpacing = 16
        const horizontalSpacing = 24

        ctx.fillStyle = this.settings.colors.ellipse
        // Draw rectangles to fill the right strip with vertical spacing
        for (let y = 8; y < height - rectHeightRight; y += rectHeightRight + verticalSpacing) {
            this.drawRoundedRect(
                width - rightOffset + (rightOffset - rectWidthRight) / 2,
                y,
                rectWidthRight,
                rectHeightRight,
                radius
            )
        }

        // Draw rectangles to fill the bottom strip with horizontal spacing
        for (let x = 8; x < width - rightOffset - rectWidthBottom; x += rectWidthBottom + horizontalSpacing) {
            this.drawRoundedRect(
                x,
                height - bottomOffset + (bottomOffset - rectHeightBottom) / 2,
                rectWidthBottom,
                rectHeightBottom,
                radius
            )
        }
    }

    private drawRoundedRect(x: number, y: number, width: number, height: number, radius: number): void {
        const ctx = this.context
        ctx.beginPath()
        ctx.moveTo(x + radius, y)
        ctx.lineTo(x + width - radius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
        ctx.lineTo(x + width, y + height - radius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
        ctx.lineTo(x + radius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
        ctx.lineTo(x, y + radius)
        ctx.quadraticCurveTo(x, y, x + radius, y)
        ctx.closePath()
        ctx.fill()
        if (ctx.lineWidth > 0) {
            ctx.stroke()
        }
    }

    setSymbolName(symbolName: string): void {
        this.symbolName = symbolName
        if (this.isActive) {
            this.draw()
        }
    }

    public setAlternativeText(isTextAlternative: boolean, textTitle: string, textSubTitle: string[]): void {
        this.isTextAlternative = isTextAlternative
        this.textTitle.alt = textTitle
        this.textSubTitle.alt = textSubTitle
    }

    public setLoaderTimer(timer: number): void {
        this.loaderTimer = timer
    }

    setColors(colors: { [key: string]: string }): void {
        const validKeys = new Set(['loader', 'background', 'grid', 'ellipse', 'border'])
        for (const key in colors) {
            if (Object.prototype.hasOwnProperty.call(colors, key) && validKeys.has(key)) {
                ;(this.settings.colors as any)[key] = colors[key]
            }
        }
    }

    public setAvailableSymbolsAndDate(symbols: ServerSymbolInfo[], firstDate: string, currentDate: string): void {
        this.availableSymbols = symbols

        this.firstDate = firstDate
        this.currentDate = currentDate
    }

    public setCurrentDate(currentDate: string): void {
        this.currentDate = currentDate
        this.draw()
    }

    public getState(): EBlockingLayerState {
        return this.state
    }

    public switchStateTo(state = EBlockingLayerState.try): void {
        if (state === EBlockingLayerState.off) {
            this.stopLayer()
            return
        }

        if (state !== EBlockingLayerState.loader) {
            this.stopLoaderAnimation()
        }
        this.resetView()

        if (state === EBlockingLayerState.loader) {
            this.startLoaderAnimation()
        }

        this.state = state
        this.draw()
    }
}
