import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '@fto/icons'
import { Checkbox, Flex, TriggerOverlay, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    cells: { title: string; flex: number; active: boolean }[]
    setCells: Dispatch<
        SetStateAction<
            {
                title: string
                flex: number
                active: boolean
            }[]
        >
    >
}

const CellsDropdown: FC<Props> = ({ cells, setCells }) => {
    const { t } = useTranslation()

    const cellsWithoutSymbol = useMemo(() => cells.slice(1), [cells])

    const handleColumnToggle = useCallback(
        (id: string) => {
            setCells((prev) => {
                return prev.map((item: { title: string; flex: number; active: boolean }) =>
                    item.title === id ? { ...item, active: !item.active } : item
                )
            })
        },
        [setCells]
    )

    return (
        <TriggerOverlay
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            optionsRenderer={
                <Flex direction='column' gap={8}>
                    <Flex alignItems='center'>
                        <Typography color='gray-600' type='tiny-regular' className={styles.showColumnContainer}>
                            {t('terminal.cellsSelectionHeader')}
                        </Typography>
                    </Flex>
                    {cellsWithoutSymbol.map((cell) => (
                        <Flex
                            gap={8}
                            alignItems='center'
                            key={cell.title}
                            className={styles.showColumnRow}
                            onClick={() => handleColumnToggle(cell.title)}
                        >
                            <Checkbox checked={cell.active} onChange={() => handleColumnToggle(cell.title)} />
                            <Typography type='interface-medium' color='gray-1000'>
                                {t(`watchlist.fullFields.${cell.title}`)}
                            </Typography>
                        </Flex>
                    ))}
                </Flex>
            }
        >
            <IconButton size={24} name='ellipsis-vertical' color={'var(--color-gray-900)'} />
        </TriggerOverlay>
    )
}

export default CellsDropdown
