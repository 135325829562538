import { ObservableTemplateItem, ObserverTemplate } from './ObserverTemplate'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { CanvasLayer } from '@fto/lib/charting/auxiliary_classes_charting/Layers/Layers'
import { ChartEvent } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import { ChartControllerEvent } from '@fto/lib/globals/GlobalChartsController'

export enum chartControlEvent {
    SYMBOL_CHANGE_MODAL,
    TIMEFRAME_CHANGE_DROPDOWN,
    INDICATOR_SHOW_INDICATOR_SETTINGS,
    INDICATOR_VISIBILITY_CHANGE,
    INDICATOR_DELETE,
    NEED_REDRAW,
    INDICATOR_SELECTED_BY_CONF_CONTROL,
    BUTTON_PRESSED,
    CONTROL_SELECTED,
    CONTROL_DESELECTED,
    NEED_REDRAW_OSC_INDICATOR_CONTROL
}

export enum ChartControlId {
    UNKNOWN,
    INDICATOR_SETTINGS,
    INDICATOR_VISIBILITY,
    INDICATOR_DELETE,
    BAR_INFO,
    INDICATOR_COLLAPSE_CONTROL,
    GROUP,
    INDICATOR_MOVE_TO_LAST_BAR,
    BUTTON_TRY_AGAIN,
    BUTTON_CHANGE_SYMBOL,
    TRY_AGAIN,
    ONE_CLICK_SELL,
    ONE_CLICK_BUY,
    ONE_CLICK_SPREAD,
}

export class ChartControl {
    protected observableItem: ObservableTemplateItem<
        chartControlEvent,
        ChartControl,
        ObserverTemplate<chartControlEvent, ChartControl>
    >

    private _chartControlParams!: ChartControlParams
    private _isMouseInside = false
    private _isVisible = true
    private _isEnabled = true
    private _parentControl: ChartControl | null = null
    private _isCaptured = false
    public tooltip: string | null = null

    showTooltip(canvas: TGdiPlusCanvas, coords: TRect | null = null): void {
        if (!this.tooltip) {
            return
        }

        let location = this.getLocation()
        if (coords) {
            location = coords
        }

        const context = canvas.graphics.Context
        const fontSize = 12

        context.font = `semibold ${fontSize}px 'Roboto Flex'`

        const metrics = context.measureText(this.tooltip)
        const textWidth = metrics.width
        const textHeight = fontSize * 1.3 // 1.2 is a common ratio for line-height

        const boxWidth = textWidth + 20
        const boxHeight = textHeight + 10
        const centerX = location.Left + location.Width / 2
        const boxX = centerX - boxWidth / 2
        const boxY = location.Top - boxHeight - 10
        const tooltipBackgroundColor = '#101828'

        context.fillStyle = tooltipBackgroundColor
        context.beginPath()
        context.moveTo(boxX + 5, boxY)
        context.arcTo(boxX + boxWidth, boxY, boxX + boxWidth, boxY + boxHeight, 5)
        context.arcTo(boxX + boxWidth, boxY + boxHeight, boxX, boxY + boxHeight, 5)
        context.arcTo(boxX, boxY + boxHeight, boxX, boxY, 5)
        context.arcTo(boxX, boxY, boxX + boxWidth, boxY, 5)
        context.closePath()
        context.fill()

        context.fillStyle = 'white'
        context.textAlign = 'left'
        context.fillText(this.tooltip, boxX + 10, boxY + fontSize + (boxHeight - textHeight) / 2)

        const arrowWidth = 10
        const arrowHeight = 5
        const arrowX = centerX - arrowWidth / 2
        const arrowY = boxY + boxHeight

        context.fillStyle = tooltipBackgroundColor
        context.beginPath()
        context.moveTo(arrowX, arrowY)
        context.lineTo(arrowX + arrowWidth / 2, arrowY + arrowHeight)
        context.lineTo(arrowX + arrowWidth, arrowY)
        context.closePath()
        context.fill()
    }

    public setParentControl(control: ChartControl): void {
        this._parentControl = control
    }

    public getParentControl(): ChartControl | null {
        return this._parentControl
    }

    public isMouseInside(): boolean {
        return this._isMouseInside
    }

    protected onMouseEnterControl(): void {
        this._isMouseInside = true
    }

    protected onMouseLeaveControl(): void {
        this._isMouseInside = false
    }

    setIsMouseInside(value: boolean): void {
        this._isMouseInside = value
    }

    public Show() {
        this._isVisible = true
    }

    public Hide(): void {
        this._isVisible = false
    }

    public Enable(): void {
        this._isEnabled = true
    }

    public Disable(): void {
        this._isEnabled = false
    }

    IsEnabled(): boolean {
        return this._isEnabled
    }

    IsVisible(): boolean {
        return this._isVisible
    }

    Capture(): void {
        this._isCaptured = true
    }

    setCapture(value: boolean): void {
        this._isCaptured = value
    }

    ReleaseCapture(): void {
        this._isCaptured = false
    }

    IsCaptured(): boolean {
        return this._isCaptured
    }

    IsDraggable(): boolean {
        return this._chartControlParams.isDraggable
    }

    capturedMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        return this.onMouseMove(event, sender)
    }

    constructor(chartControlParams: ChartControlParams) {
        this.observableItem = new ObservableTemplateItem<
            chartControlEvent,
            ChartControl,
            ObserverTemplate<chartControlEvent, ChartControl>
        >()
        this._chartControlParams = chartControlParams
    }

    public getOwnerLayer(): CanvasLayer | null {
        return this._chartControlParams.ownerLayer
    }

    public attachObserver(observer: ObserverTemplate<chartControlEvent, ChartControl>): void {
        this.observableItem.attachObserver(observer)
    }

    public detachObserver(observer: ObserverTemplate<chartControlEvent, ChartControl>): void {
        this.observableItem.detachObserver(observer)
    }

    public detachAllObservers(): void {
        this.observableItem.detachAllObservers()
    }

    public notify(event: chartControlEvent): void {
        this.observableItem.notify(event, this)
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY
        if (this.IsEnabled() && this.IsVisible() && this.isPointInside(x, y)) {
            this.onMouseEnterControl()
            return this
        }
        return null
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY
        if (this.IsEnabled() && this.IsVisible() && this.isPointInside(x, y)) {
            this.onMouseEnterControl()
            return this
        } else {
            this.onMouseLeaveControl()
        }
        return null
    }

    onMouseLeave(event: MouseEvent): ChartControl | null {
        this.onMouseLeaveControl()
        return null
    }

    draw(canvas: TGdiPlusCanvas): void {}

    getLocation(): TRect {
        return this._chartControlParams.getLocation()
    }

    get controlId(): ChartControlId {
        return this._chartControlParams.controlId
    }

    set controlId(id: ChartControlId) {
        this._chartControlParams.controlId = id
    }

    protected isPointInside(x: number, y: number): boolean {
        const location = this.getLocation()
        return x >= location.Left && x <= location.Right && y >= location.Top && y <= location.Bottom
    }

    public isPointInsidePublic(x: number, y: number): boolean {
        return this.isPointInside(x, y)
    }

    setLocation(location: TRect): void {
        this._chartControlParams.setLocation(location)
    }

    getHeight(): number {
        return this._chartControlParams.getLocation().Bottom - this._chartControlParams.getLocation().Top
    }

    getWidth(): number {
        return this._chartControlParams.getLocation().Right - this._chartControlParams.getLocation().Left
    }

    onBrowserWndSizing(): void {}

    onMouseUp(event: MouseEvent, sender: TMainChart) {}
    onDblClick(event: MouseEvent, sender: TMainChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY

        if (this.IsEnabled() && this.IsVisible() && this.isPointInside(x, y)) {
            return this
        }

        return null
    }
}

export class LocationParams {
    private x = 0
    private y = 0
    private width = 0
    private height = 0

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x
        this.y = y
        this.width = width
        this.height = height
    }

    getLocation(): TRect {
        return new TRect(this.x, this.y, this.x + this.width, this.y + this.height)
    }

    setLocation(location: TRect): void {
        this.x = location.Left
        this.y = location.Top
        this.width = location.Right - location.Left
        this.height = location.Bottom - location.Top
    }

    clone(): LocationParams {
        return new LocationParams(this.x, this.y, this.width, this.height)
    }
}

export class ChartControlParams {
    private _ownerLayer: CanvasLayer | null = null
    private locationParams: LocationParams = new LocationParams(0, 0, 0, 0)
    private id: ChartControlId = ChartControlId.UNKNOWN
    private _isDraggable: boolean

    constructor(
        ownerLayer: CanvasLayer | null,
        locationParams: LocationParams,
        controlId: ChartControlId = ChartControlId.UNKNOWN,
        isDraggable = false
    ) {
        this._ownerLayer = ownerLayer
        this.locationParams = locationParams
        this.id = controlId
        this._isDraggable = isDraggable
    }

    get controlId(): ChartControlId {
        return this.id
    }

    set controlId(id: ChartControlId) {
        this.id = id
    }

    get ownerLayer(): CanvasLayer | null {
        return this._ownerLayer
    }

    get isDraggable(): boolean {
        return this._isDraggable
    }

    getLocation(): TRect {
        return this.locationParams.getLocation()
    }

    setLocation(location: TRect): void {
        this.locationParams.setLocation(location)
    }

    clone(): ChartControlParams {
        return new ChartControlParams(this.ownerLayer, this.locationParams.clone(), this.controlId)
    }
}
