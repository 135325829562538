import { t } from 'i18next'
import { TChart } from '../../charting/chart_classes/BasicChart'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { DelphiColors, TColor, TPenStyle } from '../../delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '../../drawing_interface/ColorHelperFunctions'
import { Level, TLevelData } from '../../drawing_interface/GraphicObjects'
import { TBufferStyle, TLineStyle, TMkFontStyle } from '../../drawing_interface/vclCanvas'
import {
    TCommonIndexBuffer,
    TIndexBufferProgramSide,
    TIndexBuffersList
} from '../../extension_modules/indicators/IndexBuffers'
import { TIndicator, TOutputWindow } from '../../extension_modules/indicators/IndicatorUnit'
import { TChartType } from '../../ft_types/common/BasicClasses/BasicEnums'
import { Common } from '../../ft_types/common/Common'
import ISymbolData from '../../ft_types/data/ISymbolData'
import IFMBarsArray from '../../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import DataNotDownloadedYetError from '../../ft_types/data/data_errors/DataUnavailableError'
import GlobalOptions from '../../globals/GlobalOptions'
import GlobalSymbolList from '../../globals/GlobalSymbolList'
import { TVisibleIndexBuffer } from './VisibleIndexBuffer'
import ProcRec, { IIndexBuffer, TIndexBuffer } from './api/IIndicatorApi'
import { UserIndicator } from './api/UserIndicator'
import { IndicatorJSON } from '@fto/lib/ProjectAdapter/Types'
import IndicatorOptionsStore from '@fto/lib/charting/tool_storages/indicators'
import {
    MacdMode,
    TChartInfo,
    TMAType,
    TOptValue,
    TOptionType,
    TOptValue_Session,
    TOptValue_SessionsArray,
    TOptValue_str,
    TPriceType,
    TValueType,
    TObjectType,
    TDrawStyle, TOptValue_bool
} from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'
import { TOffsStringList } from '@fto/lib/ft_types/common/OffsStringList'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { GlobalMovingAverageCache, WmaInfo } from '@fto/lib/globals/GlobalMovingAverageCache'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { PaintToolManager, TPaintToolClass } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { objectTypeToName } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { ObjProp } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { IndicatorConfigurationControl } from '@fto/chart_components/IndicatorConfigurationControl'
import { GlobalHighestLowestPriceCache } from '@fto/lib/globals/HighestLowestCache/GlobalHighestLowestPriceCache'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import { OscIndicatorConfigurationControl } from '@fto/chart_components/OscIndicatorConfigurationControl'

export enum TCalcType {
    ct_FT3,
    ct_MT4
}

export type TInitProc = () => void
export type TDoneProc = () => void

// export type TShowPropsDialogProc = () => TModalResult;
export type TParamsChangeProc = () => void
export type TStartProc = () => void
export type TOnChartEvent = (id: number, lparam: number, dparam: number, sparam: string) => void
export type TRecalculateBarProc = (index: number) => void
export type TOnPaintProc = (CanvasContext: CanvasRenderingContext2D) => void

//same as TDLLIndicator in Delphi
export class TRuntimeIndicator extends TIndicator {
    private _userIndicator: UserIndicator
    private fBars: IFMBarsArray
    private fSymbol!: ISymbolData
    private fChartWindow!: TChart
    private fLevelsStr: TOptValue_str // level in sting (registered in options)
    private fCalcType: TCalcType
    // private fBarsCounted: number;
    private fBuffers!: TIndexBuffersList
    private fInitProc!: TInitProc
    private fDoneProc!: TDoneProc

    // private fShowPropsDialogProc!: TShowPropsDialogProc;
    private fOnParamsChangeProc!: TParamsChangeProc
    private fStartProc!: TStartProc
    private fOnChartEvent!: TOnChartEvent

    private _isNeedRecalculateAlways = false
    private _backOffsetForCalculation = 0

    private fOnPaintProc!: TOnPaintProc
    private fRecalculateBarProc!: TRecalculateBarProc
    private indicatorConfigurationControl: IndicatorConfigurationControl | null = null
    private oscIndicatorConfigurationControl: OscIndicatorConfigurationControl | null = null
    private isVisible: boolean = true
    public indicatorKey: string = ''
    private displayParamsOnIndicatorConfControl: TOptValue_bool = new TOptValue_bool(true)
    private displayValuesOnIndicatorConfControl: TOptValue_bool = new TOptValue_bool(true)

    Init(): void {
        this._userIndicator.Init()
        this.RegOption(t('indicatorModal.general.showParameters'), TOptionType.ot_Boolean, this.displayParamsOnIndicatorConfControl, false)
        this.RegOption(t('indicatorModal.general.showValues'), TOptionType.ot_Boolean, this.displayValuesOnIndicatorConfControl, false)
    }

    public getIndicator(): UserIndicator {
        return this._userIndicator
    }

    Calculate(index: number): void {
        if (this.isVisible) {
            this._userIndicator.Calculate(index)
            if (this.indicatorConfigurationControl) {
                if (this.indicatorConfigurationControl.isMouseInside()) {
                    this.indicatorConfigurationControl.adjustControlWidth(true)
                } else {
                    this.indicatorConfigurationControl.adjustControlWidth(false)
                }
            }
            if (this.oscIndicatorConfigurationControl) {
                if (this.oscIndicatorConfigurationControl.isMouseInside()) {
                    this.oscIndicatorConfigurationControl.adjustControlWidth(true)
                } else {
                    this.oscIndicatorConfigurationControl.adjustControlWidth(false)
                }
            }
        }
    }

    OnMapLoaded(): void {
        this.indicatorConfigurationControl?.OnIndicatorParamsChange()
        this.oscIndicatorConfigurationControl?.OnIndicatorParamsChange()
    }

    OnParamsChange(): void {
        this._userIndicator.OnParamsChange()
        if (this.indicatorConfigurationControl) {
            this.indicatorConfigurationControl.OnIndicatorParamsChange()
            if (this.indicatorConfigurationControl.isMouseInside()) {
                this.indicatorConfigurationControl.adjustControlWidth(true)
            } else {
                this.indicatorConfigurationControl.adjustControlWidth(false)
            }
        }
        if (this.oscIndicatorConfigurationControl) {
            this.oscIndicatorConfigurationControl.OnIndicatorParamsChange()
            if (this.oscIndicatorConfigurationControl.isMouseInside()) {
                this.oscIndicatorConfigurationControl.adjustControlWidth(true)
            } else {
                this.oscIndicatorConfigurationControl.adjustControlWidth(false)
            }
        }
    }

    Done(): void {
        this._userIndicator.Done()
    }

    constructor(
        userIndicator: UserIndicator,
        aSymbol: ISymbolData,
        aTimeframe: number,
        aLibName: string,
        ChartType: TChartType
    ) {
        super(aLibName)
        this._userIndicator = userIndicator

        // Initialize member variables to their default values

        this.fBuffers = new TIndexBuffersList()

        this.fCalcType = TCalcType.ct_FT3

        this.fSymbol = aSymbol
        this.fTimeframe = aTimeframe

        let theBars
        // Set bars based on the ChartType
        if (this.fSymbol) {
            switch (ChartType) {
                case TChartType.ct_Normal: {
                    theBars = this.fSymbol.GetOrCreateBarArray(this.fTimeframe)
                    break
                }
                default: {
                    throw new StrangeError('Unknown chart type DllIndicatorUnit')
                }
            }

            if (theBars) {
                this.fBars = theBars
            } else {
                throw new StrangeError('Error initializing indicator. Bars not found')
            }
        } else {
            throw new StrangeError('Error initializing indicator. Symbol not provided')
        }

        this.fLevelsStr = new TOptValue_str('')
        this.fOptions.RegOption('Levels', TOptionType.at_Levels, this.fLevelsStr, true, Common.SelfReplaceStrProc)

        this.fInitProc = this.Init
        this.fRecalculateBarProc = this.Calculate
        this.fOnParamsChangeProc = this.OnParamsChange

        this.onLoad()
    }

    public set configurationControl(indicatorConfigurationControl: IndicatorConfigurationControl | null) {
        this.indicatorConfigurationControl = indicatorConfigurationControl
    }

    public get configurationControl(): IndicatorConfigurationControl | null {
        return this.indicatorConfigurationControl
    }

    public set oscConfigurationControl(indicatorConfigurationControl: OscIndicatorConfigurationControl) {
        this.oscIndicatorConfigurationControl = indicatorConfigurationControl
    }

    public get oscConfigurationControl(): OscIndicatorConfigurationControl | null {
        return this.oscIndicatorConfigurationControl
    }

    public DisplayParamsOnIndicatorConfigurationControl(): boolean {
        return this.displayParamsOnIndicatorConfControl.value
    }

    public DisplayValuesOnIndicatorConfigurationControl(): boolean {
        return this.displayValuesOnIndicatorConfControl.value
    }

    private onLoad() {
        const realApiImplementation: ProcRec = {
            AddLevel: (value: number, style: TPenStyle, width: number, color: TColor, opacity: number) => {
                this.AddLevel(value, style, width, color, opacity)
            },
            AddOptionValue: (optionName: string, value: string) => {
                this.AddOptionValue(optionName, value)
            },
            AddSeparator: (separatorName: string) => {
                this.AddSeparator(separatorName)
            },
            Ask: () => {
                return this.Ask
            },
            Bars: () => {
                return this.Bars
            },
            Bid: () => {
                return this.Bid
            },
            ChartToScrX: (index: number) => {
                return this.ChartToScrX(index)
            },
            ChartToScrY: (price: number) => {
                return this.ChartToScrY(price)
            },
            Close: (index: number) => {
                return this.Close(index)
            },
            CreateIndexBuffer: () => {
                const programSideBuffer = this.CreateIndexBuffer()
                return new TIndexBuffer(programSideBuffer)
            },
            // CreateIndexBufferWithArgs: (
            //     index: number,
            //     aLabel: string,
            //     DrawStyle: TDrawStyle,
            //     style: TPenStyle,
            //     width: number,
            //     color: TColor) => {
            //     return undefined
            // },
            // CreateLoopbackIndexBuffer: (maxSize: number) => {
            //     return this.CreateLoopbackIndexBuffer(maxSize)
            // },
            Digits: () => {
                return this.Digits
            },
            GetChartInfo: () => {
                return this.GetChartInfo()
            },
            GetDayOfMonthByDateTime: (time: TDateTime) => {
                return this.GetDayOfMonthByDateTime(time)
            },
            GetHighestValue: (ValueType: TValueType, StartIndex: number, count: number) => {
                return this.GetHighestValue(ValueType, StartIndex, count)
            },
            // Marked in delphi as deprecated
            // GetIndicatorBufferValue: (indicator: TOptValue, index: number) => {
            //     return 0
            // },
            // GetInterfaceVersion: () => {
            //     return { MajorValue: 0, MinorValue: 0 }
            // },
            GetLowestValue: (ValueType: TValueType, StartIndex: number, count: number) => {
                return this.GetLowestValue(ValueType, StartIndex, count)
            },
            GetMA: (
                index: number,
                shift: number,
                period: number,
                maType: TMAType,
                applyTo: TPriceType,
                prev?: number
            ) => {
                return this.GetMA(index, shift, period, maType, applyTo, prev ?? 0)
            },
            GetMonthByDateTime: (time: TDateTime) => {
                return this.GetMonthByDateTime(time)
            },
            GetPrice: (index: number, priceType: TPriceType) => {
                return this.GetPrice(index, priceType)
            },
            // GetSymbolInfo: (symbol: string) => {
            //     return { digits: this.Digits, point: this.Point }
            // },
            GetWeekByDateTime: (time: TDateTime) => {
                return this.GetWeekByDateTime(time)
            },
            GetYearByDateTime: (time: TDateTime) => {
                return this.GetYearByDateTime(time)
            },
            High: (lastItem0_based_index: number) => {
                return this.High(lastItem0_based_index)
            },
            IndicatorBuffers: (bufferCount: number) => {
                this.IndicatorBuffers(bufferCount)
            },
            // IndicatorDigits: (digits: number) => {},
            IndicatorShortName: (shortName: string) => {
                this.IndicatorShortName(shortName)
            },
            // LRCChannelParams: (
            //     shift: number,
            //     period: number,
            //     priceType: TPriceType) => {
            //     return { Bottom: 0, EndValue: 0, Height: 0, StartValue: 0, Top: 0 }
            // },
            Low: (lastItem0_based_index: number) => {
                return this.Low(lastItem0_based_index)
            },
            ObjectCreate: (
                name: string,
                objType: TObjectType,
                window: number,
                time1: TDateTime,
                price1: number,
                time2: TDateTime | undefined,
                price2: number | undefined,
                time3: TDateTime | undefined,
                price3: number | undefined,
                isStatic: boolean | undefined
            ) => {
                let isStaticParam = false
                if (isStatic !== undefined) {
                    isStaticParam = isStatic
                }
                return this.ObjectCreate(
                    name,
                    objType,
                    window,
                    time1,
                    price1,
                    time2,
                    price2,
                    time3,
                    price3,
                    isStaticParam
                )
            },
            ObjectExists: (uniqueObjectname: string, isStatic?: boolean) => {
                let isStaticParam = false
                if (isStatic !== undefined) {
                    isStaticParam = isStatic
                }
                return this.ObjectExists(uniqueObjectname, isStaticParam)
            },
            ObjectDelete: (name: string, isStatic?: boolean) => {
                let isStaticParam = false
                if (isStatic !== undefined) {
                    isStaticParam = isStatic
                }
                return this.ObjectDelete(name, isStaticParam)
            },
            // ObjectExists: (name: string) => {
            //     return false
            // },
            // ObjectGet: (name: string, index: number) => {
            //     return 0
            // },
            // ObjectGetText: (name: string) => {
            //     return ''
            // },
            // ObjectName: (index: number) => {
            //     return ''
            // },
            ObjectSet: (name: string, index: number, value: any, isStatic?: boolean) => {
                let isStaticParam = false
                if (isStatic !== undefined) {
                    isStaticParam = isStatic
                }
                return this.ObjectSet(name, index, value, isStaticParam)
            },
            ObjectSetText: (
                name: string,
                text: string,
                fontSize?: number,
                fontName?: string,
                color?: TColor,
                isStatic?: boolean
            ) => {
                let textFontSize = 12
                let textFontName = 'Roboto Flex'
                let textColor: TColor = DelphiColors.clBlack
                let isStaticParam = false

                if (fontSize) {
                    textFontSize = fontSize
                }
                if (fontName) {
                    textFontName = fontName
                }
                if (color) {
                    textColor = color
                }
                if (isStatic !== undefined) {
                    isStaticParam = isStatic
                }
                let fontStyle: TMkFontStyle = new TMkFontStyle(textFontName, 10, textFontSize, textColor)
                return this.ObjectSetText(name, text, fontStyle, isStaticParam)
            },
            // ObjectType: (name: string) => {
            //     return undefined
            // },
            // ObjectsDeleteAll: (window: number, objType: TObjectType) => {},
            // ObjectsTotal: () => {
            //     return 0
            // },
            Open: (index: number) => {
                return this.Open(index)
            },
            Point: () => {
                return this.Point
            },
            // Print: (s: string) => {},
            RecalculateMeAlways: () => {
                this.RecalculateMeAlways()
            },
            RegApplyToPriceOption: (optionPtr: TOptValue, name = '') => {
                this.RegApplyToPriceOption(optionPtr, name)
            },
            RegMATypeOption: (optionPtr: TOptValue) => {
                this.RegMATypeOption(optionPtr)
            },
            RegOption: (optionName: string, optionType: number, optionPtr: TOptValue) => {
                this.RegOption(optionName, optionType, optionPtr)
            },
            // built-in function of delphi that replaces a substring within a string, no need to implement (i think)
            // ReplaceStr: (dest: string, source: string) => {},
            // Not used in any indicators
            // ScrToChartX: (x: number) => {
            //     return 0
            // },
            // ScrToChartY: (y: number) => {
            //     return 0
            // },
            SetBackOffsetForCalculation: (offset: number) => {
                this.SetBackOffsetForCalculation(offset)
            },
            SetBufferShift: (bufferIndex: number, shift: number) => {
                this.SetBufferShift(bufferIndex, shift)
            },

            SetEmptyValue: (emptyValue: number) => {
                this.SetEmptyValue(emptyValue)
            },
            SetFixedMinMaxValues: (aMin: number, aMax: number) => {
                this.SetFixedMinMaxValues(aMin, aMax)
            },
            SetIndexBuffer: (index: number, buffer: TIndexBuffer) => {
                this.SetIndexBuffer(index, buffer.getBuffer())
            },
            SetIndexDrawBegin: (bufferIndex: number, PaintFrom: number) => {
                this.SetIndexDrawBegin(bufferIndex, PaintFrom)
            },
            // SetIndexEmptyValue: (bufferIndex: number, value: number) => {},
            SetIndexLabel: (bufferIndex: number, bufferName: string) => {
                this.SetIndexLabel(bufferIndex, bufferName)
            },
            SetIndexStyle: (bufferIndex: number, _type: number, _style: number, width: number, clr: string) => {
                this.SetIndexStyle(bufferIndex, _type, _style, width, clr)
            },
            SetIndexSymbol: (bufferIndex: number, symbol: number, xoffs: number, yoffs: number) => {
                this.SetIndexSymbol(bufferIndex, symbol, xoffs, yoffs)
            },
            // SetOptionDigits(optionName: string, digits: number) => {},
            SetOptionRange: (optionName: string, minValue: number, maxValue: number) => {
                this.SetOptionRange(optionName, minValue, maxValue)
            },
            SetOutputWindow: (tOutputWindow: TOutputWindow) => {
                this.SetOutputWindow(tOutputWindow)
            },
            Symbol: () => {
                return this.Symbol()
            },
            Time: (index: number) => {
                return this.Time(index)
            },
            TimeCurrent: () => {
                return 0
            },
            Timeframe: () => {
                return this.Timeframe
            },
            Volume: (index: number) => {
                return this.Volume(index)
            },
            iBarShift: (symbol: string, timeframe: number, time: TDateTime, exact: boolean) => {
                return this.iBarShift(symbol, timeframe, time, exact)
            },
            iBars: (symbol: string, timeframe: number) => {
                return this.iBars(symbol, timeframe)
            },
            iClose: (symbol: string, timeframe: number, index: number) => {
                return this.iClose(symbol, timeframe, index)
            },
            iHigh: (symbol: string, timeframe: number, index: number) => {
                return this.iHigh(symbol, timeframe, index)
            },
            iHighest: (symbol: string, timeframe: number, _type: number, count: number, index: number) => {
                return this.iHighest(symbol, timeframe, _type, count, index)
            },
            iLow: (symbol: string, timeframe: number, index: number) => {
                return this.iLow(symbol, timeframe, index)
            },
            iLowest: (symbol: string, timeframe: number, _type: number, count: number, index: number) => {
                return this.iLowest(symbol, timeframe, _type, count, index)
            },
            iMACD: (
                symbol: string | null,
                timeframe: number | null,
                fastEmaPeriod: number,
                slowEmaPeriod: number,
                signalPeriod: number,
                appliedPrice: TPriceType,
                mode: MacdMode,
                index: number
            ) => {
                return 0
            },
            iOpen: (symbol: string, timeframe: number, index: number) => {
                return this.iOpen(symbol, timeframe, index)
            },
            iTime: (symbol: string, timeframe: number, index: number) => {
                return this.iTime(symbol, timeframe, index)
            },
            iVolume: (symbol: string, timeframe: number, index: number) => {
                return this.iVolume(symbol, timeframe, index)
            },
            setIndicatorIdKey: (key: string) => {
                this.indicatorKey = key
            }
            // mql4_Counted_bars: () => {
            //     return
            // }
        }

        this._userIndicator.OnAttach(realApiImplementation)
    }

    public getBackOffsetForCalculation() {
        return this._backOffsetForCalculation
    }

    public get BarsArray(): IFMBarsArray {
        return this.fBars
    }

    public set BarsArray(value: IFMBarsArray) {
        this.fBars = value
    }

    public get ChartWindow(): TChart {
        return this.fChartWindow
    }

    public set ChartWindow(value: TChart) {
        this.fChartWindow = value
    }

    public get SymbolData(): ISymbolData {
        return this.fSymbol
    }

    public get buffers(): TIndexBuffersList {
        return this.fBuffers
    }

    private ReverseFrom_firstItem0_to_lastItem0(firstItem0_index: number): number {
        return this.fBars.LastItemInTestingIndex - firstItem0_index
    }

    private Reverse_from_lastItem0_to_firstItem0(lastItem0_index: number): number {
        return this.fBars.LastItemInTestingIndex - lastItem0_index
    }

    public RecountValuesForTheRange(
        start_visibleIndex_firstItem0based: number,
        end_VisibleIndex_firstItem0based: number
    ): void {
        try {
            for (let i = start_visibleIndex_firstItem0based; i <= end_VisibleIndex_firstItem0based; i++) {
                if (this._isNeedRecalculateAlways || this.fBuffers.IsEmpty(i)) {
                    const reversedIndex_lastItem0based = this.ReverseFrom_firstItem0_to_lastItem0(i)
                    this.fRecalculateBarProc(reversedIndex_lastItem0based)
                }
            }
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                DebugUtils.warn(`Cannot calculate indicators yet, data is not available. Error: ${error.message}`)
            }
            for (let i = start_visibleIndex_firstItem0based; i <= end_VisibleIndex_firstItem0based; i++) {
                //just in case let's reclculate all values
                this.fBuffers.ClearValueAtGlobalIndex(i)
            }
        }
    }

    //do not call this in the constructor, somehow it does not work because some class members of derived classes are not initialized yet
    public InitializeTheIndicator(): void {
        this.fInitProc()

        if (this.fOnParamsChangeProc) {
            this.fOnParamsChangeProc()
        }
    }

    get OnPaintProc(): TOnPaintProc {
        return this.fOnPaintProc
    }

    // Helper method to check if the index is within the range of the array.
    private isIndexInRange(index: number, array: any[]): boolean {
        return index >= 0 && index < array.length
    }

    private isValidOutputWindow(value: number): boolean {
        return Object.values(TOutputWindow).includes(value)
    }

    public ProcessChartEvent(id: number, lparam: number, dparam: number, sparam: string): void {
        if (this.fOnChartEvent) {
            this.fOnChartEvent(id, lparam, dparam, sparam)
        }
    }

    public ReassignInfo(aSymbol: ISymbolData): void {
        this.fSymbol = aSymbol
        if (this.fSymbol) {
            const bars = this.fSymbol.GetOrCreateBarArray(this.fTimeframe)
            if (bars) {
                this.fBars = bars
            }
        }
    }

    public RefreshLevels(): void {
        this.fLevels.ImportFromStr(this.fLevelsStr.value)
    }

    public SaveOptions(): void {
        this.UpdateLevelsInOptions()
    }

    public UpdateLevelsInOptions(): void {
        const levelsOption = this.fOptions.GetOptionRec('Levels')
        if (levelsOption) {
            levelsOption.Value = this.fLevels.ExportToStr(this.fDecimals)
        } else {
            throw new StrangeError('Levels option not found.')
        }
    }

    public RefreshOptions(): void {
        this.fOnParamsChangeProc?.()
    }

    public Show() {
        this.isVisible = true
        this._userIndicator.OnShow()
        this.indicatorConfigurationControl?.onOwnerIndicatorShow()
        this.oscConfigurationControl?.onOwnerIndicatorShow()
    }

    public Hide() {
        this.isVisible = false
        this._userIndicator.OnHide()
        this.indicatorConfigurationControl?.onOwnerIndicatorHide()
        this.oscConfigurationControl?.onOwnerIndicatorHide()
    }

    IsVisible(): boolean {
        return this.isVisible
    }

    private calcWMASum(startIndex: number, period: number, shift: number, applyTo: TPriceType): number {
        let sum = 0
        for (let i = 0; i < period; i++) {
            sum += this.GetPrice(startIndex + i + shift, applyTo) * (period - i)
        }
        return sum
    }

    private getWmaValue(
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        weightForPeriod: number
    ): number {
        const currentValueInCache = GlobalMovingAverageCache.getInstance().getCurrWmaValue(
            this.fSymbol.symbolInfo.SymbolName,
            this.fTimeframe,
            index,
            shift,
            period,
            applyTo,
            this.fBars.LastItemInTestingIndex
        )

        if (currentValueInCache) {
            return currentValueInCache.value / weightForPeriod
        } else {
            const wmaInfo: WmaInfo = {
                value: this.calcWMASum(index, period, shift, applyTo),
                lastWeightedPrice: this.GetPrice(index, applyTo) * period
            }
            GlobalMovingAverageCache.getInstance().saveWmaSum(
                this.fSymbol.symbolInfo.SymbolName,
                this.fTimeframe,
                index,
                shift,
                period,
                applyTo,
                this.fBars.LastItemInTestingIndex,
                wmaInfo
            )
            return wmaInfo.value / weightForPeriod
        }
    }

    private getWmaValueForCurrentTestingIndex(
        shift: number,
        period: number,
        applyTo: TPriceType,
        weightForPeriod: number
    ): number {
        const currentValueInCache = GlobalMovingAverageCache.getInstance().getCurrWmaValue(
            this.fSymbol.symbolInfo.SymbolName,
            this.fTimeframe,
            0,
            shift,
            period,
            applyTo,
            this.fBars.LastItemInTestingIndex
        )

        let sum: number
        const lastWeightedPrice: number = this.GetPrice(0, applyTo) * period

        if (currentValueInCache) {
            sum = currentValueInCache.value - currentValueInCache.lastWeightedPrice + lastWeightedPrice
        } else {
            sum = this.calcWMASum(0, period, shift, applyTo)
        }

        const wmaInfo: WmaInfo = {
            value: sum,
            lastWeightedPrice: lastWeightedPrice
        }
        GlobalMovingAverageCache.getInstance().saveWmaSum(
            this.fSymbol.symbolInfo.SymbolName,
            this.fTimeframe,
            0,
            shift,
            period,
            applyTo,
            this.fBars.LastItemInTestingIndex,
            wmaInfo
        )

        return sum / weightForPeriod
    }

    private NoBarsAvail(): boolean {
        return !this.fBars || !this.fBars.LastItemInTestingIndexAvailable
    }

    private TestStarted(): boolean {
        return GlobalOptions.Options.TestStarted
    }

    //this
    private GetBar_lastItem0_based_index(lastItem0_based_index: number): TBarRecord {
        if (this.NoBarsAvail()) {
            throw new DataNotDownloadedYetError('No bars available - GetBar_lastItem0_based_index')
        }

        let firstItem0_based_index = this.Reverse_from_lastItem0_to_firstItem0(lastItem0_based_index)
        firstItem0_based_index = this.fBars.FitIndex(firstItem0_based_index)
        const bar = this.fBars.GetItemByGlobalIndex(firstItem0_based_index)
        if (!bar) {
            throw new DataNotDownloadedYetError(
                `GetBar_lastItem0_based_index - Bar at index ${lastItem0_based_index} is not available`
            )
        }
        return bar
    }

    // GetBar function retrieves the bar record for a given symbol, timeframe, and index.
    // It returns a reference to an empty bar if the symbol data is not found or if there are no bars.
    private GetBar(Symbol: string, TimeFrame: number, index: number): TBarRecord {
        // Default result is a reference to an empty bar.
        let result: TBarRecord = new TBarRecord(0, 0, 0, 0, 0, 0)

        // Retrieve symbol data from the SymbolList.
        const data = GlobalSymbolList.SymbolList.GetOrCreateSymbol(Symbol, true)
        if (data === null) {
            return result
        }

        // Get bars array for the specified timeframe.
        const bars = data.GetOrCreateBarArray(TimeFrame)
        if (bars === null || !bars.LastItemInTestingIndexAvailable) {
            return result
        }

        // Adjust the index to fit within the bars array.
        index = bars.FitIndex(index)

        // Return the bar at the adjusted index.
        const foundBar = bars.GetItemByGlobalIndex(bars.LastItemInTestingIndex - 1 - index)
        if (foundBar) {
            result = foundBar
        }
        return result
    }

    ExportData(edit?: boolean): void {
        const { setIndicatorOptions, resetIndicatorOptions } = IndicatorOptionsStore

        resetIndicatorOptions()
        this.RefreshOptions()
        this.SaveOptions()
        let finalValue: string | Level[] | TBufferStyle | null
        const decimals = this.fDecimals

        const indicatorOptions = Object.entries(this.options).reduce<Record<string, any>>((acc, [key, value]) => {
            const { alias, Value: valueTemp, OptionType: type, values, HighValue, LowValue } = value

            if (alias === 'Levels') {
                if (this.fOutputWindow === TOutputWindow.ow_SeparateWindow) {
                    finalValue = this.parseOptionValue(type, valueTemp)
                } else {
                    finalValue = null
                }
            } else if (type === TOptionType.ot_LineStyle) {
                const lineStyleOptions = this.parseOptionValue(type, valueTemp)
                if (lineStyleOptions instanceof TLineStyle) {
                    const buffer = this.VisibleBuffers.find((buff) => buff.name === alias)
                    if (buffer) {
                        finalValue = new TBufferStyle(
                            lineStyleOptions.color,
                            lineStyleOptions.style,
                            lineStyleOptions.width,
                            buffer.IsVisible()
                        )
                    } else {
                        finalValue = lineStyleOptions.SaveToStr()
                    }
                }
            } else {
                finalValue = this.parseOptionValue(type, valueTemp)
            }

            acc[key] = { alias, fvalue: finalValue, type, values, HighValue, LowValue, decimals }

            return acc
        }, {})

        const finalIndicatorOptions = {
            ...indicatorOptions,
            indicatorInstance: this
        }
        setIndicatorOptions(finalIndicatorOptions)

        if (edit) {
            addModal(MODAL_NAMES.chart.indicatorModal, { isEdit: true })
        } else {
            addModal(MODAL_NAMES.chart.indicatorModal, { isEdit: false })
        }
    }

    ImportData(): void {
        const { indicatorOptions } = IndicatorOptionsStore
        Object.entries(indicatorOptions).forEach(([key, option]: [string | number, any]) => {
            if (key === 'indicatorInstance') return
            const { fvalue } = option
            const fvalueString = this.formatOptionValue(this.options[key as number].OptionType, fvalue)
            this.options[key as number].SetValue(fvalueString)
            if (fvalue instanceof TBufferStyle) {
                this.processBufferStyleChange(option.alias, fvalue.isVisible)
            }
            if (option.alias === 'Levels') {
                this.processLevelChange(fvalueString)
            }
        })
    }

    private processBufferStyleChange(optionName: string, isVisible: boolean): void {
        const buffer = this.VisibleBuffers.find((_buffer) => _buffer.name === optionName)
        if (buffer) {
            isVisible ? buffer.show() : buffer.hide()
        }
    }

    private processLevelChange(newValue: string) {
        this.fLevels.Clear()
        if (!newValue) return

        // Trim leading and trailing braces and split into level strings
        const levels = newValue
            .slice(1, -1)
            .split('}{')
            .map((level) => level.trim())
        levels.forEach((level, index) => {
            const [value, text, color, style, width, id, isActive] = level
                .split(' ')
                .map((comp) => comp.replace(/^"|"$/g, ''))
            const fLevel = this.fLevels[index]

            if (fLevel) {
                fLevel.SetLevelFromStr(level)
            } else {
                this.AddLevel(
                    parseFloat(value.replace(',', '.')),
                    parseInt(style),
                    parseInt(width),
                    color,
                    1,
                    parseInt(isActive)
                )
            }
        })
    }

    private parseOptionValue(type: TOptionType, value: any): any {
        switch (type) {
            case TOptionType.ot_Session: {
                return TOptValue_Session.copyFromString(value)
            }
            case TOptionType.ot_SessionsArray: {
                return TOptValue_SessionsArray.copyFromString(value)
            }
            case TOptionType.at_Levels: {
                const level = new Level()
                return level.LevelFromString(value)
            }
            case TOptionType.ot_LineStyle: {
                const [color, style, width] = value.split(',')
                return new TLineStyle(color, parseInt(style), parseInt(width))
            }
            case TOptionType.ot_double: {
                return parseFloat(value.replace(',', '.'))
            }
            case TOptionType.ot_Integer:
            case TOptionType.ot_Longword: {
                return parseInt(value.replace(',', '.'))
            }
            case TOptionType.ot_Boolean: {
                return value === 'Yes'
            }
            default: {
                return value
            }
        }
    }

    private formatOptionValue(type: TOptionType, value: TLineStyle | Level[] | string): string {
        if (type === TOptionType.ot_LineStyle && value instanceof TLineStyle) {
            return `${value.color},${value.style},${value.width}`
        }
        if (type === TOptionType.at_Levels && Array.isArray(value)) {
            const level = new Level()
            if (Array.isArray(value) && value.length === 0) {
                return ''
            }
            return level.LevelToString(value)
        }
        if (type === TOptionType.ot_Boolean) {
            return value ? 'Yes' : 'No'
        }
        return value === null ? '' : value.toString()
    }

    public clearBuffers(): void {
        this.fBuffers.ClearValues()
    }

    //# api Implementation {

    private AddLevel(value: number, style: TPenStyle, width: number, clr: TColor, opacity: number, isActive = 1) {
        if (opacity < 0 || opacity > 1) {
            throw new StrangeError(
                'Invalid opacity value for the indicator level! The opacity was',
                opacity,
                ', but valid values range from 0 to 1.'
            )
        }
        const tLevelData = new TLevelData(
            '0',
            new TLineStyle(ColorHelperFunctions.MakeColor(clr, opacity * 255), style, width)
        )
        tLevelData.text = '0'
        tLevelData.value = value
        tLevelData.isActive = isActive
        this.fLevels.Add(tLevelData)
    }

    private AddSeparator(text: string): void {
        this.RegOption(text, TOptionType.ot_Separator, null)
    }

    private get Ask(): number {
        return this.fSymbol.ask
    }

    private get Bars(): number {
        return this.fBars.LastItemInTestingIndex + 1
    }

    private get Bid(): number {
        return this.fSymbol.bid
    }

    private Close(lastItem0_based_index: number): number {
        const bar = this.GetBar_lastItem0_based_index(lastItem0_based_index)
        return bar.close
    }

    private CreateIndexBuffer(): TIndexBufferProgramSide {
        // Create a new TIndexBuffer instance
        const buffer = new TCommonIndexBuffer(this.BarsArray, this.EmptyValue)

        // Add the buffer to the buffers collection
        this.fBuffers.push(buffer)

        // Return the newly created buffer
        return buffer
    }

    public get Digits(): number {
        return this.fSymbol.symbolInfo.decimals
    }

    private GetMA(
        index: number,
        shift: number,
        period: number,
        maType: TMAType,
        applyTo: TPriceType,
        prev = 0
    ): number {
        let i: number
        let k: number
        switch (maType) {
            case TMAType.ma_SMA: {
                // SMA

                if (index + shift + period > this.fBars.LastItemInTestingIndex || index + shift < 0) {
                    return 0
                }

                let sum = 0
                const prevSum = GlobalMovingAverageCache.getInstance().getPrevSum(
                    this.fSymbol.symbolInfo.SymbolName,
                    this.fTimeframe,
                    index,
                    shift,
                    period,
                    applyTo,
                    this.fBars.LastItemInTestingIndex
                )

                if (prevSum) {
                    sum =
                        prevSum - this.GetPrice(index + shift + period, applyTo) + this.GetPrice(index + shift, applyTo)
                } else {
                    for (let local_i = index + shift; local_i < index + shift + period; local_i++) {
                        sum += this.GetPrice(local_i, applyTo)
                    }
                }

                GlobalMovingAverageCache.getInstance().saveCurrentSum(
                    this.fSymbol.symbolInfo.SymbolName,
                    this.fTimeframe,
                    index,
                    shift,
                    period,
                    applyTo,
                    this.fBars.LastItemInTestingIndex,
                    sum
                )

                return sum / period
            }
            case TMAType.ma_EMA: {
                // EMA
                k = 2 / (period + 1)
                i = index + shift
                if (i >= this.fBars.LastItemInTestingIndex || i < 0) {
                    return 0
                } else {
                    if (prev === 0) {
                        return this.GetPrice(i, applyTo)
                    } else {
                        return prev + k * (this.GetPrice(i, applyTo) - prev)
                    }
                }
            }
            case TMAType.ma_SSMA: {
                // SSMA
                if (index + shift + period > this.fBars.LastItemInTestingIndex || index + shift < 0) {
                    return 0
                } else {
                    if (prev === 0) {
                        // Recursive call with ma_SMA to initialize SSMA
                        return this.GetMA(index, shift, period, TMAType.ma_SMA, applyTo, prev)
                    } else {
                        return (prev * (period - 1) + this.GetPrice(index + shift, applyTo)) / period
                    }
                }
            }
            case TMAType.ma_WMA: {
                // WMA
                if (index + shift + period > this.fBars.LastItemInTestingIndex || index + shift < 0) {
                    return 0
                }

                const currWeight = (period / 2) * (1 + period)
                if (index === 0) {
                    return this.getWmaValueForCurrentTestingIndex(shift, period, applyTo, currWeight)
                } else {
                    return this.getWmaValue(index, shift, period, applyTo, currWeight)
                }
            }
            default: {
                throw new StrangeError('Unsupported MA type')
            }
        }
    }

    private GetMonthByDateTime(time: TDateTime): number {
        return DateUtils.MonthOf(time)
    }

    private GetPrice(lastItem_0_based_index: number, PriceType: TPriceType): number {
        let high: number, low: number, close: number

        switch (PriceType) {
            case TPriceType.pt_Close: {
                return this.Close(lastItem_0_based_index)
            }
            case TPriceType.pt_Open: {
                return this.Open(lastItem_0_based_index)
            }
            case TPriceType.pt_High: {
                return this.High(lastItem_0_based_index)
            }
            case TPriceType.pt_Low: {
                return this.Low(lastItem_0_based_index)
            }
            case TPriceType.pt_HL2: {
                high = this.High(lastItem_0_based_index)
                low = this.Low(lastItem_0_based_index)
                return (high + low) / 2
            }
            case TPriceType.pt_HLC3: {
                high = this.High(lastItem_0_based_index)
                low = this.Low(lastItem_0_based_index)
                close = this.Close(lastItem_0_based_index)
                return (high + low + close) / 3
            }
            case TPriceType.pt_HLCC4: {
                high = this.High(lastItem_0_based_index)
                low = this.Low(lastItem_0_based_index)
                close = this.Close(lastItem_0_based_index)
                return (high + low + close * 2) / 4
            }
            default: {
                return 0
            }
        }
    }

    private GetWeekByDateTime(time: TDateTime): number {
        return DateUtils.WeekOf(time)
    }

    private GetYearByDateTime(time: TDateTime): number {
        return DateUtils.YearOf(time)
    }

    private High(lastItem0_based_index: number): number {
        const bar = this.GetBar_lastItem0_based_index(lastItem0_based_index)
        return bar.high
    }

    private IndicatorBuffers(count: number): void {
        if (count > this.VisibleBuffers.Count) {
            //add new default buffers, we will init them below
            for (let i = this.VisibleBuffers.Count; i < count; i++) {
                this.fVisibleBuffers.push(new TVisibleIndexBuffer())
            }
        } else if (count < this.VisibleBuffers.Count) {
            //decrease the number of visible buffers
            this.fVisibleBuffers.SetLength(count)
        }

        if (count === 0) {
            return
        }

        this.AddSeparator('Styles')

        // Initialize the fVisibleBuffers array with default values for each buffer
        for (let i = 0; i < this.fVisibleBuffers.length; i++) {
            const thisBuffer = this.fVisibleBuffers[i]

            thisBuffer.buffer = null
            thisBuffer.style.color = '#FF0000' // clRed
            thisBuffer.style.style = TPenStyle.psSolid // psSolid
            thisBuffer.style.width = 1
            thisBuffer.PaintFrom = 0
        }
    }

    private IndicatorShortName(shortName: string) {
        this.fShortName = shortName
    }

    private ObjectCreate(
        name: string,

        objType: TObjectType,
        window: number,
        time1: TDateTime,
        price1: number,
        time2: TDateTime | undefined,
        price2: number | undefined,
        time3: TDateTime | undefined,
        price3: number | undefined,
        isStatic: boolean | undefined
    ): boolean {
        let result = false

        let tool: TBasicPaintTool
        let t: TPaintToolClass

        if (!this.fChartWindow || this.fChartWindow.PaintTools.NameExists(name)) {
            return result
        }

        const toolNameToCreate = objectTypeToName(objType)

        if (toolNameToCreate === '') {
            return result
        }

        const ToolClass = PaintToolManager.GetPaintToolClass(toolNameToCreate)
        if (ToolClass === null) {
            return false
        }

        tool = new ToolClass(this.fChartWindow)
        tool.CompleteTool()

        const encoder = new TextEncoder()
        const uint8Array = encoder.encode(name)
        tool.SetAllTimeframes()
        tool.SymbolName = this.fSymbol.symbolInfo.SymbolName
        if (isStatic) {
            this.fChartWindow.staticPaintTools.AddTool(tool)
            this.fChartWindow.staticPaintTools.ChangeToolName(tool, StrsConv.Utf8ToString(uint8Array))
        } else {
            this.fChartWindow.PaintTools.AddTool(tool)
            this.fChartWindow.PaintTools.ChangeToolName(tool, StrsConv.Utf8ToString(uint8Array))
        }

        if (objType === TObjectType.obj_VLine) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
        }
        if (objType === TObjectType.obj_HLine) {
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
        }
        if (
            objType === TObjectType.obj_Text
            // ||
            // objType === TObjectType.obj_Sign ||
            // objType === TObjectType.obj_RightPriceLabel ||
            // objType === TObjectType.obj_LeftPriceLabel
        ) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
        }
        if (
            objType === TObjectType.obj_TrendLine ||
            objType === TObjectType.obj_Ray ||
            objType === TObjectType.obj_FiboRetracement ||
            objType === TObjectType.obj_FiboTimeZones ||
            objType === TObjectType.obj_FiboArc ||
            objType === TObjectType.obj_FiboFan
            // ||
            // objType === TObjectType.obj_PriceLabel)
        ) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
            tool.SetProperty(ObjProp.OBJPROP_TIME2, time2)
            tool.SetProperty(ObjProp.OBJPROP_PRICE2, price2)
        }
        if (objType === TObjectType.obj_Rectangle || objType === TObjectType.obj_Ellipse) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
            tool.SetProperty(ObjProp.OBJPROP_TIME2, time2)
            tool.SetProperty(ObjProp.OBJPROP_PRICE2, price2)
            tool.SetProperty(ObjProp.OBJPROP_FILLINSIDE, 1)
        }
        if (objType === TObjectType.obj_Triangle) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
            tool.SetProperty(ObjProp.OBJPROP_TIME2, time2)
            tool.SetProperty(ObjProp.OBJPROP_PRICE2, price2)
            tool.SetProperty(ObjProp.OBJPROP_TIME3, time3)
            tool.SetProperty(ObjProp.OBJPROP_PRICE3, price3)
        }

        result = true

        return result
    }

    private ObjectExists(uniqueObjectname: string, isStatic: boolean): boolean {
        let result = false

        if (this.fChartWindow) {
            if (isStatic) {
                result = this.fChartWindow.staticPaintTools.NameExists(uniqueObjectname)
            } else {
                result = this.fChartWindow.PaintTools.NameExists(uniqueObjectname)
            }
        }

        return result
    }

    private ObjectDelete(uniqueObjectName: string, isStatic: boolean) {
        if (this.fChartWindow) {
            if (isStatic) {
                this.fChartWindow.staticPaintTools.DeleteTool(uniqueObjectName)
            } else {
                this.fChartWindow.PaintTools.DeleteTool(uniqueObjectName)
            }
        }
    }

    ObjectSetText(name: string, text: string, fontStyle: TMkFontStyle, isStatic: boolean): boolean {
        let result = false
        let tool: TBasicPaintTool | null = null
        if (this.fChartWindow) {
            if (isStatic) {
                tool = this.fChartWindow.staticPaintTools.GetTool(name)
            } else {
                tool = this.fChartWindow.PaintTools.GetTool(name)
            }
            if (tool) {
                tool.SetProperty(ObjProp.OBJPROP_TEXT, text)
                tool.SetProperty(ObjProp.OBJPROP_TEXT_PARAMS, fontStyle)
                result = true
            }
        }

        return result
    }

    private ObjectSet(name: string, index: number, value: any, isStatic: boolean): boolean {
        let result = false

        if (this.fChartWindow) {
            if (isStatic) {
                const tool = this.fChartWindow.staticPaintTools.GetTool(name)
                if (tool) {
                    tool.SetProperty(index, value)
                    result = true
                }
            } else {
                const tool = this.fChartWindow.PaintTools.GetTool(name)
                if (tool) {
                    tool.SetProperty(index, value)
                    result = true
                }
            }
        }

        return result
    }

    private Low(lastItem0_based_index: number): number {
        const bar = this.GetBar_lastItem0_based_index(lastItem0_based_index)
        return bar.low
    }

    private Open(lastItem0_based_index: number): number {
        const bar = this.GetBar_lastItem0_based_index(lastItem0_based_index)
        return bar.open
    }

    private get Point(): number {
        return this.fSymbol.symbolInfo.MinPoint
    }

    private RecalculateMeAlways() {
        this._isNeedRecalculateAlways = true
    }

    private RegApplyToPriceOption(option: TOptValue, name = ''): void {
        if (name === '') {
            name = t('indicatorModal.general.applyToPrice')
        }

        this.RegOption(name, TOptionType.ot_EnumType, option)
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.close'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.open'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.high'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.low'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.hl2'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.hlc3'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.hlcc4'))
        // Uncomment the following line if the option should be reset to 0 as indicated by the commented line in the Delphi code.
        // option.value = 0;

        // return option;
    }

    private RegMATypeOption(option: TOptValue, name = ''): void {
        // Set the default name for the option if not provided
        if (name === '') {
            name = t('indicatorModal.movingAverage.fields.maType')
        }

        // Register the option with the given name and type
        this.RegOption(name, TOptionType.ot_EnumType, option)

        // Add possible values for the MA type option
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.sma'))
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.ema'))
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.ssma'))
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.wma'))

        //TODO: in Delphi it was commented out, I do not know if we can comment it out here option.value = 0;
    }

    private SetBackOffsetForCalculation(offsetForCalculation: number) {
        this._backOffsetForCalculation = offsetForCalculation
    }

    private SetEmptyValue(value: number): void {
        this.EmptyValue = value

        for (const buffer of this.fBuffers) {
            buffer.EmptyValue = value

            // TODO:EX TLoopbackIndexBuffer = class(TIndexBuffer)
            // if (buffer instanceof TLoopbackIndexBuffer) {
            //     ;(buffer as TLoopbackIndexBuffer).buff.EmptyValue = value
            // }
        }
    }

    private SetFixedMinMaxValues(minValue: number, maxValue: number) {
        this.fMinValue = minValue
        this.fMaxValue = maxValue
    }

    // Function Overloading Declarations
    private SetIndexBuffer(index: number, buffer: IIndexBuffer): void
    private SetIndexBuffer(index: number, buffer: number): void
    // Unified Implementation
    private SetIndexBuffer(index: number, buffer: any): void {
        let bufferIndex: number
        if (buffer instanceof TIndexBufferProgramSide) {
            bufferIndex = this.buffers.IndexOf(buffer)
        } else {
            bufferIndex = buffer
        }
        if (
            this.fOptions &&
            this.fVisibleBuffers &&
            this.fVisibleBuffers.IndexValid(index) &&
            this.buffers.IndexValid(bufferIndex)
        ) {
            const name = `BufferStyle${index.toString().padStart(2, '0')}` // Use const for immutable variable
            this.fOptions.DeleteByMask(name)
            this.fOptions.RegOption(name, TOptionType.ot_LineStyle, this.fVisibleBuffers[index].style)
            this.fVisibleBuffers[index].buffer = this.buffers[bufferIndex] // Use the correct case for buffers
            this.fVisibleBuffers[index].name = `Value ${index}`
        }
    }

    private SetIndexDrawBegin(bufferIndex: number, PaintFrom: number): void {
        if (this.VisibleBuffers.IndexValid(bufferIndex)) {
            this.VisibleBuffers[bufferIndex].PaintFrom = Math.max(0, PaintFrom)
        }
    }

    // Improved TypeScript implementation of the SetIndexLabel method
    private SetIndexLabel(index: number, text: string | null): void {
        // Use the exact format as in Delphi, including leading zeros for the index
        const name = `BufferStyle${index.toString().padStart(2, '0')}`

        // Convert text to a string, handling null as an empty string
        // In Delphi, PAnsiChar is a pointer to an ANSI string, so when it's null,
        // it should be converted to an empty string. The original TypeScript code
        // correctly uses the nullish coalescing operator (??) to handle this.
        const s = text ?? ''

        // Retrieve the option record using the formatted name
        const rec = this.fOptions.GetOptionRec(name)
        if (rec) {
            // Assign the alias in the option record
            rec.alias = s

            // Check if the index is within the range of fVisibleBuffers
            // The original TypeScript code correctly translates the InRange check
            // from Delphi to a simple range check in TypeScript.
            if (index >= 0 && index < this.fVisibleBuffers.length) {
                // Update the name in the visible buffer at the specified index
                this.fVisibleBuffers[index].name = s
            }
        }
    }

    private SetIndexStyle(index: number, _type: number, _style: number, width: number, clr: TColor): void {
        // Check if the index is within the range of the fVisibleBuffers array.
        if (this.isIndexInRange(index, this.fVisibleBuffers)) {
            // Using a local variable for the buffer style to mimic the 'with' statement from Delphi.
            const bufferStyle = this.fVisibleBuffers[index].style

            clr = ColorHelperFunctions.FixColor(clr)

            if (_type === TDrawStyle.ds_Fill) {
                clr = ColorHelperFunctions.MakeColor(clr, 100)
                // Check if the previous buffer exists before trying to access it.
                if (index > 0 && this.fVisibleBuffers[index - 1]) {
                    this.fVisibleBuffers[index - 1].style.color = ColorHelperFunctions.MakeColor(
                        this.fVisibleBuffers[index - 1].style.color,
                        100
                    )
                }
            }

            // Apply the styles to the buffer.
            bufferStyle.color = clr
            bufferStyle.style = _style as TPenStyle
            bufferStyle.width = width

            // Only set the DrawingStyle if _type is not -1.
            if (_type !== -1) {
                bufferStyle.DrawingStyle = _type as TDrawStyle
            }
        } else {
            throw new StrangeError('Index out of range')
        }
    }

    private SetOutputWindow(aWindow: number): void {
        if (this.isValidOutputWindow(aWindow)) {
            this.fOutputWindow = aWindow as TOutputWindow
        } else {
            throw new StrangeError('Invalid TOutputWindow value')
        }
    }

    private Symbol(): string {
        return this.fSymbol.symbolInfo.SymbolName
    }

    private Time(index: number): TDateTime {
        if (this.NoBarsAvail()) {
            return 0
        }

        index = this.fBars.FitIndex(index)
        const bar = this.fBars.GetItemByGlobalIndex(this.fBars.LastItemInTestingIndex - index)
        if (!bar) {
            throw new StrangeError('bar not found in indicators Time function')
        }
        return bar.DateTime
    }

    private Volume(lastItem0_based_index: number): number {
        const bar = this.GetBar_lastItem0_based_index(lastItem0_based_index)
        return bar.volume
    }

    private iClose(Symbol: string, TimeFrame: number, index: number): number {
        // Assuming GetBar is a method that retrieves a bar record given a symbol, timeframe, and index
        // and that it is already implemented and available in the current context.
        const bar = this.GetBar(Symbol, TimeFrame, index)
        return bar.close
    }

    private iHigh(Symbol: string, TimeFrame: number, index: number): number {
        return this.GetBar(Symbol, TimeFrame, index).high
    }

    private iLow(Symbol: string, TimeFrame: number, index: number): number {
        return this.GetBar(Symbol, TimeFrame, index).low
    }

    private iOpen(Symbol: string, TimeFrame: number, index: number): number {
        // Assuming GetBar is a method that retrieves the bar data for the given symbol, timeframe, and index
        // and that it handles its own error checking, we can directly return the 'open' property of the bar.
        return this.GetBar(Symbol, TimeFrame, index).open
    }

    private iTime(Symbol: string, TimeFrame: number, index: number): TDateTime {
        return this.GetBar(Symbol, TimeFrame, index).DateTime
    }

    private iVolume(Symbol: string, TimeFrame: number, index: number): number {
        return this.GetBar(Symbol, TimeFrame, index).volume
    }

    //TODO:EX function TDllIndicator.ChartToScrX(index: integer): integer;
    private ChartToScrX(index: number): number {
        //in delphi this.fBars.BarsCount is  fBars.count, I am guessing this.fBars.BarsCount is the equivalent
        if (!this.fBars || this.fBars.totalBarsCount === 0 || !this.fChartWindow || !this.fChartWindow.CanBePainted) {
            return 0
        }

        index = this.fBars.FitIndex(index)
        //same as above, i am guessing this.fBars.BarsCount is the equivalent of fBars.count
        index = this.fBars.totalBarsCount - 1 - index
        //ex fChartWindow.GetXFromDate(fBars[index].DateTime)
        return this.fChartWindow.GetXFromDate(this.fBars.GetDateByGlobalIndex(index, false))
    }

    private ChartToScrY(price: number): number {
        if (this.fChartWindow === null || !this.fChartWindow.CanBePainted) {
            return 0
        }
        return this.fChartWindow.GetY(price)
    }

    //TODO:EX function TDllIndicator.GetChartInfo(var ChartInfo: TChartInfo): boolean;
    //TODO:IN fChartWindow.GetChartInfo is not implemented
    private GetChartInfo(): TChartInfo | null {
        if (
            this.fChartWindow === null ||
            !this.fChartWindow.CanBePainted ||
            this.fBars === null ||
            !this.fBars.LastItemInTestingIndexAvailable
        ) {
            //TODO: throw StrangeError??
            DebugUtils.error('GetChartInfo: fChartWindow is null or cannot be painted or fBars is null')
            return null
        }
        return this.fChartWindow.GetChartInfo()
    }

    private GetDayOfMonthByDateTime(time: TDateTime): number {
        return DateUtils.DayOfMonth(time)
    }

    private GetHighestValue(ValueType: TValueType, StartIndex: number, count: number): number {
        const value = (index: number): number => {
            switch (ValueType) {
                case TValueType.vt_Open: {
                    return this.Open(index)
                }
                case TValueType.vt_High: {
                    return this.High(index)
                }
                case TValueType.vt_Low: {
                    return this.Low(index)
                }
                case TValueType.vt_Close: {
                    return this.Close(index)
                }
                default: {
                    return this.Volume(index)
                }
            }
        }

        if (StartIndex !== 0) {
            if (this.fBars && this.fBars.DataDescriptor) {
                const reversedIndex = this.Reverse_from_lastItem0_to_firstItem0(StartIndex)
                let highestPrice = GlobalHighestLowestPriceCache.Instance.getHighestValue(
                    this.fBars.DataDescriptor,
                    reversedIndex,
                    ValueType,
                    count
                )

                if (highestPrice) {
                    return highestPrice
                }
            }
        }

        let result = value(StartIndex)
        for (let i = 1; i < count; i++) {
            const v = value(StartIndex + i)
            if (v > result) {
                result = v
            }
        }

        if (StartIndex !== 0 && this.fBars && this.fBars.DataDescriptor) {
            const reversedIndex = this.Reverse_from_lastItem0_to_firstItem0(StartIndex)
            GlobalHighestLowestPriceCache.Instance.setHighestValue(
                this.fBars.DataDescriptor,
                reversedIndex,
                ValueType,
                count,
                result
            )
        }

        return result
    }

    private GetLowestValue(ValueType: TValueType, StartIndex: number, count: number): number {
        const value = (index: number): number => {
            switch (ValueType) {
                case TValueType.vt_Open: {
                    return this.Open(index)
                }
                case TValueType.vt_High: {
                    return this.High(index)
                }
                case TValueType.vt_Low: {
                    return this.Low(index)
                }
                case TValueType.vt_Close: {
                    return this.Close(index)
                }
                default: {
                    return this.Volume(index)
                }
            }
        }

        if (StartIndex !== 0) {
            if (this.fBars && this.fBars.DataDescriptor) {
                const reversedIndex = this.Reverse_from_lastItem0_to_firstItem0(StartIndex)
                let highestPrice = GlobalHighestLowestPriceCache.Instance.getLowestValue(
                    this.fBars.DataDescriptor,
                    reversedIndex,
                    ValueType,
                    count
                )

                if (highestPrice) {
                    return highestPrice
                }
            }
        }

        let result = value(StartIndex)
        for (let i = 1; i < count; i++) {
            const v = value(StartIndex + i)
            if (v < result) {
                result = v
            }
        }

        if (StartIndex !== 0 && this.fBars && this.fBars.DataDescriptor) {
            const reversedIndex = this.Reverse_from_lastItem0_to_firstItem0(StartIndex)
            GlobalHighestLowestPriceCache.Instance.setLowestValue(
                this.fBars.DataDescriptor,
                reversedIndex,
                ValueType,
                count,
                result
            )
        }

        return result
    }

    private SetBufferShift(BuffIndex: number, shift: number): void {
        if (BuffIndex >= 0 && BuffIndex < this.fVisibleBuffers.length) {
            const bufferItem = this.fVisibleBuffers[BuffIndex]
            if (bufferItem && bufferItem.buffer) {
                bufferItem.buffer.shift = shift
            }
        }
    }

    private SetIndexSymbol(index: number, symbol: number, xoffs: number, yoffs: number): void {
        if (index >= 0 && index < this.fVisibleBuffers.length) {
            this.fVisibleBuffers[index].style.Symbol = symbol
            this.fVisibleBuffers[index].style.xoffs = xoffs
            this.fVisibleBuffers[index].style.yoffs = yoffs
        }
    }

    public onChangeTimeframeOrSymbol(timeframe: number, symbol: TSymbolData): void {
        this.buffers.ClearValues()

        this.fTimeframe = timeframe

        this.ReassignInfo(symbol)

        for (const [index, buffer] of this.buffers.entries()) {
            if (buffer instanceof TCommonIndexBuffer) {
                buffer.bars = this.fBars
                buffer.buff.setDataDescriptor = this.fBars.DataDescriptor
                buffer.buff.setBarsArray = this.fBars
            }
        }

        this.SaveOptions()
        this.RefreshLevels()
        this.RefreshOptions()
    }

    isSame(indicator: TRuntimeIndicator): boolean {
        return this.fFileName === indicator.fFileName
    }

    public toJSON(): IndicatorJSON {
        const listForOptions = new TOffsStringList()
        this.fOptions.SaveToList(listForOptions)
        return {
            ShortName: this.fShortName,
            IsVisible: this.isVisible,
            Levels: this.fLevels.ExportToStr(this.fDecimals),
            Decimals: this.fDecimals,
            Symbol: this.fSymbol.symbolInfo.SymbolName,
            Options: listForOptions.SaveToString(),
            BufferVisibility: this.fVisibleBuffers.map((buffer) => {
                return buffer.IsVisible()
            })
        }
    }
}

export var IndRecountFlag = false
