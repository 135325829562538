import { FC, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { IconButton } from '@fto/icons'
import { TChartStyle } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import ChartSettingsStore from '@fto/lib/store/chartSettings'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import RibbonDropdownMenu from '@fto/chart_components/ProjectInterface/components/Ribbon/components/RibbonDropdownMenu'

import RibbonDropdown from '../../RibbonDropdown'
import { RibbonOptionType } from '../../types'

const ChartModeDropdown: FC = observer(() => {
    const { t } = useTranslation()

    const { settings, setSettings, updateChartSettings } = ChartSettingsStore
    const chartModeOptions = useMemo(
        () =>
            [
                {
                    value: 'candles',
                    name: t('ribbon.chartModes.candles'),
                    icon: 'candle',
                    action: () => handleChartModeChange(TChartStyle.cs_Bar)
                },
                {
                    value: 'lines',
                    name: t('ribbon.chartModes.lines'),
                    icon: 'lines',
                    action: () => handleChartModeChange(TChartStyle.cs_Line)
                },
                {
                    value: 'bars',
                    name: t('ribbon.chartModes.bars'),
                    icon: 'bars',
                    action: () => handleChartModeChange(TChartStyle.cs_Stick)
                }
            ] as RibbonOptionType[],
        []
    )

    const getActiveOption = useCallback(() => {
        switch (settings.ChartStyle) {
            case TChartStyle.cs_Bar:
                return 'candles'
            case TChartStyle.cs_Line:
                return 'lines'
            case TChartStyle.cs_Stick:
                return 'bars'
        }
    }, [settings])

    const handleChartModeChange = (mode: TChartStyle) => {
        setSettings((settings) => ({ ...settings, ChartStyle: mode }))
        updateChartSettings()
    }

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.chartMode', {
                value: t(`ribbon.chartModes.${getActiveOption()}`)
            })}
            renderer={(close) => (
                <RibbonDropdownMenu
                    activeOption={getActiveOption()}
                    close={close}
                    options={chartModeOptions}
                    name={t('ribbon.chartModes.chartMode')}
                />
            )}
        >
            <IconButton
                size={24}
                name='chart-mode'
                onClick={() => GlobalChartsController.Instance.disableMouseEvents()}
            />
        </RibbonDropdown>
    )
})

export default ChartModeDropdown
