import { t } from 'i18next'

import { DateUtils, TDateTime } from '../delphi_compatibility/DateUtils'
import { DelphiMathCompatibility } from '../delphi_compatibility/DelphiMathCompatibility'
import { TAccountInfo } from '../ft_types/common/BasicClasses/AccountInfo'
import {
    TCommApplyType,
    TOperationType,
    TTradePositionStatus,
    TTradePositionType,
    TTrailingStopType
} from '../ft_types/common/BasicClasses/BasicEnums'
import { TTradePosition } from '../ft_types/common/BasicClasses/TradePosition'
import { TOffsStringList, TVarList } from '../ft_types/common/OffsStringList'
import { StrsConv } from '../ft_types/common/StrsConv'
import { TSymbolCalcType } from '../ft_types/data/DataEnums'
import { TSymbolData } from '../ft_types/data/SymbolData'
import { TEquityArr } from '../ft_types/data/data_arrays/EquityArrays'
import GlobalUserJournal from '../globals/GlobalUserJournal'
import StatisticsStore from '../store/statistics'
import { UtilityFunctions } from '../utils/common_utils'
import { InsufficientMarginError, InvalidOrderTypeError, ProcessingCoreError } from './ProcessingCoreErrors'
import { TTradePos } from './TradePositionClasses/TradePosition'
import { TTradePositionsList } from './TradePositionClasses/TradePositionsList'
import { TTradeStatistics } from './TradeStatistics'
import { ObservableTemplateItem, ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import { EOperationType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { HistoryJSON } from '@fto/lib/ProjectAdapter/Types'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { TSymbolCalcParams } from '@fto/lib/processing_core/TradePositionClasses/SymbolCalcParams'
import { fireMixpanelEvent } from '@root/utils/api'
import { showErrorToast, showSuccessToast, showWarningToast } from '@root/utils/toasts'
import { Exception } from 'sass'
import { throttle } from 'lodash'
import { EducationProcessor } from '../Education/EducationProcessor'
import { DebugUtils } from '../utils/DebugUtils'
import StrangeError from '../common/common_errors/StrangeError'
import ProcessingCoreUtils from './ProcessingCoreUtils'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import { TTrailingStopInfo } from './TradePositionClasses/TrailingStopInfo'
import DataNotDownloadedYetError from '../ft_types/data/data_errors/DataUnavailableError'
import { TDataArrayEvents } from '../ft_types/data/data_downloading/DownloadRelatedEnums'
import GlobalChartsController from '../globals/GlobalChartsController'
import GlobalProcessingCore from '../globals/GlobalProcessingCore'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import CommonConstants from '../ft_types/common/CommonConstants'
import GlobalTestingManager from '../globals/GlobalTestingManager'

export enum ProcessingCoreEvent {
    UPDATE_HISTORY
}

export type PlacingOrderType = {
    SymbolName: string
    OperationType: TTradePositionType
    lot: number
    StopLoss: number
    TakeProfit: number
    price: number
    comment: string
    MagicNumber?: number
    AutoCloseTime?: TDateTime
    dataForStatistics?: {
        risk_amount: string
        risk_percentage: string
        tp_percentage: string
        sl_percentage: string
    } | null
    source?: string
}

export class TProcessingCore {
    private observableItem: ObservableTemplateItem<
        ProcessingCoreEvent,
        TProcessingCore,
        ObserverTemplate<ProcessingCoreEvent, TProcessingCore>
    >
    private fLastTicket!: number
    private fDeposit: number
    private fBalance: number
    private fMargin: number
    private fFreeMargin: number
    private fEquity: number
    private fMaxBalance: number
    private fMaxEquity: number
    private fMaxDrawdown: number
    private fCurrTime!: TDateTime
    private fHistory: TTradePositionsList
    private fOpenPositions: TTradePositionsList
    private fPendingFlag: boolean
    public EquityArr: TEquityArr // Replace with actual type
    public NeedHistoryUpdate!: boolean
    public LotDigits: number
    private prevState: {
        balance: number
        equity: number
        freeMargin: number
        margin: number
    } = {
        balance: 0,
        equity: 0,
        freeMargin: 0,
        margin: 0
    }

    private _throttledUpdateStatistics = throttle(() => {
        const updatedStatistics = this.GetStatistics()
        const { statistics, setStatistics } = StatisticsStore
        setStatistics({ ...statistics, ...updatedStatistics })
    }, 1000)

    constructor() {
        this.fDeposit = 0
        this.fBalance = 0
        this.fMargin = 0
        this.fFreeMargin = 0
        this.fEquity = 0
        this.fLastTicket = 0
        this.fMaxBalance = 0
        this.fMaxEquity = 0
        this.fMaxDrawdown = 0
        this.LotDigits = 2
        this.fPendingFlag = false

        this.fHistory = new TTradePositionsList()
        this.fOpenPositions = new TTradePositionsList()
        this.EquityArr = new TEquityArr()

        this.observableItem = new ObservableTemplateItem<
            ProcessingCoreEvent,
            TProcessingCore,
            ObserverTemplate<ProcessingCoreEvent, TProcessingCore>
        >()
    }

    public get HasOpenOrders(): boolean {
        return this.fOpenPositions.Count > 0
    }

    public attachObserver(observer: ObserverTemplate<ProcessingCoreEvent, TProcessingCore>): void {
        this.observableItem.attachObserver(observer)
    }

    public detachObserver(observer: ObserverTemplate<ProcessingCoreEvent, TProcessingCore>): void {
        this.observableItem.detachObserver(observer)
    }

    toJSON() {
        const list = new TOffsStringList()

        this.SaveToList(list)

        return list
    }

    fromJSON(jsonObj: TOffsStringList): void {
        this.LoadFromList(jsonObj)
    }

    public get Deposit(): number {
        return this.fDeposit
    }

    public set Deposit(value: number) {
        this.fDeposit = value
    }

    public get Balance(): number {
        return this.fBalance
    }

    public get Margin(): number {
        return this.fMargin
    }

    public get FreeMargin(): number {
        return this.fFreeMargin
    }

    public get Equity(): number {
        return this.fEquity
    }

    public get MaxDrawdown(): number {
        return this.fMaxDrawdown
    }

    public set MaxDrawdown(value: number) {
        this.fMaxDrawdown = value
    }

    public get MaxEquity(): number {
        return this.fMaxEquity
    }

    public set MaxEquity(value: number) {
        this.fMaxEquity = value
    }

    public get MaxBalance(): number {
        return this.fMaxBalance
    }

    public get CurrTime(): TDateTime {
        return this.fCurrTime
    }

    public set CurrTime(value: TDateTime) {
        this.fCurrTime = value
    }

    public get History(): TTradePositionsList {
        return this.fHistory
    }

    public get OpenPositions(): TTradePositionsList {
        return this.fOpenPositions
    }

    public get PendingFlag(): boolean {
        return this.fPendingFlag
    }

    public get Drawdown(): number {
        return this.GetDrawdown()
    }

    private GetDrawdown(): number {
        const result = this.fMaxBalance - this.fEquity

        if (result < 0) {
            return 0
        }

        return result
    }

    public rollBackToDate(timeToRollTo: TDateTime): void {
        try {
            // roll back opened positions - remove them if they were opened after timeToRollTo
            this.rollBackOpenPositions(timeToRollTo)

            // roll back history
            this.rollBackHistory(timeToRollTo)

            // check incorrect stop losses
            for (let openPosIndex = 0; openPosIndex < this.fOpenPositions.Count; openPosIndex++) {
                const currentPos = this.fOpenPositions.GetItem(openPosIndex)
                currentPos.UpdatePriceForOpenPosition()

                if (currentPos.tpos.StopLoss !== 0 && currentPos.tpos.ClosePrice === currentPos.tpos.StopLoss) {
                    currentPos.tpos.StopLoss = 0
                }

                if (currentPos.tpos.TakeProfit !== 0 && currentPos.tpos.ClosePrice === currentPos.tpos.TakeProfit) {
                    currentPos.tpos.TakeProfit = 0
                }
            }

            // update open positions
            this.fCurrTime = DateUtils.IncMilliSecond(
                timeToRollTo,
                -CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS
            )
            this.UpdateOpenPositions()
            this.RecountMargin()
            this.NeedHistoryUpdate = true

            // roll back balance/equity
            this.EquityArr.RollToDate(timeToRollTo) // not Implemented
            this.EquityArr.AddValue(this.fCurrTime, this.Equity, this.Balance, this.Margin, this.Drawdown)
            this.stopDeferralMechanism()
            this.updateEverything()
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                this.DeferRollBackToDate(timeToRollTo)
            } else {
                throw error
            }
        }
    }

    private updateEverything(forceRepaint = true): void {
        GlobalChartsController.Instance.RefreshCharts(true, forceRepaint, true)
        this.UpdateStatistics()
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }

    private stopDeferralMechanism(): void {
        if (this.deferredRollBackToDate) {
            this.deferredRollBackToDate = undefined
            if (this.deferralTimer) {
                clearInterval(this.deferralTimer)
            }
            GlobalSymbolList.SymbolList.Events.off(TDataArrayEvents.de_SeekCompleted, this.boundHandleSeekCompleted)
        }
    }

    private deferredRollBackToDate: TDateTime | undefined = undefined
    private deferralTimer: ReturnType<typeof setInterval> | undefined = undefined

    private DeferRollBackToDate(timeToRollTo: TDateTime): void {
        this.deferredRollBackToDate = timeToRollTo
        this.deferralTimer = setInterval(() => {
            this.doDeferredRollBackIfNecessary()
        }, 500)
    }

    private doDeferredRollBackIfNecessary() {
        if (this.deferredRollBackToDate) {
            this.rollBackToDate(this.deferredRollBackToDate)
        }
    }

    private boundHandleSeekCompleted = this.handleSeekCompleted.bind(this)

    private handleSeekCompleted(): void {
        this.doDeferredRollBackIfNecessary()
    }

    private rollBackHistory(timeToRollTo: TDateTime) {
        for (let historyIndex = this.fHistory.Count - 1; historyIndex >= 1; historyIndex--) {
            const tpos = this.fHistory.GetItem(historyIndex).tpos
            if (tpos.CloseTime < timeToRollTo) {
                break
            }

            // if pos was opened after time then delete it
            if (tpos.OpenTime >= timeToRollTo) {
                this.deleteHistoryRecord(tpos, historyIndex)
            }

            // if pos was opened before and closed after timeToRollTo then
            // move it to open positions
            if (tpos.OpenTime < timeToRollTo && DateUtils.MoreOrEqual(tpos.CloseTime, timeToRollTo)) {
                // extract from history and add to open positions
                this.revertHistoryPosToOpen(tpos, historyIndex)
            }
        }
    }

    private revertHistoryPosToOpen(tpos: TTradePosition, historyIndex: number) {
        this.fBalance -= tpos.profit
        const pos = this.fHistory.Extract(this.fHistory.GetItem(historyIndex)) as TTradePos
        pos.reset()
        this.fOpenPositions.Add(pos)

        // remove close commission if applied
        //FIXME: what if the commission type is TCommApplyType.ca_OpenClose? we won't remove this commission then
        if (pos.symbol && pos.symbol.symbolInfo.ComissionApplyType === TCommApplyType.ca_Close) {
            pos.RemoveCommission()
        }

        //FIXME: what about spread? we should add it back to the balance
    }

    private deleteHistoryRecord(tpos: TTradePosition, historyIndex: number) {
        this.fBalance -= tpos.profit
        const pos = this.fHistory.GetItem(historyIndex)
        pos.reset()
        this.fHistory.Delete(historyIndex)
    }

    private rollBackOpenPositions(timeToRollTo: number) {
        for (let openPosIndex = this.fOpenPositions.Count - 1; openPosIndex >= 0; openPosIndex--) {
            const openPos = this.fOpenPositions.GetItem(openPosIndex)
            if (openPos.tpos.OpenTime >= timeToRollTo) {
                openPos.reset()
                this.fOpenPositions.Delete(openPosIndex)
            }
        }
    }

    public SaveToList(list: TOffsStringList): void {
        const vars = new TVarList()
        vars.AddVarInt('LastTicket', this.fLastTicket)
        vars.AddVarDouble('Deposit', this.fDeposit, 2)
        vars.AddVarDouble('Balance', this.fBalance, 2)
        vars.AddVarDouble('Margin', this.fMargin, 2)
        vars.AddVarDouble('FreeMargin', this.fFreeMargin, 2)
        vars.AddVarDouble('Equity', this.fEquity, 2)
        vars.AddVarDouble('MaxBalance', this.fMaxBalance, 2)
        vars.AddVarDouble('MaxEquity', this.fMaxEquity, 2)
        vars.AddVarDouble('MaxDrawdown', this.fMaxDrawdown, 2)
        // vars.AddVarDateTime('CurrTime', this.fCurrTime)
        vars.AddVarInt('LotDigits', this.LotDigits)

        vars.SaveToList(list, 'Global')

        // list.OpenSection('History')
        // this.fHistory.SaveToList(list)
        // list.CloseSection()

        list.OpenSection('OpenPositions')
        this.fOpenPositions.SaveToList(list)
        list.CloseSection()
    }

    public LoadFromList(list: TOffsStringList): void {
        let list1: TOffsStringList
        let vars: TVarList
        try {
            list1 = new TOffsStringList()

            try {
                // load Global section
                list.GetSection('Global', list1)

                vars = new TVarList()
                try {
                    vars.LoadFromList(list1)
                    this.fLastTicket = vars.GetInt('LastTicket')
                    this.fDeposit = vars.GetDouble('Deposit')
                    this.fBalance = vars.GetDouble('Balance')
                    this.fMargin = vars.GetDouble('Margin')
                    this.fFreeMargin = vars.GetDouble('FreeMargin')
                    this.fEquity = vars.GetDouble('Equity')
                    this.fMaxBalance = vars.GetDouble('MaxBalance')
                    this.fMaxEquity = vars.GetDouble('MaxEquity')
                    this.fMaxDrawdown = vars.GetDouble('MaxDrawdown')
                    // this.fCurrTime = vars.GetDateTime('CurrTime')  // no need to load this, already set during project loading
                    this.LotDigits = vars.GetInt('LotDigits')
                } catch (error) {
                    DebugUtils.error(`[TProcessingCore.LoadFromList] Cannot load global section, reason: ${error}`)
                }

                // load history records
                // list.GetSection('History', list1)
                // DebugLog('Get section: ' + this.GetElapsedTimeStr());

                // GlobalPositionsHistory.History.LoadFromList(this.fSymbolList, list1)
                // this.debug('Load: ' + this.GetElapsedTimeStr());

                // load open positions
                list.GetSection('OpenPositions', list1)
                this.fOpenPositions.LoadFromList(GlobalSymbolList.SymbolList, list1)

                // update positions
                // this.RecountMargin()
                // this.UpdateOpenPositions()
                this.refreshOrdersInTerminalAndOrderModal()
            } catch (error) {
                DebugUtils.error(`[TProcessingCore.LoadFromList] Cannot load last state, reason: ${error}`)
            }
        } catch (error) {
            DebugUtils.error(`[TProcessingCore.LoadFromList] Cannot load last state, reason: ${error}`)
            throw error
        }
    }

    public loadHistoryFromJSON(history: HistoryJSON[]): void {
        // this.fHistory.Clear()
        for (const historyItem of history) {
            const pos = new TTradePos(
                GlobalSymbolList.SymbolList,
                historyItem.Ticket,
                historyItem.Symbol,
                DateUtils.fromUnixTimeMilliseconds(historyItem.OpenTime),
                historyItem.PosType,
                historyItem.Lot,
                historyItem.OpenPrice,
                historyItem.StopLoss,
                historyItem.TakeProfit,
                historyItem.Comments,
                0,
                0,
                true
            )
            pos.tpos.CloseTime = DateUtils.fromUnixTimeMilliseconds(historyItem.CloseTime)
            pos.tpos.ClosePrice = historyItem.ClosePrice
            pos.tpos.commission = historyItem.Commission
            pos.tpos.swap = historyItem.Swap
            pos.tpos.profit = historyItem.Profit
            pos.tpos.ProfitPips = historyItem.ProfitPips
            pos.tpos.margin = historyItem.Margin
            pos.tpos.orderStatus = this.restoreHistoryOrderStatus(historyItem.PosType)

            this.fHistory.Add(pos)
        }
    }

    public UpdateOpenPositions(): void {
        let totalProfitForProject = 0
        let pos: TTradePos
        let NeedToSort = false

        this.fPendingFlag = false

        // process market and pending orders
        //iterating backwards to avoid issues with removing items (like ProcessPendingOrder can remove the order)
        for (let i = this.fOpenPositions.Count - 1; i >= 0; i--) {
            pos = this.fOpenPositions.GetItem(i) // Accessing the position using a method to retrieve the position
            NeedToSort = this.updatePosition(pos) || NeedToSort
        }

        // calculate profit
        for (let i = 0; i < this.fOpenPositions.Count; i++) {
            totalProfitForProject += this.fOpenPositions.GetItem(i).tpos.profit // Accessing the profit using a method
        }

        // count equity and FreeMargin
        this.fEquity = this.fBalance + totalProfitForProject
        this.fFreeMargin = Math.max(0, this.fEquity - this.fMargin)

        // update max values
        this.fMaxBalance = Math.max(this.fBalance, this.fMaxBalance)
        this.fMaxEquity = Math.max(this.fEquity, this.fMaxEquity)
        this.fMaxDrawdown = Math.max(this.Drawdown, this.fMaxDrawdown)

        // check for margin call
        if (this.fEquity <= 0 && this.fOpenPositions.OpenMarketOrdersCount() > 0) {
            const marginCallTitle = t('processingCore.toasts.margin-call-title')
            const marginCallMessage = t('processingCore.toasts.margin-call-message')

            showErrorToast({ title: marginCallTitle, message: marginCallMessage })
            GlobalUserJournal.Instance.AddMessage(marginCallTitle)
            GlobalUserJournal.Instance.AddMessage(marginCallMessage)

            // close all positions
            this.closeAllMarketOrders()

            this.fMargin = 0
            this.fFreeMargin = 0
            this.NeedHistoryUpdate = true

            GlobalTestingManager.TestingManager.stopTesting()
            return
        }

        // this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this);

        // sort list of positions
        if (NeedToSort) {
            this.SortPositions()
        }
    }

    private updatePosition(pos: TTradePos): boolean {
        pos.UpdatePriceForOpenPosition()

        if (ProcessingCoreUtils.isPendingOrder(pos.PosType)) {
            return this.ProcessPendingOrder(pos)
        } else {
            return this.ProcessMarketOrder(pos) //this can cause removing the item from fOpenPositions
        }
    }

    private closeAllMarketOrders(): void {
        for (const pos of this.fOpenPositions) {
            if (ProcessingCoreUtils.isMarketOrder(pos.PosType)) {
                this.CloseOrder(pos.tpos.ticket, 0, false)
            }
        }
    }

    //TODO: notify about what?
    public refreshOrdersInTerminalAndOrderModal(): void {
        this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)
        //GlobalSymbolList.SymbolList.onTimezoneOrDSTChanged()
    }

    public UpdateLot_CheckLot(lot: number): number {
        lot = DelphiMathCompatibility.RoundTo(lot, -this.LotDigits)

        const minimumLotSize = 1 / Math.pow(10, this.LotDigits)

        if (lot < minimumLotSize) {
            const formattedErrorMessage = t('processingCore.toasts.invalid-lot-size', {
                lot: lot.toFixed(this.LotDigits),
                minimumLotSize: minimumLotSize.toFixed(this.LotDigits)
            })
            throw new ProcessingCoreError(formattedErrorMessage)
        }
        return lot
    }

    public UpdateSLandTP_CheckSLandTP(
        symbol: TSymbolData,
        PosType: TTradePositionType,
        price: number,
        StopLoss: number,
        TakeProfit: number
    ): [number, number] {
        const updatedStopLoss = symbol.RoundPrice(StopLoss)
        const updatedTakeProfit = symbol.RoundPrice(TakeProfit)
        this.CheckStopLoss(symbol, PosType, price, StopLoss)
        this.CheckTakeProfit(symbol, PosType, price, TakeProfit)
        return [updatedStopLoss, updatedTakeProfit]
    }

    public CheckStopLoss(symbol: TSymbolData, PosType: TTradePositionType, price: number, value: number): void {
        if (value !== 0) {
            if (Math.abs(value - price) < symbol.symbolInfo.MinDistToPrice * symbol.symbolInfo.MinPoint) {
                throw new ProcessingCoreError(`Minimum distance to price is ${symbol.symbolInfo.MinDistToPrice}`)
            }
            const decimals = symbol.symbolInfo.decimals || 5
            switch (PosType) {
                case TTradePositionType.tp_Buy:
                case TTradePositionType.tp_BuyLimit:
                case TTradePositionType.tp_BuyStop: {
                    if (value > price) {
                        throw new ProcessingCoreError(
                            `Stop loss value (${value.toFixed(
                                decimals
                            )}) for buy position must be less than the current price (${price.toFixed(decimals)}).`
                        )
                    }
                    break
                }
                default: {
                    if (value < price) {
                        throw new ProcessingCoreError(
                            `Stop loss value (${value.toFixed(
                                decimals
                            )}) for sell position must be greater than the current price (${price.toFixed(decimals)}).`
                        )
                    }
                    break
                }
            }
        }
    }

    public CheckTakeProfit(symbol: TSymbolData, PosType: TTradePositionType, price: number, value: number): void {
        if (value !== 0) {
            if (Math.abs(value - price) < symbol.symbolInfo.MinDistToPrice * symbol.symbolInfo.MinPoint) {
                throw new ProcessingCoreError(
                    `The distance to price must be at least ${symbol.symbolInfo.MinDistToPrice} points.`
                )
            }
            const decimals = symbol.symbolInfo.decimals || 5

            switch (PosType) {
                case TTradePositionType.tp_Buy:
                case TTradePositionType.tp_BuyLimit:
                case TTradePositionType.tp_BuyStop: {
                    if (value < price) {
                        throw new ProcessingCoreError(
                            `TakeProfit for a buy position (${value.toFixed(
                                decimals
                            )}) must be greater than the current price (${price.toFixed(decimals)}).`
                        )
                    }
                    break
                }
                default: {
                    if (value > price) {
                        throw new ProcessingCoreError(
                            'TakeProfit for a sell position must be less than the current price.'
                        )
                    }
                    break
                }
            }
        }
    }

    public FindOrder(ticket: number): number {
        for (let i = 0; i < this.fOpenPositions.Count; i++) {
            const pos = this.fOpenPositions.GetItem(i)
            if (pos.tpos.ticket === ticket) {
                return i
            }
        }
        return -1
    }

    FindHistoryOrder(ticket: number): number {
        let result = -1
        for (let i = 0; i < this.fHistory.Count; i++) {
            const pos = this.fHistory.GetItem(i)
            if (pos.tpos.ticket === ticket) {
                result = i
                break
            }
        }

        return result
    }

    public SortPositions(): void {
        for (let i = 0; i < this.fOpenPositions.Count - 1; i++) {
            let pos1 = this.fOpenPositions.GetItem(i)
            for (let j = i + 1; j < this.fOpenPositions.Count; j++) {
                const pos2 = this.fOpenPositions.GetItem(j)
                if (pos2.tpos.OpenTime < pos1.tpos.OpenTime) {
                    this.fOpenPositions.Exchange(i, j)
                    pos1 = pos2
                }
            }
        }
    }

    public GetPosInfo(SymbolName: string, PosType: string, lot: number, price: number, sl: number, tp: number): string {
        const symbol: TSymbolData | null = GlobalSymbolList.SymbolList.GetOrCreateSymbol(SymbolName, true)
        const digits: number = symbol ? symbol.symbolInfo.decimals : 5

        return `${SymbolName}, ${PosType}, ${StrsConv.StrDouble(lot, this.LotDigits)}, ${StrsConv.StrDouble(
            price,
            digits
        )}, ${StrsConv.StrDouble(sl, digits)}, ${StrsConv.StrDouble(tp, digits)}`
    }

    public Reset(): void {
        this.fDeposit = 0
        this.fBalance = 0
        this.fMargin = 0
        this.fFreeMargin = 0
        this.fEquity = 0
        this.fLastTicket = 0
        this.fMaxBalance = 0
        this.fMaxEquity = 0
        this.fMaxDrawdown = 0
        this.LotDigits = 2

        this.fHistory.Clear()
        this.fOpenPositions.Clear()
        this.EquityArr.Clear()
        this.resetOrderLevels()
    }

    public InitialDeposit(DateTime: TDateTime, amount: number): void {
        this.CurrTime = DateTime
        this.DepositMoney(amount, t('processingCore.initialDeposit'))
        this.Deposit = amount
    }

    public DeleteOrder(OrderHandle: number): void {
        try {
            // find order
            const index = this.FindOrder(OrderHandle)
            this.validateOrderIndex(index, OrderHandle)

            const pos = this.fOpenPositions.GetItem(index)

            if (!ProcessingCoreUtils.isPendingOrder(pos.PosType)) {
                throw new ProcessingCoreError(
                    t('processingCore.errors.invalidOrderTypeForDeletion', {
                        orderType: ProcessingCoreUtils.StrPosType(pos.PosType)
                    })
                )
            }

            // delete order and add it to history
            this.fOpenPositions.Extract(pos)
            this.fHistory.Add(pos)
            this.UpdateOpenPositions()

            GlobalUserJournal.Instance.AddMessage(
                t('processingCore.messages.orderDeleted', { orderHandle: OrderHandle, posInfo: pos.GetPosInfo() })
            )
        } catch (error) {
            GlobalUserJournal.Instance.AddMessage(
                t('processingCore.errors.errorDeletingOrder', { orderHandle: OrderHandle, error })
            )
            throw error
        }
    }

    public CancelOrder(OrderHandle: number): void {
        try {
            // find order
            const index = this.FindOrder(OrderHandle)
            this.validateOrderIndex(index, OrderHandle)

            const pos = this.fOpenPositions.GetItem(index)

            if (!ProcessingCoreUtils.isPendingOrder(pos.PosType)) {
                throw new ProcessingCoreError('Invalid order type for deletion.')
            }

            pos.tpos.orderStatus = TTradePositionStatus.tps_Cancelled
            pos.reset()

            // delete order and add it to history
            this.fOpenPositions.Extract(pos)
            this.fHistory.Add(pos)
            this.UpdateOpenPositions()

            const message = t('processingCore.messages.orderCancelled', {
                orderHandle: OrderHandle,
                posInfo: pos.GetPosInfo()
            })

            showSuccessToast({
                title: t('orders.modal.toasts.pendingCancelled', {
                    type: this.convertOperationTypeToStr(pos.PosType)
                }),
                message: message
            })

            GlobalUserJournal.Instance.AddMessage(message)
        } catch (error) {
            const errMsg = (error as Error).message
            const errorMessage = t('processingCore.errors.errorDeletingOrder', {
                orderHandle: OrderHandle,
                error: errMsg
            })
            showErrorToast({ title: t('processingCore.toasts.error'), message: errorMessage })
            GlobalUserJournal.Instance.AddMessage(errorMessage)
            throw error
        }
    }

    public ProcessPendingOrder(pos: TTradePos): boolean {
        // const pos = this.fOpenPositions.GetItem(index)
        const tpos = pos.tpos
        const originalPosType = tpos.PosType
        let NeedToSort = false
        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

        // check if order was executed
        if (
            ((tpos.PosType === TTradePositionType.tp_BuyLimit || tpos.PosType === TTradePositionType.tp_SellStop) &&
                tpos.ClosePrice <= tpos.OpenPrice) ||
            ((tpos.PosType === TTradePositionType.tp_SellLimit || tpos.PosType === TTradePositionType.tp_BuyStop) &&
                tpos.ClosePrice >= tpos.OpenPrice)
        ) {
            const posInfoStr = pos.GetPosInfo()

            // try to open position
            pos.SwitchPositionToOpen(pos.symbol.LastProcessedTickTime)
            pos.reset()
            this.RecountMargin()

            if (this.fFreeMargin <= 0) {
                // insufficient margin
                const message = t('processingCore.errors.insufficientMarginForPendingOrder', {
                    ticket: tpos.ticket,
                    posInfo: posInfoStr
                })
                GlobalUserJournal.Instance.AddMessage(message)
                showWarningToast({ title: t('processingCore.errors.insufficient-margin'), message: message })
                this.deleteOpenPositionByTicket(pos.tpos.ticket)
                this.RecountMargin()
            } else {
                // order was executed
                if (
                    pos.symbol.symbolInfo.ComissionApplyType === TCommApplyType.ca_Open ||
                    pos.symbol.symbolInfo.ComissionApplyType === TCommApplyType.ca_OpenClose
                ) {
                    pos.ApplyCommission()
                }
                showSuccessToast({
                    title: t('orders.modal.toasts.pendingExecuted', {
                        type: this.convertOperationTypeToStr(originalPosType)
                    }),
                    message: t('orders.modal.toasts.pendingExecutedText', {
                        type: this.convertOperationTypeToStr(originalPosType),
                        lot: tpos.lot,
                        symbol: tpos.SymbolName,
                        price: tpos.OpenPrice.toFixed(pos.symbol.symbolInfo.decimals)
                    })
                })
                pos.reset()
                NeedToSort = true
                this.fPendingFlag = true
                GlobalUserJournal.Instance.AddMessage(
                    `Order executed for ticket ${tpos.ticket}, ${posInfoStr}, at price ${pos.symbol.FormatPriceToStr(
                        tpos.ClosePrice
                    )}`
                )
            }
        }
        return NeedToSort
    }

    private deleteOpenPositionByTicket(ticket: number) {
        const index = this.FindOrder(ticket)
        this.fOpenPositions.Delete(index)
    }

    private ProcessMarketOrder(pos: TTradePos): boolean {
        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

        // update swaps
        pos.UpdateSwaps(pos.symbol.LastProcessedTickTime)

        // update profit
        pos.UpdateProfit()

        pos.tpos.CloseTime = pos.symbol.LastProcessedTickTime

        if (pos.StopLossHit || pos.TakeProfitHit) {
            // order is closed by stop loss or take profit
            this.closePositionBySLTP(pos)
            return true
        }

        // process trailing stop
        if (pos.tstop.enabled) {
            this.ProcessTrailingStop(pos)
        }

        if (pos.PendingPartialCloseHit) {
            this.CloseOrder(pos.tpos.ticket, pos.tpos.pendingPartialCloseLotValue)
        }

        return false
    }

    private closePositionBySLTP(pos: TTradePos) {
        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

        if (pos.StopLossHit) {
            GlobalUserJournal.Instance.AddMessage(
                t('processingCore.eventMessages.order-closed-by-SL', {
                    orderNumber: pos.tpos.ticket,
                    posInfo: pos.GetPosInfo(),
                    formattedPrice: pos.symbol.FormatPriceToStr(pos.tpos.ClosePrice),
                    count: Math.round(pos.tpos.ProfitPips),
                    profitUSD: pos.tpos.profit.toFixed(2)
                })
            )
        }

        if (pos.TakeProfitHit) {
            const formattedMessage = t('processingCore.eventMessages.order-closed-by-TP', {
                orderNumber: pos.tpos.ticket,
                posInfo: pos.GetPosInfo(),
                formattedPrice: pos.symbol.FormatPriceToStr(pos.tpos.ClosePrice),
                count: Math.round(pos.tpos.ProfitPips), // Handles pluralization
                profitUSD: pos.tpos.profit.toFixed(2)
            })

            GlobalUserJournal.Instance.AddMessage(formattedMessage)
        }

        // apply commission if needed
        if (
            pos.symbol.symbolInfo.ComissionApplyType === TCommApplyType.ca_Open ||
            pos.symbol.symbolInfo.ComissionApplyType === TCommApplyType.ca_OpenClose
        ) {
            pos.ApplyCommission()
            //TODO: don't we do UpdateProfit twice here? may this be a performance issue?
            pos.UpdateProfit()
        }

        pos.tpos.orderStatus = TTradePositionStatus.tps_Filled
        // extract from orders and add to history
        this.fOpenPositions.Extract(pos)
        this.fHistory.Add(pos)
        this.RecountMargin()

        pos.reset()

        this.fBalance += pos.tpos.profit
        this.NeedHistoryUpdate = true
        this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)

        fireMixpanelEvent('order_closed', {
            pair: pos.tpos.SymbolName,
            type: this.convertOperationTypeToStr(pos.tpos.PosType),
            side: this.getSideByOperationType(pos.tpos.PosType),
            lot: pos.tpos.lot,
            price: pos.tpos.ClosePrice,
            points: pos.tpos.ProfitPips,
            amount: pos.tpos.profit,
            percentage_tp: Number(pos.AdditionalInfoForStatistics.tp_percentage),
            percentage_sl: Number(pos.AdditionalInfoForStatistics.sl_percentage),
            message: pos.tpos.Comments,
            PNL: (pos.tpos.profit / pos.tpos.margin) * 100,
            old_deposit: this.Balance - pos.tpos.profit,
            new_deposit: this.Balance,
            margin: pos.tpos.margin,
            tp_hit: pos.TakeProfitHit,
            sl_hit: pos.StopLossHit
        })
    }

    public MoveStopLossToSafe(orderHandle: number): void {
        const index = this.FindOrder(orderHandle)

        this.validateOrderIndex(index, orderHandle)

        const pos = this.fOpenPositions.GetItem(index)

        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

        try {
            this.ModifyOrder(
                orderHandle,
                pos.tpos.lot,
                pos.tpos.OpenPrice,
                pos.tpos.OpenPrice,
                pos.tpos.TakeProfit,
                pos.tpos.Comments
            )
            this.RecountMargin()
            this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)
            showSuccessToast({
                message: t('processingCore.toasts.stopLossMovedToSafe'),
                title: t('processingCore.toasts.success')
            })
        } catch (error) {
            showErrorToast({
                message: (error as Exception).message,
                title: t('processingCore.toasts.error')
            })
        }
    }

    convertTradePositionToOperationType(posType: TTradePositionType): EOperationType {
        switch (posType) {
            case TTradePositionType.tp_Buy: {
                return EOperationType.BUY
            }
            case TTradePositionType.tp_Sell: {
                return EOperationType.SELL
            }
            case TTradePositionType.tp_BuyLimit: {
                return EOperationType.BUY
            }
            case TTradePositionType.tp_SellLimit: {
                return EOperationType.SELL
            }
            case TTradePositionType.tp_BuyStop: {
                return EOperationType.BUY
            }
            case TTradePositionType.tp_SellStop: {
                return EOperationType.SELL
            }
            default: {
                throw new InvalidOrderTypeError('Invalid order type')
            }
        }
    }

    public ProcessTrailingStop(pos: TTradePos): void {
        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

        let sl = 0
        const tpos = pos.tpos
        const tstop = pos.tstop
        const point = pos.symbol.symbolInfo.MinPoint

        if (tstop.tsType === TTrailingStopType.ts_OnProfit && tpos.ProfitPips < tstop.AtProfit) {
            return
        }

        if (tpos.StopLoss === 0) {
            // first set of stop loss
            switch (tpos.PosType) {
                case TTradePositionType.tp_Buy: {
                    sl = tpos.ClosePrice - tstop.StopPoints * point
                    break
                }
                case TTradePositionType.tp_Sell: {
                    sl = tpos.ClosePrice + tstop.StopPoints * point
                    break
                }
                default:
                //do nothing, we only need to process market orders here
            }
        } else {
            // moving stop loss
            let x: number, xx: number
            if (tpos.PosType === TTradePositionType.tp_Buy) {
                x = Math.round((tpos.ClosePrice - tpos.OpenPrice) / point) - tstop.StopPoints
                xx = Math.floor(x / tstop.StepPoints)
                if (x < 0 && x % tstop.StepPoints !== 0) {
                    xx--
                }
                sl = tpos.OpenPrice + xx * tstop.StepPoints * point
            } else {
                x = Math.round((tpos.OpenPrice - tpos.ClosePrice) / point) - tstop.StopPoints
                xx = Math.floor(x / tstop.StepPoints)
                if (x < 0 && x % tstop.StepPoints !== 0) {
                    xx--
                }
                sl = tpos.OpenPrice - xx * tstop.StepPoints * point
            }
        }

        if (sl === 0) {
            return
        }

        sl = DelphiMathCompatibility.RoundTo(sl, -pos.symbol.symbolInfo.decimals)

        if (
            tpos.StopLoss !== 0 &&
            ((tpos.PosType === TTradePositionType.tp_Buy && sl <= tpos.StopLoss) ||
                (tpos.PosType === TTradePositionType.tp_Sell && sl >= tpos.StopLoss))
        ) {
            return
        }

        try {
            // Assuming CheckStopLoss and AddMessage methods are implemented elsewhere
            this.CheckStopLoss(pos.symbol, tpos.PosType, tpos.ClosePrice, sl)
            GlobalUserJournal.Instance.AddMessage(
                t('processingCore.messages.trailingStopAdjusted', { ticket: tpos.ticket })
            )
            tpos.StopLoss = sl
        } catch (error) {
            const errorMessage = t('processingCore.errors.trailingStopAdjustmentError', { ticket: tpos.ticket, error })
            showErrorToast({ title: t('processingCore.toasts.error'), message: errorMessage })
            GlobalUserJournal.Instance.AddMessage(errorMessage)
            tstop.enabled = false
        }
    }

    //TODO: after the conversion, remove price from the parameters (it was used in delphi code as var parameter, but it's not used in the typescript in the same way)
    public SendMarketOrder(params: PlacingOrderType, showToasts = true): [number, number] {
        const {
            SymbolName,
            OperationType,
            lot,
            StopLoss,
            TakeProfit,
            price,
            comment = '',
            MagicNumber = 0,
            AutoCloseTime = 0,
            dataForStatistics = null,
            source
        } = params

        let priceValue = price
        let lotValue = lot
        let symbolNameValue = SymbolName

        //TODO: do not change incoming parameters, but use a local variable for price
        try {
            priceValue = 0
            symbolNameValue = SymbolName.toUpperCase()

            const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(SymbolName)

            this.throwErrorIfSymbolDataIsInvalid(symbolData)

            lotValue = this.UpdateLot_CheckLot(lot)

            let price_t: number
            let PosType: TTradePositionType
            if (OperationType === TTradePositionType.tp_Buy) {
                priceValue = symbolData.ask
                price_t = symbolData.bid // The price_t variable is used to store the opposite price for SL/TP calculation
                PosType = TTradePositionType.tp_Buy
            } else {
                priceValue = symbolData.bid
                price_t = symbolData.ask // The price_t variable is used to store the opposite price for SL/TP calculation
                PosType = TTradePositionType.tp_Sell
            }

            const [updatedStopLoss, updatedTakeProfit] = this.UpdateSLandTP_CheckSLandTP(
                symbolData,
                PosType,
                price_t,
                StopLoss,
                TakeProfit
            ) // price_t is used here for SL/TP calculation

            const ticket = this.GetTicket()
            const pos = new TTradePos(
                GlobalSymbolList.SymbolList,
                ticket,
                symbolNameValue,
                symbolData.LastProcessedTickTime,
                PosType,
                lotValue,
                priceValue,
                updatedStopLoss,
                updatedTakeProfit,
                comment,
                MagicNumber,
                AutoCloseTime
            )

            if (
                symbolData.symbolInfo.ComissionApplyType === TCommApplyType.ca_Open ||
                symbolData.symbolInfo.ComissionApplyType === TCommApplyType.ca_OpenClose
            ) {
                pos.ApplyCommission()
            }

            const index = this.fOpenPositions.AddAndGetIndex(pos)

            this.RecountMargin()
            if (this.fFreeMargin <= 0) {
                this.fOpenPositions.Delete(index)
                this.RecountMargin()
                this.fLastTicket--
                throw new InsufficientMarginError(t('processingCore.toasts.insufficientMargin'))
            }

            this.UpdateOpenPositions()
            this.UpdateEquity()
            this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)

            pos.AdditionalInfoForStatistics = dataForStatistics!

            fireMixpanelEvent('order_opened', {
                pair: symbolNameValue,
                type: this.convertOperationTypeToStr(OperationType),
                side: this.getSideByOperationType(OperationType),
                lot: lotValue,
                risk_amount: Number(dataForStatistics?.risk_amount) || 0,
                risk_percentage: Number(dataForStatistics?.risk_percentage) || 0,
                price: priceValue,
                points: pos.tpos.ProfitPips,
                amount: pos.tpos.profit,
                percentage_tp: Number(dataForStatistics?.tp_percentage) || 0,
                percentage_sl: Number(dataForStatistics?.sl_percentage) || 0,
                message: comment,
                tp: updatedTakeProfit === 0 ? 'yes' : 'no',
                sl: updatedStopLoss === 0 ? 'yes' : 'no',
                deposit: this.Deposit,
                margin: pos.tpos.margin,
                source: source
            })

            const message = t('processingCore.messages.orderExecuted', {
                ticket: ticket,
                posInfo: pos.GetPosInfo(),
                price: symbolData.FormatPriceToStr(priceValue)
            })

            if (showToasts) {
                showSuccessToast({
                    title: t('orders.modal.toasts.marketPlaced', {
                        type: this.convertOperationTypeToStr(OperationType)
                    }),
                    message: message
                })
            }

            // Logging the operation
            GlobalUserJournal.Instance.AddMessage(message)

            return [ticket, price]
        } catch (error) {
            // eslint-disable-next-line unicorn/throw-new-error
            const errorMessage = UtilityFunctions.GetMessageFromError(error)
            const reason = t('processingCore.reason', { reason: errorMessage })
            const formattedErrorMessage = t('processingCore.toasts.cannot-place-market-order', {
                posInfo: this.GetPosInfo(
                    SymbolName,
                    ProcessingCoreUtils.StrPosType(OperationType),
                    lotValue,
                    price,
                    StopLoss,
                    TakeProfit
                ),
                reason: reason
            })

            GlobalUserJournal.Instance.AddMessage(formattedErrorMessage)
            showErrorToast({ title: t('processingCore.toasts.error'), message: formattedErrorMessage })

            throw error
        }
    }

    private throwErrorIfSymbolDataIsInvalid(symbolData: TSymbolData) {
        if (symbolData.bid === 0 || symbolData.ask === 0) {
            const message = t('processingCore.errors.noBidAskPrices', { symbolName: symbolData.symbolInfo.SymbolName })
            throw new ProcessingCoreError(message)
        }

        const currentTestingDate = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)

        if (symbolData.VeryFirstDateInHistory > currentTestingDate) {
            const message = t('processingCore.errors.noHistoricalData', {
                symbolName: symbolData.symbolInfo.SymbolName,
                currentDate: DateUtils.DF(currentTestingDate),
                firstAvailableDate: DateUtils.DF(symbolData.VeryFirstDateInHistory)
            })
            throw new ProcessingCoreError(message)
        }
    }

    //do NOT translate
    public convertOperationTypeToStr(operationType: TTradePositionType): string {
        switch (operationType) {
            case TTradePositionType.tp_Buy: {
                return 'Buy'
            }
            case TTradePositionType.tp_Sell: {
                return 'Sell'
            }
            case TTradePositionType.tp_BuyLimit: {
                return 'Buy Limit'
            }
            case TTradePositionType.tp_SellLimit: {
                return 'Sell Limit'
            }
            case TTradePositionType.tp_BuyStop: {
                return 'Buy Stop'
            }
            case TTradePositionType.tp_SellStop: {
                return 'Sell Stop'
            }
            case TTradePositionType.tp_Deposit: {
                return 'Deposit'
            }
            case TTradePositionType.tp_Withdrawal: {
                return 'Withdrawal'
            }
            case TTradePositionType.tp_Credit: {
                return 'Credit'
            }
            default: {
                return 'Unknown'
            }
        }
    }

    public getSideByOperationType(operationType: TTradePositionType): string {
        switch (operationType) {
            case TTradePositionType.tp_Buy: {
                return 'long'
            }
            case TTradePositionType.tp_Sell: {
                return 'short'
            }
            case TTradePositionType.tp_BuyLimit: {
                return 'long'
            }
            case TTradePositionType.tp_SellLimit: {
                return 'short'
            }
            case TTradePositionType.tp_BuyStop: {
                return 'long'
            }
            case TTradePositionType.tp_SellStop: {
                return 'short'
            }
            default: {
                return 'Unknown'
            }
        }
    }

    public SendPendingOrder(
        SymbolName: string,
        OperationType: TTradePositionType,
        lot: number,
        StopLoss: number,
        TakeProfit: number,
        ExecutionPrice: number,
        comment = '',
        MagicNumber = 0,
        AutoCloseTime: TDateTime = 0,
        dataForStatistics: {
            risk_amount: string
            risk_percentage: string
            tp_percentage: string
            sl_percentage: string
        } | null = null
    ): number {
        try {
            if (!ProcessingCoreUtils.isPendingOrder(OperationType)) {
                throw new InvalidOrderTypeError('Invalid operation type for pending order.')
            }

            SymbolName = SymbolName.toUpperCase()
            const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(SymbolName)

            this.throwErrorIfSymbolDataIsInvalid(symbolData)

            this.UpdateLot_CheckLot(lot)
            ExecutionPrice = symbolData.RoundPrice(ExecutionPrice)

            const MinDist = symbolData.symbolInfo.MinDistToPrice * symbolData.symbolInfo.MinPoint

            // Check execution price and order type
            if (
                ((OperationType === TTradePositionType.tp_BuyLimit ||
                    OperationType === TTradePositionType.tp_BuyStop) &&
                    Math.abs(symbolData.ask - ExecutionPrice) < MinDist) ||
                ((OperationType === TTradePositionType.tp_SellLimit ||
                    OperationType === TTradePositionType.tp_SellStop) &&
                    Math.abs(symbolData.bid - ExecutionPrice) < MinDist)
            ) {
                throw new ProcessingCoreError(t('processingCore.errors.executionPriceTooClose'))
            }

            if (
                (OperationType === TTradePositionType.tp_BuyLimit && ExecutionPrice > symbolData.ask) ||
                (OperationType === TTradePositionType.tp_BuyStop && ExecutionPrice < symbolData.ask) ||
                (OperationType === TTradePositionType.tp_SellLimit && ExecutionPrice < symbolData.bid) ||
                (OperationType === TTradePositionType.tp_SellStop && ExecutionPrice > symbolData.bid)
            ) {
                const executionPriceStr = symbolData.FormatPriceToStr(ExecutionPrice)

                const message = t('processingCore.errors.invalidExecutionPrice', {
                    executionPrice: executionPriceStr,
                    orderType: ProcessingCoreUtils.StrPosType(OperationType)
                })
                showWarningToast({ title: t('processingCore.toasts.error'), message: message })
                throw new ProcessingCoreError(message)
            }

            this.UpdateSLandTP_CheckSLandTP(symbolData, OperationType, ExecutionPrice, StopLoss, TakeProfit)

            const ticket = this.GetTicket()
            const pos = new TTradePos(
                GlobalSymbolList.SymbolList,
                ticket,
                SymbolName,
                symbolData.LastProcessedTickTime,
                OperationType,
                lot,
                ExecutionPrice,
                StopLoss,
                TakeProfit,
                comment,
                MagicNumber
            )

            this.fOpenPositions.Add(pos)
            this.UpdateOpenPositions()
            this.refreshOrdersInTerminalAndOrderModal()

            pos.AdditionalInfoForStatistics = dataForStatistics!

            fireMixpanelEvent('pending_order_opened', {
                pair: SymbolName,
                type: this.convertOperationTypeToStr(OperationType),
                side: this.getSideByOperationType(OperationType),
                lot: lot,
                risk_amount: Number(dataForStatistics?.risk_amount) || 0,
                risk_percentage: Number(dataForStatistics?.risk_percentage) || 0,
                price: ExecutionPrice,
                points: pos.tpos.ProfitPips,
                amount: pos.tpos.profit,
                percentage_tp: Number(dataForStatistics?.tp_percentage) || 0,
                percentage_sl: Number(dataForStatistics?.sl_percentage) || 0,
                message: comment,
                tp: TakeProfit === 0 ? 'no' : 'yes',
                sl: StopLoss === 0 ? 'no' : 'yes',
                deposit: this.Deposit,
                margin: pos.tpos.margin
            })

            const message = t('processingCore.messages.orderPlaced', {
                posInfo: pos.GetPosInfo(),
                executionPrice: symbolData.FormatPriceToStr(ExecutionPrice)
            })
            GlobalUserJournal.Instance.AddMessage(message)
            showSuccessToast({
                title: t('orders.modal.toasts.pendingPlaced', {
                    type: this.convertOperationTypeToStr(OperationType)
                }),
                message: message
            })

            return ticket
        } catch (error) {
            // eslint-disable-next-line unicorn/throw-new-error
            const errorMessage = UtilityFunctions.GetMessageFromError(error)
            const reason = t('processingCore.reason', { reason: errorMessage })

            const formattedErrorMessage = t('processingCore.toasts.cannot-place-pending-order', {
                posInfo: this.GetPosInfo(
                    SymbolName,
                    ProcessingCoreUtils.StrPosType(OperationType),
                    lot,
                    ExecutionPrice,
                    StopLoss,
                    TakeProfit
                ),
                reason: reason
            })

            showErrorToast({ title: t('processingCore.toasts.error'), message: formattedErrorMessage })

            GlobalUserJournal.Instance.AddMessage(formattedErrorMessage)
            GlobalUserJournal.Instance.AddMessage(reason)

            throw error
        }
    }

    public reversePositionMarket(ticket: number): void {
        // Find the order by the given ticket number
        const index = this.FindOrder(ticket)
        this.validateOrderIndex(index, ticket)

        // Get the order
        const pos = this.fOpenPositions.GetItem(index)
        const comment = pos.tpos.Comments

        // Close the existing order at the current market price
        this.CloseOrder(ticket, 0, false)

        // Determine the opposite direction of the order
        let oppositeDirection: TTradePositionType

        if (pos.tpos.PosType === TTradePositionType.tp_Buy) {
            oppositeDirection = TTradePositionType.tp_Sell
        } else if (pos.tpos.PosType === TTradePositionType.tp_Sell) {
            oppositeDirection = TTradePositionType.tp_Buy
        } else {
            throw new ProcessingCoreError('Invalid order type for reversal.')
        }

        // Open a new order with the opposite direction at the current market price,
        // and with the switched Stop Loss and Take Profit values

        try {
            const [newTicket, newPrice] = this.SendMarketOrder(
                {
                    SymbolName: pos.tpos.SymbolName,
                    OperationType: oppositeDirection,
                    lot: pos.tpos.lot,
                    StopLoss: 0,
                    TakeProfit: 0,
                    price: 0,
                    comment
                },
                false
            )

            const decimals = pos.symbol ? pos.symbol.symbolInfo.decimals : 5

            showSuccessToast({
                title: t('processingCore.toasts.positionReversed'),
                message: t('processingCore.toasts.newPositionOpenedAtPrice', {
                    newTicket: newTicket,
                    newPrice: newPrice.toFixed(decimals)
                })
            })

            this.RecountMargin()
            this.UpdateOpenPositions()

            this.UpdateEquity()
            this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)
            this.UpdateStatistics()
        } catch (error) {
            if (error instanceof InsufficientMarginError) {
                this.RestoreOrder(ticket)
                showErrorToast({
                    title: t('processingCore.toasts.insufficientMargin'),
                    message: t('processingCore.toasts.insufficientMarginErrorMessage')
                })
            }
            throw error
        }
    }

    private validateOrderIndex(index: number, orderHandle: number) {
        if (index === -1) {
            throw new ProcessingCoreError(t('processingCore.errors.orderNotFound', { orderHandle: orderHandle }))
        }
    }

    public ModifyOrder(
        OrderHandle: number,
        lot: number,
        NewPrice: number,
        StopLoss: number,
        TakeProfit: number,
        comment: string,
        dataForStatistics: {
            tp_points: string
            tp_amount: string
            tp_percent: string
            sl_points: string
            sl_amount: string
            sl_percent: string
            risk_amount: string
            risk_percentage: string
        } | null = null
    ): void {
        try {
            const index = this.FindOrder(OrderHandle)
            this.validateOrderIndex(index, OrderHandle)

            const pos = this.fOpenPositions.GetItem(index)

            if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

            const symbol = pos.symbol
            const originalPosInfoStr = pos.GetPosInfo()

            if (ProcessingCoreUtils.isMarketOrder(pos.tpos.PosType)) {
                const [stopLoss, takeProfit] = this.UpdateSLandTP_CheckSLandTP(
                    symbol,
                    pos.tpos.PosType,
                    pos.tpos.ClosePrice,
                    StopLoss,
                    TakeProfit
                )
                pos.tpos.StopLoss = stopLoss
                pos.tpos.TakeProfit = takeProfit
                pos.tpos.Comments = comment
            } else {
                NewPrice = symbol.RoundPrice(NewPrice)
                const MinDist = symbol.symbolInfo.MinDistToPrice * symbol.symbolInfo.MinPoint
                const Lot = this.UpdateLot_CheckLot(lot)

                if (
                    ([TTradePositionType.tp_BuyLimit, TTradePositionType.tp_BuyStop].includes(pos.tpos.PosType) &&
                        Math.abs(symbol.ask - NewPrice) < MinDist) ||
                    ([TTradePositionType.tp_SellLimit, TTradePositionType.tp_SellStop].includes(pos.tpos.PosType) &&
                        Math.abs(symbol.bid - NewPrice) < MinDist)
                ) {
                    throw new StrangeError('New price too close to market price.')
                }

                if (
                    (pos.tpos.PosType === TTradePositionType.tp_BuyLimit && NewPrice > symbol.ask) ||
                    (pos.tpos.PosType === TTradePositionType.tp_BuyStop && NewPrice < symbol.ask) ||
                    (pos.tpos.PosType === TTradePositionType.tp_SellLimit && NewPrice < symbol.bid) ||
                    (pos.tpos.PosType === TTradePositionType.tp_SellStop && NewPrice > symbol.bid)
                ) {
                    throw new StrangeError('Invalid new price for order type.')
                }

                const [stopLoss, takeProfit] = this.UpdateSLandTP_CheckSLandTP(
                    symbol,
                    pos.tpos.PosType,
                    NewPrice,
                    StopLoss,
                    TakeProfit
                )

                pos.tpos.lot = Lot
                pos.tpos.OpenPrice = NewPrice
                pos.tpos.StopLoss = stopLoss
                pos.tpos.TakeProfit = takeProfit
                pos.tpos.Comments = comment
            }
            this.UpdateOpenPositions()
            const message = t('processingCore.messages.orderModified', {
                orderHandle: OrderHandle,
                originalPosInfo: originalPosInfoStr,
                newPosInfo: pos.GetPosInfo()
            })
            GlobalUserJournal.Instance.AddMessage(message)

            pos.AdditionalInfoForStatistics = {
                risk_amount: dataForStatistics?.risk_amount || '0',
                risk_percentage: dataForStatistics?.risk_percentage || '0',
                tp_percentage: dataForStatistics?.tp_points || '0',
                sl_percentage: dataForStatistics?.tp_amount || '0'
            }

            fireMixpanelEvent('order_modified', {
                tp_set: TakeProfit === 0 ? 'no' : 'yes',
                sl_set: StopLoss === 0 ? 'no' : 'yes',
                tp_price: TakeProfit,
                sl_price: StopLoss,
                tp_points: dataForStatistics?.tp_points || 0,
                tp_amount: dataForStatistics?.tp_amount || 0,
                tp_percentage: dataForStatistics?.tp_percent || 0,
                sl_points: dataForStatistics?.sl_points || 0,
                sl_amount: dataForStatistics?.sl_amount || 0,
                sl_percentage: dataForStatistics?.sl_percent || 0
            })

            showSuccessToast({
                title: t('orders.modal.toasts.orderUpdated'),
                message: t('orders.modal.toasts.orderUpdatedText')
            })

            pos.onEditFinished()
        } catch (error) {
            const formattedMessage = t('processingCore.toasts.cannot-modify-order', {
                orderHandle: OrderHandle
            })

            // eslint-disable-next-line unicorn/throw-new-error
            const errorMessage = UtilityFunctions.GetMessageFromError(error as Error)
            //TODO: translate this

            showErrorToast({ title: formattedMessage, message: errorMessage })

            GlobalUserJournal.Instance.AddMessage(formattedMessage)
            GlobalUserJournal.Instance.AddMessage(errorMessage)

            const index = this.FindOrder(OrderHandle)
            const pos = this.fOpenPositions.GetItem(index)

            pos.onEditFinished()

            throw error
        }
    }

    public _CloseOrder_ForStatementProcessor(OrderHandle: number, price: number): void {
        try {
            // find order
            const index = this.FindOrder(OrderHandle)
            if (index === -1) {
                throw new StrangeError(`Order ${OrderHandle} not found.`)
            }

            const pos = this.fOpenPositions.GetItem(index)

            if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

            // check position type
            if (pos.tpos.PosType !== TTradePositionType.tp_Buy && pos.tpos.PosType !== TTradePositionType.tp_Sell) {
                throw new StrangeError('Invalid position type.')
            }

            // update price and recalculate order
            pos.tpos.ClosePrice = price

            // update commission
            if (
                [TCommApplyType.ca_Close, TCommApplyType.ca_OpenClose].includes(
                    pos.symbol.symbolInfo.ComissionApplyType
                )
            ) {
                pos.ApplyCommission()
            }

            this.ProcessMarketOrder(pos)

            // close order and move it to history
            this.fBalance += pos.tpos.profit
            this.fOpenPositions.Extract(pos)
            this.fHistory.Add(pos)

            const message = t('processingCore.messages.orderClosedWithProfit', {
                orderHandle: OrderHandle,
                count: Math.round(pos.tpos.ProfitPips),
                profit: pos.tpos.profit
            })
            GlobalUserJournal.Instance.AddMessage(message)

            // update positions
            this.RecountMargin()
            this.UpdateOpenPositions()
            this.UpdateEquity()

            this.NeedHistoryUpdate = true
            this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)
        } catch (error) {
            this.handleOrderClosingError(error as Error, OrderHandle)
        }
    }

    //TODO: refactor this
    public CloseOrder(OrderHandle: number, lotToClose_param = 0, showToasts = true): void {
        try {
            // find order
            const index = this.FindOrder(OrderHandle)
            if (index < 0) {
                throw new StrangeError('Error: cannot find order with handle:', OrderHandle)
            }

            const pos = this.fOpenPositions.GetItem(index)

            if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

            // check position type
            if (!ProcessingCoreUtils.isMarketOrder(pos.PosType)) {
                throw new StrangeError(`Invalid order type for CloseOrder: ${pos.PosType}`)
            }

            this.savePrevState()

            // check lot
            if (lotToClose_param !== 0) {
                this.processPartialClose(lotToClose_param, pos, showToasts)
            }

            // update commission
            if (
                [TCommApplyType.ca_Close, TCommApplyType.ca_OpenClose].includes(
                    pos.symbol.symbolInfo.ComissionApplyType
                )
            ) {
                pos.ApplyCommission()
            }

            this.ProcessMarketOrder(pos)
            pos.tpos.orderStatus = TTradePositionStatus.tps_Filled
            // close order and move it to history
            this.fBalance += pos.tpos.profit
            this.fOpenPositions.Extract(pos)
            this.fHistory.Add(pos)
            pos.reset()

            if (showToasts) {
                this.showOrderClosedToast(pos)
            }

            EducationProcessor.Instance.processChecks()

            const formattedMessage = t('processingCore.eventMessages.order-closed', {
                orderHandle: OrderHandle,
                posInfo: pos.GetPosInfo(),
                count: Math.round(pos.tpos.ProfitPips),
                profitUSD: pos.tpos.profit.toFixed(2)
            })

            GlobalUserJournal.Instance.AddMessage(formattedMessage)

            // update positions
            this.RecountMargin()
            this.UpdateOpenPositions()
            this.UpdateEquity()

            this.UpdateStatistics()

            this.NeedHistoryUpdate = true
            this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)

            this.fireOrderClosedMixpanelEvent(pos)
        } catch (error) {
            this.handleOrderClosingError(error as Error, OrderHandle)
        }
    }

    private showOrderClosedToast(pos: TTradePos) {
        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder')

        showSuccessToast({
            title: t('orders.modal.toasts.marketClosed', {
                type: this.convertOperationTypeToStr(pos.tpos.PosType)
            }),
            message: t('orders.modal.toasts.marketClosedText', {
                type: this.convertOperationTypeToStr(pos.tpos.PosType),
                lot: pos.tpos.lot,
                symbol: pos.tpos.SymbolName,
                price: pos.tpos.ClosePrice.toFixed(pos.symbol.symbolInfo.decimals)
            })
        })
    }

    private fireOrderClosedMixpanelEvent(pos: TTradePos) {
        fireMixpanelEvent('order_closed', {
            pair: pos.tpos.SymbolName,
            type: this.convertOperationTypeToStr(pos.tpos.PosType),
            side: this.getSideByOperationType(pos.tpos.PosType),
            lot: pos.tpos.lot,
            price: pos.tpos.ClosePrice,
            points: pos.tpos.ProfitPips,
            amount: pos.tpos.profit,
            percentage_tp: 0,
            percentage_sl: 0,
            message: pos.tpos.Comments,
            PNL: (pos.tpos.profit / pos.tpos.margin) * 100,
            old_deposit: this.Balance - pos.tpos.profit,
            new_deposit: this.Balance,
            margin: pos.tpos.margin,
            tp_hit: pos.TakeProfitHit,
            sl_hit: pos.StopLossHit
        })
    }

    private processPartialClose(lotToClose_param: number, pos: TTradePos, showToasts: boolean) {
        this.UpdateLot_CheckLot(lotToClose_param)

        if (lotToClose_param < pos.tpos.lot) {
            // split position if we close part of order
            const lot1 = lotToClose_param
            // create new position
            const pos_new = new TTradePos(
                GlobalSymbolList.SymbolList,
                this.GetTicket(),
                pos.tpos.SymbolName,
                pos.tpos.OpenTime,
                pos.tpos.PosType,
                lotToClose_param - lot1,
                pos.tpos.OpenPrice,
                pos.tpos.StopLoss,
                pos.tpos.TakeProfit,
                pos.tpos.Comments,
                pos.tpos.MagicNumber,
                pos.tpos.AutoCloseTime
            )

            pos_new.tpos.swap = (pos.tpos.swap * (lotToClose_param - lot1)) / lotToClose_param
            pos_new.tstop = pos.tstop

            // update current position
            pos.tpos.swap = (pos.tpos.swap * lot1) / lotToClose_param
            pos.tpos.lot = lot1
            pos.tpos.margin = pos.GetMargin(false)
            pos.tpos.HedgedMargin = pos.GetMargin(true)

            // recount initial commission for two orders
            if (pos.tpos.commission > 0) {
                pos.tpos.commission = 0
                pos.ApplyCommission()
                pos_new.ApplyCommission()
            }

            this.fOpenPositions.Add(pos_new)

            if (showToasts) {
                showSuccessToast({
                    title: t('processingCore.toasts.positionClosed'),
                    message: t('processingCore.toasts.partialClose', { lot: lotToClose_param, ticket: pos.tpos.ticket })
                })
            }
        }
    }

    private handleOrderClosingError(error: Error, OrderHandle: number): void {
        // eslint-disable-next-line unicorn/throw-new-error
        const errorMessage = UtilityFunctions.GetMessageFromError(error)
        const reason = t('processingCore.reason', { reason: errorMessage })
        const formattedErrorMessage = t('processingCore.toasts.cannot-close-order', {
            orderHandle: OrderHandle,
            reason: reason
        })

        GlobalUserJournal.Instance.AddMessage(formattedErrorMessage)
        showErrorToast({ title: t('processingCore.toasts.error'), message: formattedErrorMessage })

        throw error
    }

    private RestoreOrder(OrderHandle: number): void {
        const index = this.FindHistoryOrder(OrderHandle)
        if (index === -1) {
            throw new Error(`Order ${OrderHandle} not found.`)
        }

        const pos = this.fHistory.GetItem(index)

        if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals
        this.fHistory.Delete(OrderHandle)
        this.fOpenPositions.Add(pos)

        this.restorePrevState()

        this.RecountMargin()
        this.UpdateOpenPositions()

        this.UpdateEquity()
        this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)
        this.UpdateStatistics()
    }

    private savePrevState(): void {
        this.prevState = {
            balance: this.Balance,
            equity: this.Equity,
            freeMargin: this.FreeMargin,
            margin: this.Margin
        }
    }

    private restorePrevState(): void {
        this.fBalance = this.prevState.balance
        this.fEquity = this.prevState.equity
        this.fFreeMargin = this.prevState.freeMargin
        this.fMargin = this.prevState.margin
    }

    public DoublePosition(OrderHandle: number): void {
        // find order
        const index = this.FindOrder(OrderHandle)
        if (index === -1) {
            throw new StrangeError(`Order ${OrderHandle} not found.`)
        }

        const pos = this.fOpenPositions.GetItem(index)

        if (!pos.symbol) throw new StrangeError('the symbol should not be null in ProcessPendingOrder')
        // Close the existing order at the current market price

        // Open a new order with the same parameters as the original order, but with double the lot size
        try {
            this.CloseOrder(OrderHandle, 0, false)

            this.SendMarketOrder(
                {
                    SymbolName: pos.tpos.SymbolName,
                    OperationType: pos.tpos.PosType,
                    lot: pos.tpos.lot * 2,
                    StopLoss: pos.tpos.StopLoss,
                    TakeProfit: pos.tpos.TakeProfit,
                    price: 0,
                    comment: pos.tpos.Comments
                },
                false
            )

            this.RecountMargin()
            this.UpdateOpenPositions()

            this.UpdateEquity()
            this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)
            this.UpdateStatistics()

            showSuccessToast({
                title: t('processingCore.toasts.doublePosition'),
                message: t('processingCore.toasts.openedDoubleLotSizePosition')
            })
        } catch (error) {
            if (error instanceof InsufficientMarginError) {
                this.RestoreOrder(OrderHandle)

                showErrorToast({
                    title: t('processingCore.toasts.insufficientMargin'),
                    message: t('processingCore.toasts.insufficientMarginDoublePosition')
                })
            }
        }
    }

    public UpdateStatistics(): void {
        this._throttledUpdateStatistics()
    }

    public DepositMoney(amount: number, comment: string): void {
        // Rounding the amount to 2 decimal places to match Delphi's RoundTo behavior
        amount = DelphiMathCompatibility.RoundTo(amount, -2)

        // Creating a new trade position for the deposit
        const pos = new TTradePos(
            GlobalSymbolList.SymbolList,
            this.GetTicket(),
            '',
            this.CurrTime,
            TTradePositionType.tp_Deposit,
            0,
            0,
            0,
            0,
            comment,
            0,
            0
        )
        pos.tpos.profit = amount
        this.fHistory.Add(pos)

        // Updating balance and equity
        this.fDeposit += amount
        this.fBalance += amount
        this.fEquity += amount
        this.NeedHistoryUpdate = true
        this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)

        // Recounting margin and updating equity
        this.RecountMargin()
        this.UpdateEquity()
    }

    public WithdrawMoney(amount: number, comment: string): void {
        // Delphi's RoundTo function rounds to a power of ten, which is not directly available in TypeScript.
        // Using toFixed for rounding to 2 decimal places as a close equivalent.
        amount = Number(amount.toFixed(2))
        if (amount > this.fBalance || amount > this.fEquity) {
            throw new ProcessingCoreError(t('processingCore.errors.withdrawalExceedsBalance'))
        }

        // Creating a new TTradePos object with the necessary parameters.
        // Assuming TTradePos constructor and its properties are already implemented.
        const pos = new TTradePos(
            GlobalSymbolList.SymbolList,
            this.GetTicket(),
            '',
            this.fCurrTime,
            TTradePositionType.tp_Withdrawal,
            0,
            0,
            0,
            0,
            comment,
            0,
            0
        )
        pos.tpos.profit = -amount
        this.fHistory.Add(pos)

        // Updating balance and equity after withdrawal.
        this.fDeposit -= amount
        this.fBalance -= amount
        this.fEquity -= amount
        this.NeedHistoryUpdate = true
        this.observableItem.notify(ProcessingCoreEvent.UPDATE_HISTORY, this)

        // RecountMargin and UpdateEquity are essential for maintaining correct margin and equity values.
        // Assuming these methods are implemented elsewhere.
        this.RecountMargin()
        this.UpdateEquity()
    }

    public GetTicket(): number {
        const result = this.fLastTicket
        this.fLastTicket++
        return result
    }

    public GetSymbolCostUSD(params: TSymbolCalcParams, OpType: TOperationType): number {
        // if symbol is nil, return CostInUSD
        if (params.symbol) {
            // Depending on the CalculationType and OpType, return the appropriate value
            if (params.CalculationType === TSymbolCalcType.sc_Normal_xxxUSD) {
                switch (OpType) {
                    case TOperationType.ot_Buy: {
                        return params.symbol.ask
                    }
                    default: {
                        return params.symbol.bid
                    }
                }
            } else {
                switch (OpType) {
                    case TOperationType.ot_Buy: {
                        return 1 / params.symbol.ask
                    }
                    default: {
                        return 1 / params.symbol.bid
                    }
                }
            }
        } else {
            return params.CostInUSD
        }
    }

    public RecountMargin(): void {
        let totalBuyLotsForRestOfTheOrders: number,
            totalSellLotsForRestOfTheOrders: number,
            lotsThatCanBeHedged: number,
            lotsThat_CANNOT_beHedged: number
        let symbol: TSymbolData

        // clear flags
        for (const pos of this.fOpenPositions) {
            pos.flag = false
        }

        lotsThatCanBeHedged = 0
        lotsThat_CANNOT_beHedged = 0
        this.fMargin = 0
        this.fFreeMargin = this.fBalance

        for (const [i, pos] of this.fOpenPositions.entries()) {
            if (!pos.symbol) throw new StrangeError('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

            if (
                pos.flag ||
                (pos.tpos.PosType !== TTradePositionType.tp_Buy && pos.tpos.PosType !== TTradePositionType.tp_Sell)
            ) {
                continue
            }

            symbol = pos.symbol
            totalBuyLotsForRestOfTheOrders = 0
            totalSellLotsForRestOfTheOrders = 0

            // count all lots for buy and sell for this symbol
            for (let j = i; j < this.fOpenPositions.length; j++) {
                const posJ = this.fOpenPositions[j]
                if (posJ.symbol === symbol) {
                    switch (posJ.tpos.PosType) {
                        case TTradePositionType.tp_Buy: {
                            totalBuyLotsForRestOfTheOrders += posJ.tpos.lot
                            break
                        }
                        case TTradePositionType.tp_Sell: {
                            totalSellLotsForRestOfTheOrders += posJ.tpos.lot
                            break
                        }
                    }
                }
            }

            // count margin for this symbol including hedged margin
            for (let j = i; j < this.fOpenPositions.length; j++) {
                const posJ = this.fOpenPositions[j]
                if (posJ.symbol === symbol) {
                    posJ.flag = true
                    if (posJ.tpos.PosType === TTradePositionType.tp_Buy) {
                        lotsThatCanBeHedged = Math.min(totalSellLotsForRestOfTheOrders, posJ.tpos.lot)
                        lotsThat_CANNOT_beHedged = posJ.tpos.lot - lotsThatCanBeHedged
                        totalSellLotsForRestOfTheOrders -= lotsThatCanBeHedged
                    } else if (posJ.tpos.PosType === TTradePositionType.tp_Sell) {
                        lotsThatCanBeHedged = Math.min(totalBuyLotsForRestOfTheOrders, posJ.tpos.lot)
                        lotsThat_CANNOT_beHedged = posJ.tpos.lot - lotsThatCanBeHedged
                        totalBuyLotsForRestOfTheOrders -= lotsThatCanBeHedged
                    } else continue //ignore pendings

                    const totalLots = lotsThatCanBeHedged + lotsThat_CANNOT_beHedged
                    if (totalLots !== posJ.tpos.lot) {
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                            `totalLots !== posJ.tpos.lot in RecountMargin ${posJ.tpos.ticket}`
                        )
                    }

                    this.fMargin +=
                        (lotsThatCanBeHedged / totalLots) * posJ.tpos.HedgedMargin +
                        (lotsThat_CANNOT_beHedged / totalLots) * posJ.tpos.margin
                }
            }
        }

        this.fFreeMargin = Math.max(this.fEquity - this.fMargin, 0)
    }

    public UpdateEquity(): void {
        //TODO: implement this
        // if (this.EquityArr.count === 0) return;
        // this.EquityArr.AddValue(this.EquityArr.equity.LastItem.DateTime, this.fEquity, this.fBalance, this.fMargin, this.GetDrawdown());
    }

    public GetStatistics(): TTradeStatistics {
        let DaysProcessed = 0
        let MonthsProcessed = 0
        let TotalTrades = 0
        let ProfitTrades = 0
        let LossTrades = 0
        let ProfitTradesConsequently = 0
        let LossTradesConsequently = 0
        let ProfCons = 0
        let LossCons = 0
        let profit = 0
        const result: TTradeStatistics = {
            DaysProcessed: 0,
            MonthsProcessed: 0,
            TotalTrades: 0,
            ProfitTrades: 0,
            LossTrades: 0,
            ProfitTradesConsequently: 0,
            LossTradesConsequently: 0,
            TradesPerDay: 0,
            TradesPerMonth: 0,
            ProfitTradesPerMonth: 0,
            LossTradesPerMonth: 0,
            MaxProfitTrade: 0,
            MaxLossTrade: 0,
            NetProfit: 0,
            GrossProfit: 0,
            GrossLoss: 0,
            ProfitPerMonth: 0,
            AverageProfit: 0,
            AverageLoss: 0,
            MaxDrawdown: this.fMaxDrawdown,
            ProfitFactor: 0,
            ReturnPercents: 0,
            MaxLotUsed: 0,
            RestorationFactor: 0,
            ReliabilityFactor: 0,
            ProfitProbability: 0,
            LossProbability: 0,
            balance: this.fBalance,
            equity: this.fEquity,
            margin: this.fMargin,
            FreeMargin: this.fFreeMargin
        }

        if (this.fHistory.Count === 0) {
            return result
        }
        // process history
        for (let i = 0; i < this.fHistory.Count; i++) {
            if (
                this.fHistory[i].tpos.PosType === TTradePositionType.tp_Buy ||
                this.fHistory[i].tpos.PosType === TTradePositionType.tp_Sell
            ) {
                TotalTrades++
                profit = this.fHistory[i].tpos.profit

                if (profit > 0) {
                    ProfitTrades++
                    result.GrossProfit += profit
                    result.MaxProfitTrade = Math.max(result.MaxProfitTrade, profit)

                    LossCons = 0
                    ProfCons++
                    ProfitTradesConsequently = Math.max(ProfitTradesConsequently, ProfCons)
                } else if (profit < 0) {
                    LossTrades++
                    result.GrossLoss += Math.abs(profit)
                    result.MaxLossTrade = Math.max(result.MaxLossTrade, Math.abs(profit))

                    ProfCons = 0
                    LossCons++
                    LossTradesConsequently = Math.max(LossTradesConsequently, LossCons)
                } else {
                    LossCons = 0
                    ProfCons = 0
                }

                result.MaxLotUsed = Math.max(result.MaxLotUsed, this.fHistory[i].tpos.lot)
            }
        }

        if (ProfitTrades > 0) {
            result.AverageProfit = result.GrossProfit / ProfitTrades
        }

        if (LossTrades > 0) {
            result.AverageLoss = result.GrossLoss / LossTrades
        }

        DaysProcessed = this.CurrTime - this.fHistory[0].tpos.OpenTime // Assuming TDateTime() gets current time
        MonthsProcessed = DaysProcessed / (365.24 / 12)

        if (DaysProcessed > 0) {
            result.TradesPerDay = TotalTrades / DaysProcessed

            if (MonthsProcessed > 1) {
                result.ProfitTradesPerMonth = ProfitTrades / MonthsProcessed
                result.TradesPerMonth = TotalTrades / MonthsProcessed
                result.LossTradesPerMonth = LossTrades / MonthsProcessed
                result.ProfitPerMonth = (result.GrossProfit - result.GrossLoss) / MonthsProcessed
            } else {
                result.ProfitTradesPerMonth = ProfitTrades
                result.TradesPerMonth = TotalTrades
                result.LossTradesPerMonth = LossTrades
                result.ProfitPerMonth = result.GrossProfit - result.GrossLoss
            }
        }

        if (result.GrossLoss > 0) {
            result.ProfitFactor = result.GrossProfit / result.GrossLoss
        }

        if (result.MaxDrawdown > 0) {
            result.RestorationFactor = (result.GrossProfit - result.GrossLoss) / result.MaxDrawdown
            result.ReliabilityFactor = result.ProfitPerMonth / result.MaxDrawdown
        }

        if (ProfitTrades > 0 || LossTrades > 0) {
            result.ProfitProbability = Math.round((100 * ProfitTrades) / (ProfitTrades + LossTrades))
            result.LossProbability = Math.round((100 * LossTrades) / (ProfitTrades + LossTrades))
        }

        result.NetProfit = result.GrossProfit - result.GrossLoss

        if (this.fDeposit === 0) {
            result.ReturnPercents = 0
        } else {
            result.ReturnPercents = (result.NetProfit / this.fDeposit) * 100
        }

        result.DaysProcessed = DaysProcessed
        result.MonthsProcessed = MonthsProcessed
        result.TotalTrades = TotalTrades
        result.ProfitTrades = ProfitTrades
        result.LossTrades = LossTrades
        result.ProfitTradesConsequently = ProfitTradesConsequently
        result.LossTradesConsequently = LossTradesConsequently

        return result
    }

    public StrLot(lot: number): string {
        return StrsConv.StrDouble(lot, this.LotDigits)
    }

    public GetAccountHistory(): [number, number, TTradePosition[]] {
        let ProfitLoss = 0
        let balance = 0
        const tpos: TTradePosition[] = []

        for (let i = 0; i < this.fHistory.Count; i++) {
            const item = this.fHistory.GetItem(i) // Assuming GetItem is the equivalent of Delphi's default array property
            tpos[i] = item.tpos // Aligning with Delphi's direct assignment to tpos[i]
            balance += item.tpos.profit // Assuming tpos has a profit property and correcting to match Delphi's addition to balance
            if (
                item.tpos.PosType !== TTradePositionType.tp_Deposit &&
                item.tpos.PosType !== TTradePositionType.tp_Withdrawal
            ) {
                ProfitLoss += item.tpos.profit // Correcting to match Delphi's condition for adding to ProfitLoss
            }
        }

        this.NeedHistoryUpdate = false
        return [ProfitLoss, balance, tpos]
    }

    public GetOpenPositions(): TTradePosition[] {
        const result: TTradePosition[] = []
        for (let i = 0; i < this.fOpenPositions.Count; i++) {
            result.push(this.fOpenPositions.GetItem(i).tpos)
        }
        return result
    }

    public GetOrderInfo(OrderHandle: number): [boolean, TTradePosition | null] {
        for (let i = 0; i < this.fOpenPositions.length; i++) {
            if (this.fOpenPositions[i].tpos.ticket === OrderHandle) {
                // Return both the result and the modified info object
                return [true, this.fOpenPositions[i].tpos]
            }
        }
        // If not found, return false and null for the info
        return [false, null]
    }

    public GetHistoryOrderInfo(OrderHandle: number): [boolean, TTradePosition | null] {
        let result = false
        let info: TTradePosition | null = null // Initialize info as null to handle the case where the order is not found.
        for (let i = 0; i < this.fHistory.Count; i++) {
            const item = this.fHistory.GetItem(i).tpos // Assuming fHistory items have a 'tpos' property based on Delphi code.
            if (item.ticket === OrderHandle) {
                info = item
                result = true
                break
            }
        }
        return [result, info] // Return both result and info as a tuple since TypeScript does not support passing parameters by reference.
    }

    public GetTrailingStopInfo(OrderHandle: number): [boolean, TTrailingStopInfo | null] {
        for (let i = 0; i < this.fOpenPositions.Count; i++) {
            const pos = this.fOpenPositions.GetItem(i)
            if (pos.tpos.ticket === OrderHandle) {
                return [true, pos.tstop]
            }
        }
        return [false, null] // Assuming TTrailingStopInfo has a default constructor
    }

    public GetOpenPosInfo(index: number, info: TTradePosition): [boolean, TTradePosition | null] {
        const inRange = index >= 0 && index < this.fOpenPositions.Count

        if (inRange) {
            return [true, this.fOpenPositions.GetItem(index).tpos]
        }

        return [false, null]
    }

    public GetOpenPos(ticket: number): TTradePos {
        const index = this.FindOrder(ticket)

        if (index === -1) {
            throw new StrangeError(`Order ${ticket} not found.`)
        }

        return this.fOpenPositions.GetItem(index)
    }

    public GetHistoryPosInfo(index: number, info: TTradePosition): [boolean, TTradePosition | null] {
        const result = index >= 0 && index < this.fHistory.Count
        if (result) {
            // Directly modifying the passed object to match Delphi's var parameter behavior
            return [true, this.fHistory.GetItem(index).tpos]
        }
        return [false, null]
    }

    public GetAccountInfo(): TAccountInfo {
        return {
            Balance: this.fBalance,
            Equity: this.fEquity,
            Margin: this.fMargin,
            FreeMargin: this.fFreeMargin,
            Profit: this.fEquity - this.fDeposit,
            Leverage: 100 // obsolete
        }
    }

    public GetOpenPosProfit(): number {
        return this.fEquity - this.fBalance
    }

    public GetTradePos(OrderHandle: number): TTradePos | null {
        // Improved iteration by using for-of loop for better readability and direct access to elements.
        for (const pos of this.OpenPositions) {
            if (pos.tpos.ticket === OrderHandle) {
                return pos
            }
        }
        return null
    }

    public GetHistoryPos(OrderHandle: number): TTradePos | null {
        // Improved iteration by using the more modern for...of loop for better readability and efficiency
        for (const pos of this.History) {
            if (pos.tpos.ticket === OrderHandle) {
                return pos
            }
        }
        return null
    }

    public GetLastOpenPos(): TTradePos | null {
        if (this.fOpenPositions.Count === 0) {
            return null
        }
        return this.fOpenPositions.LastItem
    }

    public getTotalProfitAndLoss(): number {
        return Number((this.fEquity - this.fDeposit).toFixed(2))
    }

    public getHistoryWithoutDepositsAndWithdrawals(): TTradePos[] {
        return this.fHistory.filter((pos) => {
            return (
                pos.tpos.PosType !== TTradePositionType.tp_Deposit &&
                pos.tpos.PosType !== TTradePositionType.tp_Withdrawal
            )
        })
    }

    public makeAllOpenPositionsTransparent(value: boolean): void {
        for (const pos of this.fOpenPositions) pos.setTransparentOrderLevels(value)
    }

    private restoreHistoryOrderStatus(positionType: TTradePositionType): TTradePositionStatus {
        switch (positionType) {
            case TTradePositionType.tp_Buy:
            case TTradePositionType.tp_Sell: {
                return TTradePositionStatus.tps_Filled
            }
            case TTradePositionType.tp_BuyLimit:
            case TTradePositionType.tp_SellLimit:
            case TTradePositionType.tp_BuyStop:
            case TTradePositionType.tp_SellStop:
            case TTradePositionType.tp_Cancelled: {
                return TTradePositionStatus.tps_Cancelled
            }
            default: {
                return TTradePositionStatus.tps_None
            }
        }
    }

    public CanSomethingHappenTillDate(targetDate: TDateTime): boolean {
        const ordersGroupedBySymbol = this.fOpenPositions.getOrdersGroupedBySymbol()

        let maxPossibleDrawdown = 0

        for (const [symbol, ordersForSymbol] of Object.entries(ordersGroupedBySymbol)) {
            const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(symbol)
            const minMaxValues = symbolData.GetApproximateMinMaxValuesTillDate(targetDate)
            if (!minMaxValues.wasFound) {
                //we do not have enough data to make a decision, so anything can happen, return true
                return true
            }

            if (ProcessingCoreUtils.canSLBeHit(symbolData, ordersForSymbol.marketOrders, minMaxValues)) {
                return true
            }

            if (ProcessingCoreUtils.canPendingOrdersBeTriggered(ordersForSymbol.pendingOrders, minMaxValues)) {
                return true
            }

            //there is no need to check for free margin because it can only change when a pending order is executed or market order is closed, but we already check for that
            //also there is no need to check for potential drawdown in pending orders since if one of them will be executed, then "canPendingBeTriggered" will be true
            const checkDrawdownForPendingOrders = false

            const maxPossibleDrawdownForSymbol = ProcessingCoreUtils.getMaxPossibleDrawdownForSymbol(
                symbolData,
                ordersForSymbol,
                minMaxValues,
                checkDrawdownForPendingOrders
            )

            //we are only interested in the worst case scenario
            maxPossibleDrawdown += Math.min(maxPossibleDrawdownForSymbol, 0)
        }

        if (ProcessingCoreUtils.canMarginCallHappen(this.fBalance, maxPossibleDrawdown)) {
            return true
        }
        return false
    }

    public resetOrderLevels(): void {
        for (const pos of this.fOpenPositions) {
            pos.reset()
        }
    }

    setPendingPartialCloseOrder(OrderHandle: number, lot: number, price: number): void {
        const index = this.FindOrder(OrderHandle)

        if (index === -1) {
            throw new Error(`Order ${OrderHandle} not found.`)
        }

        const pos = this.fOpenPositions.GetItem(index)

        if (!pos.symbol) throw new Error('the symbol should non be null in ProcessPendingOrder') //TODO: this is a temporary fix for deposit and withdrawals

        // check lot
        if (lot !== 0) this.UpdateLot_CheckLot(lot)

        if (lot !== 0 && lot < pos.tpos.lot) {
            pos.tpos.pendingPartialCloseLotValue = lot
            pos.tpos.pendingPartialClosePrice = price
            pos.tpos.pendingPartialCloseMarketPrice = pos.GetClosePrice()
        }
    }

    // processEvent(event: ProjectEvent, item: TProjectInfo) {
    //     if (event === ProjectEvent.TIMEZONE_CHANGED) {
    //         this.refreshOrdersInTerminal()
    //     }
    // }
}
