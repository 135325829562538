import { useCallback, useEffect, useMemo } from 'react'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { NEWS_FILTERS } from '@root/constants/localStorageKeys'
import { getLocalStorage } from '@root/utils/localStorage'
import NewsStore from '@fto/lib/store/news'
import { ParamsType } from '@fto/lib/store/news/types'
const useChartInitialization = () => {
    const { userId } = useAppSelector($getUser)

    const projectId = useMemo(() => {
        const params = new URLSearchParams(window.location.search)
        return params.get('projectId')
    }, [])

    const initializeNewsInitialData = useCallback(() => {
        const key = `${userId}_${projectId}_${NEWS_FILTERS}`

        const newsFiltersStoredData = getLocalStorage<ParamsType['filters']>(key)

        if (newsFiltersStoredData) {
            NewsStore.setFilters(newsFiltersStoredData, true)
        } else {
            NewsStore.setFilters(NewsStore.filters, true)
        }
    }, [projectId, userId])

    useEffect(() => {
        initializeNewsInitialData()
    }, [])
}

export default useChartInitialization
