import { BarBackCommand } from './FTOCommands/BarBackCommand'
import { BarForwardCommand } from './FTOCommands/BarForwardCommand'
import { Command } from './FTOCommands/Command'
import { CopyCommand } from './FTOCommands/CopyCommand'
import { DebugCommand } from './FTOCommands/DebugCommand'
import { DebugCompareCommand } from './FTOCommands/DebugCompareCommand'
import { DebugLogCommand } from './FTOCommands/DebugLogCommand'
import { DeleteCommand } from './FTOCommands/DeleteCommand'
import { EducationModalCommand } from './FTOCommands/EducationModalCommand'
import { EnvInfoCommand } from './FTOCommands/EnvInfoCommand'
import { EscapeCommand } from './FTOCommands/EscapeCommand'
import { ExtendToolLeftCommand } from './FTOCommands/ExtendToolLeftCommand'
import { ExtendToolRightCommand } from './FTOCommands/ExtendToolRightCommand'
import { PasteCommand } from './FTOCommands/PasteCommand'
import { PlayPauseCommand } from './FTOCommands/PlayPauseCommand'
import { RedoCommand } from './FTOCommands/RedoCommand'
import { SelectAllCommand } from './FTOCommands/SelectAllCommand'
import { TickForwardCommand } from './FTOCommands/TickForwardCommand'
import { ToolRotateCommand } from './FTOCommands/ToolRotateCommand'
import { UndoCommand } from './FTOCommands/UndoCommand'

import ChartSettingsStore from '@fto/lib/store/chartSettings'

export type HotkeyGroup = 'Graphic Tools' | 'Testing' | 'Debug'

export type Hotkey = {
    group: HotkeyGroup
    label: string
    keyCode?: string
    command: Command
    isCtrl?: boolean
    isShift?: boolean
    isAlt?: boolean
    isMeta?: boolean
    preventDefault?: boolean
    denyOnActiveTesting?: boolean
}

const setFocusForChart = () => {
    const chartContainer = document.getElementById('chart-container-0')
    if (!chartContainer) return
    ;(chartContainer as HTMLElement).focus()
}

export const DEFAULT_HOTKEYS: Hotkey[] = [
    {
        group: 'Debug',
        label: 'Debug mod toggle',
        keyCode: 'F5',
        command: new DebugCommand(),
        isCtrl: true,
        isShift: true
    },
    {
        group: 'Testing',
        label: 'Start/Pause',
        keyCode: 'Enter',
        command: new PlayPauseCommand()
    },
    {
        group: 'Graphic Tools',
        label: 'Undo',
        keyCode: 'KeyZ',
        command: new UndoCommand(),
        isCtrl: true
    },
    {
        group: 'Graphic Tools',
        label: 'Redo',
        keyCode: 'KeyY',
        command: new RedoCommand(),
        isCtrl: true
    },
    {
        group: 'Graphic Tools',
        label: 'Escape picker mode',
        keyCode: 'Escape',
        command: new EscapeCommand()
    },
    {
        group: 'Debug',
        label: 'Debug Compare',
        keyCode: 'F3',
        command: new DebugCompareCommand(),
        isCtrl: true,
        isShift: true
    },
    {
        group: 'Debug',
        label: 'Debug Log Dump',
        keyCode: 'F4',
        command: new DebugLogCommand(),
        isCtrl: true,
        isShift: true
    },
    {
        group: 'Testing',
        label: 'Tick Forward',
        keyCode: 'Space',
        command: new TickForwardCommand(),
        isCtrl: true,
        denyOnActiveTesting: true
    },
    {
        group: 'Testing',
        label: 'Bar Forward',
        keyCode: 'Space',
        command: new BarForwardCommand(),
        denyOnActiveTesting: true
    },
    {
        group: 'Testing',
        label: 'Bar Forward',
        keyCode: 'ArrowRight',
        command: new BarForwardCommand(),
        denyOnActiveTesting: true
    },
    {
        group: 'Graphic Tools',
        label: 'Extend Tool to the right',
        keyCode: 'ArrowRight',
        command: new ExtendToolRightCommand(),
        isShift: true
    },
    {
        group: 'Graphic Tools',
        label: 'Extend Tool to the left',
        keyCode: 'ArrowLeft',
        command: new ExtendToolLeftCommand(),
        isShift: true
    },
    {
        group: 'Testing',
        label: 'Bar Back',
        keyCode: 'Backspace',
        command: new BarBackCommand(),
        denyOnActiveTesting: true
    },
    {
        group: 'Testing',
        label: 'Bar Back',
        keyCode: 'ArrowLeft',
        command: new BarBackCommand(),
        denyOnActiveTesting: true
    },
    {
        group: 'Debug',
        label: 'Open Education Modal',
        keyCode: 'F7',
        command: new EducationModalCommand(),
        isCtrl: true
    },
    {
        group: 'Debug',
        label: 'GetEnvInfo',
        keyCode: 'F10',
        command: new EnvInfoCommand(),
        isMeta: true
    },
    {
        group: 'Debug',
        label: 'Open Education Modal',
        keyCode: 'F7',
        command: new EducationModalCommand(),
        isMeta: true
    },
    {
        group: 'Graphic Tools',
        label: 'Select All Tools',
        keyCode: 'KeyA',
        command: new SelectAllCommand(),
        isCtrl: true,
        preventDefault: true
    },
    {
        group: 'Graphic Tools',
        label: 'Copy Tool',
        keyCode: 'KeyC',
        command: new CopyCommand(),
        isCtrl: true,
        preventDefault: true
    },
    {
        group: 'Graphic Tools',
        label: 'Paste Tool',
        keyCode: 'KeyV',
        command: new PasteCommand(),
        isCtrl: true,
        preventDefault: true
    },
    {
        group: 'Graphic Tools',
        label: 'Delete Tool/Indicator',
        keyCode: 'Delete',
        command: new DeleteCommand()
    },
    {
        group: 'Graphic Tools',
        label: 'Snap Trend Line or Channel to 45 degrees',
        command: new ToolRotateCommand(),
        isShift: true
    }
]

export function addHotkey(hotkey: Hotkey): void {
    window.addEventListener('keydown', (event: KeyboardEvent) => {
        const { isPlaying } = ChartSettingsStore.settings
        const isValid = validateHotkey(event, hotkey)

        if (!isValid) return

        if (hotkey.preventDefault) {
            event.preventDefault()
        }

        if (hotkey.keyCode && event.code !== hotkey.keyCode) return
        if (hotkey.denyOnActiveTesting && isPlaying) return

        setFocusForChart()
        hotkey.command.execute()
    })
}

function validateHotkey(event: KeyboardEvent, hotkey: Hotkey): boolean {
    if ((hotkey.isCtrl && !event.ctrlKey) || (!hotkey.isCtrl && event.ctrlKey)) return false
    if ((hotkey.isShift && !event.shiftKey) || (!hotkey.isShift && event.shiftKey)) return false
    if ((hotkey.isAlt && !event.altKey) || (!hotkey.isAlt && event.altKey)) return false
    if ((hotkey.isMeta && !event.metaKey) || (!hotkey.isMeta && event.metaKey)) return false

    if (
        document.querySelector('dialog') !== null ||
        document.activeElement?.nodeName === 'INPUT' ||
        document.activeElement?.nodeName === 'TEXTAREA'
    )
        return false

    return true
}

export function changeHotkey(hotkey: Hotkey, newKey: string): void {
    hotkey.keyCode = newKey
    removeHotkey(hotkey)
    addHotkey(hotkey)
}

export function removeHotkey(hotkey: Hotkey): void {
    window.removeEventListener('keydown', hotkey.command.execute)
}
