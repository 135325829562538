export const AUTH_TOKEN_KEY = 'AUTH_TOKEN'
export const AUTH_REFRESH_TOKEN_KEY = 'AUTH_REFRESH_TOKEN'
export const CURRENT_LANGUAGE_KEY = 'CURRENT_LANGUAGE'
export const TERMINAL_COLUMNS_SETTINGS = 'TERMINAL_COLUMNS_SETTINGS'
export const TERMINAL_COLUMNS_SIZE = 'TERMINAL_COLUMNS_SIZE'
export const NEWS_FILTERS = 'NEWS_FILTERS'
export const USE_CROWDIN = 'USE_CROWDIN'
export const DONT_SHOW_APPLY_TO_ALL_WARNING = 'DONT_SHOW_APPLY_TO_ALL_WARNING'

export const FAVORITE_TIMEFRAMES = 'FAVORITE_TIMEFRAMES'

export const WATCHLIST_COLUMNS = 'WATCHLIST_COLUMNS'

// MIXPANEL_EVENTS

export const PROJECT_STARTED_KEY = 'PROJECT_STARTED'

export const CONVERSION_PARAMS_FROM_SITES = 'CONVERSION_PARAMS_FROM_SITES'

export const USER_LOCAL_ID = 'USER_LOCAL_ID'
