import { FiboChannelJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList } from '../../../ft_types/common/OffsStringList'
import { NotImplementedError } from '../../../utils/common_utils'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType, TPointInfo } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPenStyle, TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TChart } from '../../chart_classes/BasicChart'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { LevelActiveType, TLevelData } from '@fto/lib/drawing_interface/GraphicObjects'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtFiboChannel extends TFiboPaintTool {
    private extendOpacity: number
    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptFiboChannel)
        this.ShortName = 'Fibo Channel'
        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 3
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorFiboChannel
        this.icon = 55
        this.extendOpacity = 0.15
        const fibo: number[] = [0, 38.2, 61.8, 100, 161.8, 261.8]
        this.SetLevels(fibo, 3)

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboChannel)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboChannel)
        }

        if (!styles) throw new StrangeError('Default styles for FiboChannel are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)

        this.pen = new IGPPen(this.fLineStyle.color, this.fLineStyle.width)
        this.pen.setPenStyle(this.fLineStyle.style)
        this.fLevels.ImportFromStr(styles.levels)
        this.fExtendsRay.left = styles.extendLeft
        this.fExtendsRay.right = styles.extendRight
    }

    clone(): TPtFiboChannel {
        const cloneObj = new TPtFiboChannel(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)

        return cloneObj
    }

    toJson(): FiboChannelJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            pen: {
                color: this.pen.color,
                width: this.pen.width,
                style: this.pen.getPenStyleFromPattern(),
                opacity: this.pen.opacity,
                dashStyle: this.pen.dashStyle
            }
        }
    }

    fromJSON(json: FiboChannelJSON) {
        super.fromJSON(json)
        this.pen = new IGPPen(json.pen.color, json.pen.width)
        this.pen.setPenStyle(json.pen.style)
    }

    public static LoadFromList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    public static SaveToList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    public SetLevels(levels: number[], decimals: number): void {
        this.fLevels.Clear()
        const colors = ['#2F80ED', '#808000', '#30755b', '#008000', '#9a5e5e', '#254883']

        for (const [index, levelValue] of levels.entries()) {
            const levelText = StrsConv.StrDouble(levelValue, decimals)
            const color = colors[index % colors.length] // Cycle through colors if there are more levels than colors
            const lineStyle = new TLineStyle(ColorHelperFunctions.MakeColor(color), TPenStyle.psSolid, 1)
            const levelData = TLevelData.Create_TLevelData_Style(
                levelValue,
                levelText,
                lineStyle,
                LevelActiveType.active
            )
            this.fLevels.Add(levelData)
        }
    }

    public Paint(): void {
        if (this.Points.length < 2) {
            return
        }
        const canvas = this.fChart.GdiCanvas

        const x0 = this.fPoints[0].x
        const y0 = this.fPoints[0].y
        const x1 = this.fPoints[1].x
        const y1 = this.fPoints[1].y
        canvas.MoveTo(Math.round(x0) + 0.5, Math.round(y0) + 0.5)
        canvas.LineTo(Math.round(x1) + 0.5, Math.round(y1) + 0.5, this.pen)
        if (this.fHighlighted) {
            const points = [new TPoint(x0, y0), new TPoint(x1, y1)]
            this.PaintHoverLine(points)
        }

        if (this.Points.length < 3) {
            return
        }

        const x2 = this.fPoints[2].x
        const y2 = this.fPoints[2].y

        const price0 = this.fPoints[0].price
        const price1 = this.fPoints[1].price
        const price2 = this.fPoints[2].price

        const totalXDist = x1 - x0
        const totalYDist = y1 - y0
        const fibPoints = []
        this.fLevels.SortByValue()
        for (let i = 0; i < this.fLevels.Count; i++) {
            const level = this.fLevels[i]
            if (level.isActive) {
                const fibValue = level.value === 0 ? 0 : level.value / 100
                const fibX0 = fibValue === 0 ? x0 : x0 + fibValue * (x2 - x0)
                const fibY0 = fibValue === 0 ? y0 : y0 - fibValue * (y0 - y2)
                const fibY1 = fibY0 + totalYDist
                const fibX1 = fibX0 + totalXDist
                if (!(this.fExtendsRay.left || this.fExtendsRay.right)) {
                    canvas.MoveTo(Math.round(fibX0) + 0.5, Math.round(fibY0) + 0.5)
                    canvas.LineTo(Math.round(fibX1) + 0.5, Math.round(fibY1) + 0.5, level.pen)
                }
                const fibPriceStart = price0 + (price2 - price0) * fibValue
                const fibPointStart = new TPointInfo(fibX0, fibY0)
                fibPointStart.price = fibPriceStart

                const fibPointEnd = new TPointInfo(fibX1, fibY1)
                const fibPriceEnd = price1 + (price2 - price0) * fibValue
                fibPointEnd.price = fibPriceEnd
                fibPoints.push({ index: i, value: fibValue, start: fibPointStart, end: fibPointEnd, pen: level.pen })

                const text = `${fibValue * 100} (${StrsConv.StrDouble(fibPriceStart, this.fChart.ScaleDecimals())})`
                const textWidth = canvas.TextWidth(text)

                const textX = fibX0 - textWidth - 10
                const textY = fibY0 + 15
                canvas.textOut(textX, textY, text, undefined, new IGPSolidBrush(level.pen.color))
            }
        }

        this.fExtendsRay.fillLeftfPoints = []
        this.fExtendsRay.fillRightfPoints = []
        this.fExtendsRay.leftfPoints.length = 0
        this.fExtendsRay.rightfPoints.length = 0
        const context = this.fChart.GdiCanvas.graphics.Context
        for (const [i, currentFibPoint] of fibPoints.entries()) {
            if (i + 1 < fibPoints.length) {
                const nextFibPoint = fibPoints[i + 1]

                const leftPoint: {
                    [key: number]: [[TPointInfo, TPointInfo], [TPointInfo, TPointInfo], IGPPen | null]
                } = {
                    [i]: [
                        [currentFibPoint.end, currentFibPoint.start],
                        [nextFibPoint.end, nextFibPoint.start],
                        currentFibPoint.pen
                    ]
                }

                const rightPoint: {
                    [key: number]: [[TPointInfo, TPointInfo], [TPointInfo, TPointInfo], IGPPen | null]
                } = {
                    [i]: [
                        [currentFibPoint.start, currentFibPoint.end],
                        [nextFibPoint.start, nextFibPoint.end],
                        currentFibPoint.pen
                    ]
                }
                this.fExtendsRay.leftfPoints.push({
                    [i]: [currentFibPoint.end, currentFibPoint.start, currentFibPoint.pen]
                })
                this.fExtendsRay.rightfPoints.push({
                    [i]: [currentFibPoint.start, currentFibPoint.end, currentFibPoint.pen]
                })

                // @ts-ignore
                this.fExtendsRay.fillLeftfPoints.push(leftPoint)

                // @ts-ignore
                this.fExtendsRay.fillRightfPoints.push(rightPoint)
                if (!this.fExtendsRay.left && !this.fExtendsRay.right) {
                    const hexColor = currentFibPoint.pen.color
                    const r = parseInt(hexColor.slice(1, 3), 16)
                    const g = parseInt(hexColor.slice(3, 5), 16)
                    const b = parseInt(hexColor.slice(5, 7), 16)
                    context.fillStyle = `rgba(${r}, ${g}, ${b}, ${0.15})`
                    context.beginPath()
                    context.moveTo(Math.round(currentFibPoint.start.x) + 0.5, Math.round(currentFibPoint.start.y) + 0.5)
                    context.lineTo(Math.round(currentFibPoint.end.x) + 0.5, Math.round(currentFibPoint.end.y) + 0.5)
                    context.lineTo(Math.round(nextFibPoint.end.x) + 0.5, Math.round(nextFibPoint.end.y) + 0.5)
                    context.lineTo(Math.round(nextFibPoint.start.x) + 0.5, Math.round(nextFibPoint.start.y) + 0.5)
                    context.closePath()
                    context.fill()
                }
            } else {
                this.fExtendsRay.leftfPoints.push({
                    [i]: [currentFibPoint.end, currentFibPoint.start, currentFibPoint.pen]
                })
                this.fExtendsRay.rightfPoints.push({
                    [i]: [currentFibPoint.start, currentFibPoint.end, currentFibPoint.pen]
                })
            }
        }
        this.PaintExtendsRay()
        this.fExtendsRay.fillLeftfPoints.length = 0
        this.fExtendsRay.fillRightfPoints.length = 0
        this.PaintMarkers()
    }

    public assign(tool: TPtFiboChannel, isCopy = false): void {
        super.assign(tool, isCopy)

        this.fLevels.Assign(tool.fLevels)
        this.pen = tool.pen
    }

    getLineStyleParams(): {
        color: {
            value: TLineStyle['color']
            hasDifferentValues: boolean
        }
        style: {
            value: TLineStyle['style']
            hasDifferentValues: boolean
        }
        width: {
            value: TLineStyle['width']
            hasDifferentValues: boolean
        }
    } {
        return {
            color: {
                value: this.pen.color,
                hasDifferentValues: false
            },
            width: {
                value: this.pen.width,
                hasDifferentValues: false
            },
            style: {
                value: this.pen.getPenStyleFromPattern(),
                hasDifferentValues: false
            }
        }
    }

    override EdgeUnderMouse(x: number, y: number): number {
        const x0 = this.fPoints[0].x
        const y0 = this.fPoints[0].y
        const x1 = this.fPoints[1].x
        const y1 = this.fPoints[1].y
        const x2 = this.fPoints[2].x
        const y2 = this.fPoints[2].y
        const totalYDist = y1 - y0
        const totalXDist = x1 - x0
        for (const level of this.fLevels) {
            const fibValue = level.value === 0 ? 0 : level.value / 100
            const fibX0 = fibValue === 0 ? x0 : x0 + fibValue * (x2 - x0)
            const fibY0 = fibValue === 0 ? y0 : y0 - fibValue * (y0 - y2)
            const fibY1 = fibY0 + totalYDist
            const fibX1 = fibX0 + totalXDist
            if (this.PointAboveTheLine(x, y, fibX0, fibY0, fibX1, fibY1)) {
                return 1
            }
        }
        return super.EdgeUnderMouse(x, y)
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        this.chart.ChartWindow.saveStateWithNotify()

        const { color, style, width } = styles
        this.pen = new IGPPen(color, width)
        this.pen.setPenStyle(style)

        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: {
                    ...this.pen,
                    style: this.pen.getPenStyleFromPattern()
                },
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.fExtendsRay.left,
                label: 'Extend to the left',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            },
            extendRight: {
                key: 'extendRight',
                value: this.fExtendsRay.right,
                label: 'Extend to the right',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboChannel,
            toolName: 'fiboChannel'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        const { description, lineStyle, levels, extendLeft, extendRight } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description
        this.fExtendsRay.left = extendLeft
        this.fExtendsRay.right = extendRight
        this.pen = new IGPPen(lineStyle.color, lineStyle.width)
        this.pen.setPenStyle(lineStyle.style)

        // SET UP LEVELS

        this.updateLevelsFromModal(levels)

        this.saveToManager()

        resetToolSettings()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboChannel, {
            lineStyle: new TLineStyle(
                this.pen.color,
                this.pen.getPenStyleFromPattern(),
                this.pen.width
            ).getSerialized(),
            toolName: PaintToolNames.ptFiboChannel,
            levels: this.fLevels.ExportToStr(5),
            extendLeft: this.fExtendsRay.left,
            extendRight: this.fExtendsRay.right
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboChannel, TPtFiboChannel)
